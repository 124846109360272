import { EvesButton } from '@/common/components/atoms/button/button';
import { CarConnectorConnectionType, IntegrationConnectionType } from '@/common/enums/enums';
import { withContext } from '@/common/utils/withContext';
import { deleteIntegrationConnection, getCarConnectorSettings } from '@/services/userProfile';
import { IUserContextModel } from '@/store/auth/types';
import { Delete, Launch } from '@mui/icons-material';
import { Box, Stack, Grid } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from '@/common/components/molecules/card/card';

const MercedesCar = (props: any) => {
	const [state, setStateData] = useState({
		mercedesConnection: {},
		isMercedesConnectionValid: false,
		mercedesConnectionSetting: {},
	} as any);

	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);

	const integrationConnections = props.connections;
	const style = {
		connectionCardText: {
			fontSize: '0.9rem', 
			color: 'rgba(0, 0, 0, 0.87)'
		} as CSSProperties,
		cardTitle: {
			fontSize: '20px',
			fontWeight: '500', 
			color: 'rgba(0, 0, 0, 0.87)'
		} as CSSProperties,
		cardimg: {
			border: '1px solid #dee2e6',
			borderRadius: '16px',
			boxShadow: '0 0.125rem 0.25rem rgb(60 72 88 / 8%)',
			backgroundColor: '#fafafa',
			padding: '0.25rem'
		} as CSSProperties,
	};
	const handleUserConnections = () => {
		if (!(integrationConnections === 0)) {
			let mercedesConnection: any = null;
			let isMercedesConnectionValid: boolean = false;
			for (const connection of integrationConnections) {
				if (connection.connectorId === IntegrationConnectionType.CONCUR) {
					mercedesConnection = connection;
					setStateData((currentData: any) => {
						return {
							...currentData,
							mercedesConnection
						};
					});
					isMercedesConnectionValid =
            isMercedesConnectionValid &&
            mercedesConnection.validUntil &&
            new Date(mercedesConnection.validUntil).getTime() > new Date().getTime();
					setStateData((currentData: any) => {
						return {
							...currentData,
							isMercedesConnectionValid,
						};
					});
				}
			}
		}
	};

	const linkMercedesAccount = () => {
		if (!state.mercedesConnectionSetting || !state.mercedesConnectionSetting.concur) {
			props.notificationService?.notify({
				message: `${translate('settings.car_connector.mercedes.link_error')}`,
				type: 'error',
			});
		} else {
			// Concur
			const mercedesSetting = state.mercedesConnectionSetting.mercedesConnection;
			const returnedUrl = `${window.location.origin}/users/mercedes-connections`;
			const mercedesState = {
				connector: IntegrationConnectionType.MERCEDES,
				appId: state.mercedesConnectionSetting.id,
				//TODO: this line gets changed after UI dependency is cleared.
				userId: authContext.userInfo?.userId,
			};
			window.open(`${mercedesSetting.authenticationUrl}/as/authorization.oauth2?client_id=${mercedesSetting.clientId}&response_type=code&scope=mb:vehicle:mbdata:evstatus offline_access&redirect_uri=${returnedUrl}&state=${JSON.stringify(mercedesState)}`, '_blank');
		}
	};

	const { t: translate } = useTranslation();

	const revokeMercedesAccount = () => {
		deleteIntegrationConnection(state.mercedesConnection.id).then(
			(response: any) => {
				if (response.status === 'success') {
					props.notificationService?.notify({
						// settings.car_connector.mercedes.revoke_success
						message: `${translate('settings.car_connector.mercedes.revoke_success')}`,
						type: 'success',
					});
				} else {
					props.notificationService?.notify({
						message: `${response} ${translate('settings.car_connector.mercedes.revoke_error')}`,
						type: 'error',
					});
				}
				props.fetchConnections();
			}, (error) => {
				props.notificationService?.notify({
					message: `${error} ${translate('settings.car_connector.mercedes.revoke_error')}`,
					type: 'error',
				});
				props.fetchConnections();
			}
		);
	};

	if(props.integrationConnections) handleUserConnections();

	const getRefundUrl = (): string => {
		if (state.mercedesConnectionSetting && state.mercedesConnectionSetting.concur) {
			return state.mercedesConnectionSetting.concur.apiUrl;
		}
		return '';
	};

	const { refetch: fetchMercedesSettings } = useQuery('carConnections', () => { return getCarConnectorSettings(); }, {
		enabled:false,
		onSuccess: (data: any) => {
			const carConnectorSettings: any = {};
			carConnectorSettings.id = data.id;
			carConnectorSettings.sensitiveData = data.sensitiveData;
			carConnectorSettings.carConnector = data.content.carConnector;
			const mercedesConnectionSetting: any = carConnectorSettings.carConnector.connections.find((connection) =>
				connection.type === CarConnectorConnectionType.MERCEDES);
			if(mercedesConnectionSetting) {
				setStateData((currentData: any) => {
					return {
						...currentData,
						mercedesConnectionSetting,
					};
				});
			}
		}
	});

	useEffect(() => {
		fetchMercedesSettings();
	}, []);
  
	return <Grid>
		{Object.keys(state.mercedesConnectionSetting).length > 0 &&
		<Card data-cy="mercedes" isTransparent={true} sx={{paddingBottom:'0'}}>
			<>
				<a href={getRefundUrl()} target="_blank" rel="noreferrer">
					<Stack direction="row" alignItems={'center'} justifyContent={'flex-start'}  marginBottom={'20px'}>
						<Box height={'72px'} width={'72px'} marginRight={'20px'}>
							<img height={'100%'} width={'100%'} src={require('@/assets/img/integrations/mercedes-logo.png')} style={style.cardimg}/>
						</Box>
						<h2 data-cy="mercedes-title" className="card-title"  style={style.cardTitle}>{`${translate('settings.car_connector.types.mercedes')}`}</h2>
					</Stack>
				</a>
			</>
			{!state.isMercedesConnectionValid && <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} style={style.connectionCardText}>
				
				<span>Not Connected</span>
				<EvesButton type="button" 
					onClick={linkMercedesAccount} startIcon={<Launch />} 
					variant="contained" data-cy="mercedes-connects">Connect</EvesButton>
			</Stack>
			|| <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
				<span> {state.refundConnection.createdAt && `${translate('users.connectors.created_on')}`}</span>
				<span>{ state.refundConnection.validUntil && `${translate('users.connectors.expired_on')}`}</span>
				<EvesButton type="button" 
					onClick={revokeMercedesAccount} startIcon={<Delete />} 
					variant="contained" >${translate('users.connectors.revoke')}</EvesButton></Stack>}	
		</Card>}
	</Grid>;
};

export default withContext(MercedesCar);