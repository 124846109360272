export class ElectricVehicleModel {
    id: string = '';
    lastChangedOn?: '';
    licensePlate?: '';
    type?: string;
    userID?: '';
    vin?: '';
    canRead: boolean = false;
    canListUsers: boolean = false;
    canUpdate: boolean = false;
    canDelete: boolean = false;
    createdOn?: '';
    carCatalog?: {
        id?: '';
        batteryCapacityFull?: number;
        fastChargePowerMax?: ''
        image?: '';
        vehicleMake?: '';
        vehicleModel?: '';
        vehicleModelVersion?: ''
    };
    user?: {
        firstName?: '';
        id?: '';
        name?: '';
    };
    converter?: {
        amperagePerPhase?: number,
        numberOfPhases?: number,
        powerWatts?: number,
        type?: ''
    };
    createdBy?: string;
    lastChangedBy?: string;
    
    constructor(input: ElectricVehicleModel) {
        Object.assign(this, input);
    }

    get dcPower() {
        return this.carCatalog?.fastChargePowerMax ? `${this.carCatalog?.fastChargePowerMax} KW` : '-';
    }

    get createdOnDateFormated() {
        return this.formatDate(this.createdOn ? this.createdOn : '');
    }

    get lastChangedOnDateFormated() {
        return this.formatDate(this.lastChangedOn ? this.lastChangedOn : '');
    }

    get createdByFormated() {
        return this.createdBy ? this.createdBy : '-';
    }

    get changedByFormated() {
        return this.lastChangedBy ? this.lastChangedBy : '-';
    }

    protected formatDate = (date: string): string => {
        const resultDate = date.length > 0 ? new Date(date) : '-';
        if (resultDate === '-') return resultDate;
        let hours = resultDate.getHours();
        const minutes = resultDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return (
            resultDate.getMonth() +
            1 +
            '/' +
            resultDate.getDate() +
            '/' +
            (resultDate.getFullYear() % 100) +
            ', ' +
            hours +
            ':' +
            (minutes < 10 ? '0' + minutes : minutes) +
            ' ' +
            ampm
        );
    };


    get typeCustom() {
        return this.type == 'C' ? 'Company' : (this.type == 'P' ? 'Private' : 'Pool');
    }

    get acConverters() {
        return `${this.converter?.powerWatts} KW - ${this.converter?.numberOfPhases} Phase(s) - ${this.converter?.amperagePerPhase} A`;
    }

    get userName() {
        return this.user ? this.user?.firstName + ' ' + this.user?.name : '-';
    }

    get carCatalogId() {
        return this.carCatalog?.id;
    }

    get carCatalogBatteryCapacityFull() {
        return this.carCatalog?.batteryCapacityFull;
    }

    get carCatalogImage() {
        return this.carCatalog?.image;
    }

    get carCatalogVehicleMake() {
        return this.carCatalog?.vehicleMake;
    }

    get carCatalogVehicleModel() {
        return this.carCatalog?.vehicleModel;
    }

    get carCatalogVehicleModelVersion() {
        return this.carCatalog?.vehicleModelVersion ? this.carCatalog?.vehicleModelVersion : '-';
    }

}

export class CatalogModel {
    batteryCapacityFull?: number;
    chargeStandardPhase?: number;
    chargeStandardPhaseAmp?: number;
    chargeStandardPower?: number;
    id?: '';
    image?: '';
    performanceTopspeed?: number;
    rangeReal?: number;
    vehicleMake?: '';
    vehicleModel?: '';
    vehicleModelVersion?: '';
    canRead: boolean = false;
    drivetrainPowerHP?: '';
    rangeWLTP?: '';
    chargePlug?: '';
    fastChargePlug?: '';
    fastChargePowerMax?: '';
    performanceAcceleration?: '';

    constructor(input: ElectricVehicleModel) {
        Object.assign(this, input);
    }

    get vehicleModelVersionFormat() {
        return this.vehicleModelVersion ? this.vehicleModelVersion : '-';
    }

    get drivetrainPowerHPFormat() {
        return this.drivetrainPowerHP ? `${this.drivetrainPowerHP} hp` : '-';
    }

    get batteryCapacityFullFormat() {
        return this.batteryCapacityFull ? `${this.batteryCapacityFull} kwh` : '-';
    }

    get rangeWLTPFormat() {
        return this.rangeWLTP ? `${this.rangeWLTP} km` : '-';
    }

    get rangeRealFormat() {
        return this.rangeReal ? `${this.rangeReal} km` : '-';
    }
    get chargeStandardPowerFormat() {
        return this.chargeStandardPower ? `${this.chargeStandardPower} kw` : '-';
    }

    get chargePlugFormat() {
        return this.chargePlug ? `${this.chargePlug}` : '-';
    }

    get fastChargePowerMaxFormat() {
        return this.fastChargePowerMax ? `${this.fastChargePowerMax} kw` : '-';
    }

    get fastChargePlugFormat() {
        return this.fastChargePlug ? `${this.fastChargePlug}` : '-';
    }

    get performanceTopspeedFormat() {
        return this.performanceTopspeed ? `${this.performanceTopspeed} km` : '-';
    }

    get performanceAccelerationFormat() {
        return this.performanceAcceleration ? `${this.performanceAcceleration}` : '-';
    }
}