export const autoRefresh = {
  // give time in milliseconds
  dashboard: {
    SESSION_SUMMARY: 600000,
    CHARGING_STATION: 3600000,
    CONNECTORS: 600000,
    TOTAL_SESSIONS: 600000,
    TELEMATICS_API_CALL_INTERVAL: 600000,
  },
  chargingStation: {
    GET_SITES: 3600000,
    CHARGING_STATION: 3600000,
    TOTAL_CAPACITY: 600000,
    CONNECTORS: 600000,
  },
  sessions: {
    IN_PROGRESS: 600000,
    IN_HISTORY: 1800000,
  },
  rfid: {
    SUMMARY: 3600000,
  },
  DEFAULT: 10000, // 10 sec
};
