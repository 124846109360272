import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import LookupComponent from '@/common/components/lookupComponent';
import { LookupEntityType } from '@/common/enums/enums';
import { withContext } from '@/common/utils/withContext';
import { Close } from '@mui/icons-material';
import { Grid, InputAdornment } from '@mui/material';
import { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AddConnectedAccount = (props: any) => {
  const { t: translate } = useTranslation();
  const connectedAccountForm: any = props.connectedAccountForm;
  const UserLookupRef: any = useRef(null);
  const [state, setStateData] = useState({
    showUserLookUpModal: false,
    userName: '',
  } as any);
  const userName = connectedAccountForm.watch('user');
  const setUserFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: true,
      };
    });
  };

  const onUserSelected = (users: any) => {
    connectedAccountForm.setValue('userID', users[0].id, {
      shouldDirty: true,
      shouldValidate: true,
    });
    connectedAccountForm.setValue(
      'user',
      users[0].firstName + ' ' + users[0].name,
      { shouldDirty: true, shouldValidate: true }
    );
    setStateData((currentData: any) => {
      return {
        ...currentData,
        userName: users[0].firstName + ' ' + users[0].name,
        showUserLookUpModal: false,
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const onRemoveUser = (event: any) => {
    event.stopPropagation();
    connectedAccountForm.setValue('user', '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    connectedAccountForm.setValue('userID', '');
    setStateData((currentData: any) => {
      return {
        ...currentData,
        userName: '',
      };
    });
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={6.1}>
          <Controller
            name='companyName'
            control={connectedAccountForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id='company'
                label={`${translate('companies.title')}`}
                fullWidth
                disabled={props.isDisabled}
                variant='standard'
                error={!!connectedAccountForm.formState?.errors.companyName}
                helperText={connectedAccountForm.formState?.errors.companyName?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={6.1}>
          <Controller
            name='user'
            control={connectedAccountForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                value={state.userName}
                id='User-standard-search'
                label={`${translate('general.search_user')}`}
                data-cy='User-filter'
                type='text'
                variant='standard'
                error={!!connectedAccountForm.formState?.errors.user}
                helperText={connectedAccountForm.formState?.errors.user?.message?.toString()}
                onClick={setUserFilter}
                ref={UserLookupRef}
                InputProps={{
                  endAdornment: userName && (
                    <InputAdornment
                      position='end'
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      <Close
                        sx={{ color: 'black', height: '15px' }}
                        id='user'
                        onClick={onRemoveUser}
                      />{' '}
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='single'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }, ...(props.superAdmin ? [{ key: 'role', value: 'A' },{ key: 'tenantId', value: props?.tenantId }] : [])]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}
    </>
  );
};

export default withContext(AddConnectedAccount);
