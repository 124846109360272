import EvesMultiselect from '@/common/components/atoms/multiselect/multiselect';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState, useEffect } from 'react';
import { filterSelector } from '../constants/constants';
import { Filters } from '../enums/enums';
import { useTranslation } from 'react-i18next';

const MenuProps = {
  PaperProps: {
    style: { maxHeight: '400px' },
  },
};
interface IFilterSelector {
  label?: string;
  filterOption: Filters;
  defaultIssuer?: boolean;
  onChange: any;
  isDefaultValue?: boolean;
  isResetBtnDisabled?: boolean;
  customOptions?: { value: { [key: string]: string } };
  'data-cy'?: string;
}
export default function FilterSelector(props: IFilterSelector) {
  const { t: translate } = useTranslation();
  const filter = !props.customOptions
    ? filterSelector[props.filterOption]
    : props.customOptions;
  const dropDownOptionsRaw: string[] = Object.keys(filter.value);
  const dropDownOptionsTransalted = dropDownOptionsRaw.map((op) =>
    translate(op)
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const filterValue = props.isResetBtnDisabled
    ? props.defaultIssuer && props.filterOption === Filters.OWNER
      ? [dropDownOptionsTransalted[0]]
      : []
    : selectedOptions;
  const [selectedFirstIndex, setSelectedFirstIndex] = useState<number>(0);

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
    if (typeof value !== 'string') {
      for (
        let index1 = 0;
        index1 < dropDownOptionsTransalted.length;
        index1++
      ) {
        let isSelectedFirstIndex = false;
        for (let index2 = 0; index2 < value.length; index2++) {
          if (dropDownOptionsTransalted[index1] === value[index2]) {
            setSelectedFirstIndex(index1);
            isSelectedFirstIndex = true;
            break;
          }
        }
        if (isSelectedFirstIndex) break;
      }
    }
    props.onChange(
      dropDownOptionsRaw.filter((op) => value.includes(translate(op)))
    );
  };

  useEffect(() => {
    if (props.isDefaultValue) setSelectedOptions([]);
  }, [props.isDefaultValue]);

  useEffect(() => {
    if (props.isResetBtnDisabled) setSelectedOptions([]);
  }, [props.isResetBtnDisabled]);

  return (
    <>
      <EvesMultiselect
        isFilter={true}
        label={
          `${translate(props.label || '')}` ||
          `${translate(props.filterOption || '')}`
        }
        options={dropDownOptionsTransalted}
        checked={(item: string) =>
          filterValue ? filterValue.includes(item) : false
        }
        value={filterValue}
        onChange={handleChange}
        renderValue={(selected: any) => {
          if (selected.length > 1) {
            return `${dropDownOptionsTransalted[selectedFirstIndex]} (+ ${
              selected.length - 1
            })`;
          } else {
            return selected.join(', ');
          }
        }}
        MenuProps={MenuProps}
        shrink={filterValue?.length > 0 ? true : false}
        data-cy={props['data-cy']}
      />
    </>
  );
}
