import { EvesButton } from '@/common/components/atoms/button/button';
import { Card } from '@/common/components/molecules/card/card';
import DataTable from '@/common/components/organisms/table/dataTable';
import {
  CarConnectorConnectionType,
  TenantComponents,
} from '@/common/enums/enums';
import { withContext } from '@/common/utils/withContext';
import {
  getCarConnectorSettings,
  updateCarConnectorSetting,
} from '@/services/integrationSettings';
import {
  Add,
  BluetoothDrive,
  Delete,
  Edit,
  Info,
  Refresh,
  Save,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Connection from './connection';
import { NotifyType } from '@/common/utils/notificationService';
import { Stack, Tooltip } from '@mui/material';
import { isActive } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

const CarConnector = (props: any) => {
  const { t: translate } = useTranslation();
  const isCarConnectorComponentActive = isActive(
    TenantComponents.CAR_CONNECTOR
  );
  const [state, setStateData] = useState({
    canUpdate: true,
    carConnectorsSettings: {},
    rows: [],
    carConnectionId: '',
    totalRowsCount: 0,
    page: 0,
    limit: 50,
    rowIndex: -1,
    saveDisabled: true,
    id: '',
    sortFields: '- name',
  } as any);

  const TypeCell = (row: any) => {
    if (row.type === 'mercedes') return <div>Mercedes Connector</div>;
    if (row.type === 'targaTelematics')
      return <div>Targa Telematics Connector</div>;
    if (row.type === 'smartCar') return <div>Smart Car Connector</div>;
    return <div>Tronity Connector</div>;
  };

  const columns = [
    {
      id: 'actions',
      label: `${translate('general.actions')}`,
      textAlign: 'center',
      minWidth: 20,
    },
    {
      id: 'name',
      label: `${translate('settings.car_connector.connection.name')}`,
      textAlign: 'left',
      minWidth: 180,
      maxWidth: 240,
      sortKey: 'name',
      allowSort: true,
    },
    {
      id: 'description',
      label: `${translate('settings.car_connector.connection.description')}`,
      textAlign: 'left',
      minWidth: 200,
      maxWidth: 210,
    },
    {
      id: 'type',
      label: `${translate('settings.car_connector.connection.type')}`,
      textAlign: 'left',
      minWidth: 180,
      maxWidth: 210,
    },
  ];

  const { refetch: fetchCarConnectorSettings } = useQuery(
    'carConnectorSettings',
    () => {
      return getCarConnectorSettings({
        Identifier: TenantComponents.CAR_CONNECTOR,
        SortFields: state.sortFields,
        Limit: state.limit,
        Page: state.page + 1,
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (setting: any) => {
        const carConnectorsSettings: any = {
          identifier: TenantComponents.CAR_CONNECTOR,
        };
        const settings = setting.setting;
        if (settings) {
          const config = settings.content;
          // ID
          carConnectorsSettings.id = settings.id;
          // Sensitive data
          carConnectorsSettings.sensitiveData = settings.sensitiveData;
          // Set
          carConnectorsSettings.carConnector = config.carConnector;
        }
        setStateData((currentData: any) => {
          return {
            ...currentData,
            carConnectorsSettings: { ...carConnectorsSettings },
            rows: carConnectorsSettings?.carConnector?.connections,
            totalRowsCount: setting?.count,
            id: settings?.id,
          };
        });
      },
    }
  );

  useEffect(() => {
    fetchCarConnectorSettings();
  }, [state.sortFields, state.page, state.limit]);

  const addCarConnector = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showCarConnectorModal: true,
      };
    });
  };

  const onFormClose = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showCarConnectorModal: false,
      };
    });
  };

  const resetCarConnectorForm = (val: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        carConnectionId: val,
      };
    });
  };

  const updateCarConnector = (record: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showCarConnectorModal: true,
        carConnectionId: record.id,
        rowIndex: record.rowIndex,
      };
    });
  };

  const saveCarConnectorConnectionSettings = async (settings: any) => {
    // Check the type
    if (!settings.type) {
      settings.type = 'carConnector';
    }
    // Build setting payload
    const settingsToSave: any = {
      id: state.id,
      identifier: TenantComponents.CAR_CONNECTOR,
      sensitiveData: [],
      content: { ...settings },
    };
    settingsToSave.content.carConnector.connections.forEach(
      (settingConnection, index) => {
        switch (settingConnection.type) {
          case CarConnectorConnectionType.MERCEDES:
            settingsToSave.sensitiveData.push(
              settingsToSave.content.carConnector.connections[index]
                .mercedesConnection.clientSecret
            );
            break;
          case CarConnectorConnectionType.TRONITY:
            settingsToSave.sensitiveData.push(
              settingsToSave.content.carConnector.connections[index]
                .tronityConnection.clientSecret
            );
            break;
          case CarConnectorConnectionType.TARGA:
            settingsToSave.sensitiveData.push(
              settingsToSave.content.carConnector.connections[index]
                .targaTelematicsConnection.clientSecret
            );
            break;
          case CarConnectorConnectionType.SMART:
            settingsToSave.sensitiveData.push(
              settingsToSave.content.carConnector.connections[index]
                .smartCarConnection.clientSecret
            );
            break;
        }
      }
    );
    // Delete IDS
    const result = await updateCarConnectorSetting(settingsToSave);
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    return result;
  };

  const saveCarConnection = async () => {
    const carConnectorSettings: any = { ...state.carConnectorsSettings };

    const response: any = await saveCarConnectorConnectionSettings(
      carConnectorSettings
    );
    const notify: NotifyType = {
      message: '',
      type: 'success',
    };
    if (response.status === 'Success') {
      notify.message = `${translate('settings.car_connector.update_success')}`;
      notify.type = 'success';
      props.notificationService?.notify(notify);
      fetchCarConnectorSettings();
    } else {
      notify.message = carConnectorSettings.id
        ? `${translate('settings.car_connector.update_error')}`
        : `${translate('settings.car_connector.create_error')}`;
      notify.type = 'error';
      props.notificationService?.notify(notify);
    }
    setStateData((currentData: any) => {
      return {
        ...currentData,
        saveDisabled: true,
      };
    });
  };

  const updateData = (carConnectorsSettings: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        carConnectorsSettings,
        saveDisabled: false,
      };
    });
  };

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const primaryActions = [
    {
      title: () => `${translate('general.edit')}`,
      icon: () => Edit,
      iconColor: 'primary',
      onClick: (record: any) => updateCarConnector(record),
    },
    {
      title: () => `${translate('general.delete')}`,
      icon: () => Delete,
      iconColor: 'error',
      onClick: (record: any) => {
        props.dialogService?.showConfirm(
          {
            title: `${translate(
              'settings.car_connector.connection.delete_title'
            )}`,
            description: `${translate(
              'settings.car_connector.connection.delete_confirm'
            ).replace(/{{carConnectorConnectionName}}/, `${record.name}`)}`,
            confirmType: 'YES_NO',
          },
          (result) => {
            if (result === 'YES') {
              setStateData((currentData: any) => {
                let rows: any =
                  currentData?.carConnectorsSettings?.carConnector?.connections;
                rows = rows?.filter((data: any) => data?.id !== record?.id);
                const carConnectorsSettings = {
                  ...currentData.carConnectorsSecurrentDatattings,
                  carConnector: {
                    ...currentData?.carConnectorsSettings?.carConnector,
                    connections: rows,
                  },
                };
                return {
                  ...currentData,
                  carConnectorsSettings,
                  rows,
                  totalRowsCount: rows.length,
                  saveDisabled: false,
                };
              });
            }
          }
        );
      },
    },
  ];

  if (!isCarConnectorComponentActive)
    return (
      <div className='information_block'>
        <Card
          icon={<Info />}
          title={`${translate('chargers.connector_info_title')}`}
          isTransparent={true}
        >
          <div className='information'>
            <p data-cy='roamingHubject-content1'>{`${translate(
              'settings.car_connector.description'
            )}`}</p>
          </div>
          <div>
            <p></p>
            <p data-cy='roamingHubject-content2'>{`${translate(
              'settings.activation_contact_msg'
            )}`}</p>
          </div>
        </Card>
      </div>
    );

  return (
    <>
      <Stack direction='row' marginBottom={'40px'}>
        <EvesButton
          startIcon={<Save style={{ fontSize: '22.4px' }} />}
          color='error'
          onClick={saveCarConnection}
          style={{ padding: '8px 24px' }}
          disabled={state.saveDisabled}
          data-cy='save-btn'
        >{`${translate('general.save')}`}</EvesButton>
      </Stack>
      <Card
        icon={<BluetoothDrive />}
        title={`${translate('settings.car_connector.connection.title')}`}
        isTransparent={true}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          marginBottom={'10px'}
          sx={{
            '@media (max-width: 1366px)': {
              marginTop: '35px',
            },
          }}
        >
          <EvesButton
            startIcon={<Add style={{ fontSize: '22.4px' }} />}
            data-cy='add-btn'
            onClick={addCarConnector}
            style={{ padding: '8px 24px' }}
          >
            {' '}
            {`${translate('general.add')}`}
          </EvesButton>
          <Tooltip
            title={`${translate('Refresh')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <EvesButton
              onClick={fetchCarConnectorSettings}
              sx={{
                backgroundColor: '#3D3AD3',
                ':hover': { backgroundColor: '#3D3AD3' },
              }}
            >
              <Refresh />
            </EvesButton>
          </Tooltip>
        </Stack>
        <DataTable
          className='companies-table'
          data-cy='companies-table'
          rows={state.rows}
          columns={columns}
          actionsMenu={primaryActions}
          totalRowsCount={state.totalRowsCount}
          onChange={onTableChange}
          count={state.totalRowsCount}
          limit={state.limit}
          page={state.page}
          tablePagination={true}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          height={{ height: 'calc(100vh - 354px)' }}
        >
          <TypeCell slot='type' />
        </DataTable>
      </Card>
      {state.showCarConnectorModal && (
        <Connection
          showCarConnectorForm={state.showCarConnectorModal}
          resetCarConnectorForm={resetCarConnectorForm}
          onFormClose={onFormClose}
          carConnectionId={state.carConnectionId}
          carConnectionSettings={state.carConnectorsSettings}
          fetchAllCarConnectors={fetchCarConnectorSettings}
          canCreate={state.canCreate}
          rowIndex={state.rowIndex}
          updateData={updateData}
        />
      )}
    </>
  );
};

export default withContext(CarConnector);
