import i18next from 'i18next';
import { AssetInErrorType, ChargingStationInErrorType, Filters, OCPPPhase, TransactionInErrorType, UserInErrorType } from '../enums/enums';

const getConnectorLetterFromConnectorID = (connectorId: number): string => {
	return String.fromCharCode(65 + connectorId - 1);
};

export const OWNER_FILTER_ITEMS = {
	CurrentOrganization: 'issuer.local',
	ExternalOrganization: 'issuer.foreign',
	CurrentOrgAPI: 'Current Organization',
	ExternalOrgAPI: 'External Organization'
};

export const DefaultPageSize = 50;

export const CSV_SEPARATOR = ',';
export const CR_LF = '\r\n';

export const HTTPError = {
	SITE_AREA_TREE_ERROR: 540,
	TENANT_ALREADY_EXIST: 597,
	SMART_CHARGING_STILL_ACTIVE_FOR_SITE_AREA: 538,
	SITE_AREA_TREE_ERROR_SMART_SAME_SITE_AREA: 541,
	SITE_AREA_TREE_ERROR_SITE: 542,
	SITE_AREA_TREE_ERROR_SMART_CHARGING: 543,
	SITE_AREA_TREE_ERROR_SMART_NBR_PHASES: 544,
	SITE_AREA_TREE_ERROR_VOLTAGE: 545,
	SITE_AREA_TREE_ERROR_MULTIPLE_ACTIONS_NOT_SUPPORTED: 546,
	USER_ACCOUNT_CHANGED: 595,
	TENANT_COMPONENT_CHANGED: 596,
	USER_EMAIL_ALREADY_EXIST_ERROR: 510,
	REFUND_SESSION_OTHER_USER_ERROR: 551,
	REFUND_CONNECTION_ERROR: 552,
	CRYPTO_MIGRATION_IN_PROGRESS: 511,
	CRYPTO_ALGORITHM_NOT_SUPPORTED: 512,
	CRYPTO_KEY_LENGTH_INVALID: 513,
	CRYPTO_CHECK_FAILED: 514,
	TAG_ALREADY_EXIST_ERROR: 576,
	TAG_VISUAL_ID_ALREADY_EXIST_ERROR: 577,
	TAG_INACTIVE: 579,
	CANNOT_ACQUIRE_LOCK: 510
};

export const DEFAULT_CURRENCY_CODE = 'EUR';

export const GraphColor = {
	color_primary: '#3D3AD3',
	color_secondary: '#111111',
	color_danger: '#D32F2F',
	color_warning: '#F57C00',
	color_success: '#388E3C',
	instantPowerAmpsColor: '#1976D2',
	instantPowerAmpsL1Color: '#2196F3',
	instantPowerAmpsL2Color: '#42A5F5',
	instantPowerAmpsL3Color: '#64B5F6',
	consumptionColor: '#0097A7',
	amountColor: '#F57C00',
	stateOfChargeColor: '#388E3C',
	limitColor: '#D32F2F',
	instantVoltsColor: '#512DA8',
	instantAmpsDCColor: '#FBC02D',
	instantVoltsL1Color: '#3F51B5',
	instantVoltsL2Color: '#5C6BC0',
	instantVoltsL3Color: '#7986CB',
	defaultColor: '#3D3AD3',
	chartDefaultColor: 'rgba(0, 0, 0)',
};

export const StatusCodes = {
	NOT_FOUND: 404,
	THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA: 539,
	CHARGE_POINT_NOT_VALID: 584,
	FEATURE_NOT_SUPPORTED_ERROR: 585,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	BAD_REQUEST: 400,
	CONFLICT: 406,
	REQUEST_TIMEOUT: 408,
	MOVED_PERMANENTLY: 301,
};
export const SUPPORTED_LOCALES = Object.freeze(['en_US', 'fr_FR', 'es_ES', 'de_DE', 'pt_PT', 'it_IT', 'cs_CZ', 'en_AU']);

export const LOCALE_DESCRIPTION = new Map([
	['locale_desc_en_US', 'English'],
	['locale_desc_fr_FR', 'French',],
	['locale_desc_es_ES', 'Spanish'],
	['locale_desc_de_DE', 'German'],
	['locale_desc_pt_PT', 'Portuguese'],
	['locale_desc_it_IT', 'Italian'],
	['locale_desc_cs_CZ', 'Czech'],
	['locale_desc_en_AU', 'Australian'],
]);

export const phaseAssignmentToGridMapThreePhased = [
	{ label: 'RST (L1, L2, L3)', value: { csPhaseL1: OCPPPhase.L1, csPhaseL2: OCPPPhase.L2, csPhaseL3: OCPPPhase.L3 } },
	{ label: 'STR (L2, L3, L1)', value: { csPhaseL1: OCPPPhase.L2, csPhaseL2: OCPPPhase.L3, csPhaseL3: OCPPPhase.L1 } },
	{ label: 'TRS (L3, L1, L2)', value: { csPhaseL1: OCPPPhase.L3, csPhaseL2: OCPPPhase.L1, csPhaseL3: OCPPPhase.L2 } },
];

export const phaseAssignmentToGridMapSinglePhased = [
	{ label: 'R(L1-N)', value: { csPhaseL1: OCPPPhase.L1, csPhaseL2: null, csPhaseL3: null } },
	{ label: 'S(L2-N)', value: { csPhaseL1: OCPPPhase.L2, csPhaseL2: null, csPhaseL3: null } },
	{ label: 'T(L3-N)', value: { csPhaseL1: OCPPPhase.L3, csPhaseL2: null, csPhaseL3: null } },
	{ label: 'RS(L1-L2-G)', value: { csPhaseL1: OCPPPhase.L4, csPhaseL2: null, csPhaseL3: null } },
	{ label: 'ST(L2-L3-G)', value: { csPhaseL1: OCPPPhase.L5, csPhaseL2: null, csPhaseL3: null } },
	{ label: 'TR(L3-L1-G)', value: { csPhaseL1: OCPPPhase.L6, csPhaseL2: null, csPhaseL3: null } },
];

const ListOfConnectors = () => {
	const inValue = Array.from(Array(26).keys()).map(
		(element: number) => {
			const value = (element + 1).toString();
			const key = getConnectorLetterFromConnectorID(element + 1);
			return { key, value, };
		});
	const connectorObjects = {};
	inValue.forEach(e => {
		connectorObjects[e.key] = e.value;
	});
	return connectorObjects;

};

export const filterSelector = {
	[Filters.OWNER]: {
		apiFilter: 'Issuer',
		value: { 'issuer.local': true, 'issuer.foreign': false }
	},
	[Filters.ROLES]: {
		apiFilter: 'Role',
		value: { 'users.role_admin': 'A', 'users.role_basic': 'B', 'users.role_demo': 'D' }
	},
	[Filters.STATUS]: {
		apiFilter: 'Status',
		value: { 'users.status_active': 'A', 'users.status_blocked': 'B', 'users.status_inactive': 'I', 'users.status_locked': 'L', 'users.status_pending': 'P' }
	},
	[Filters.API_USER]: {
		apiFilter: 'Technical',
		value: { 'users.technical_user': true, 'users.non_technical_user': false }
	},
	[Filters.INACTIVITY]: {
		apiFilter: 'InactivityStatus',
		value: { 'transactions.inactivity_warning': 'W', 'transactions.inactivity_error': 'E' }
	},
	[Filters.LEVELS]: {
		apiFilter: 'Level',
		value: { 'logs.error': 'E', 'logs.warning': 'W', 'logs.info': 'I', 'logs.debug': 'D' }
	},
	[Filters.SOURCES]: {
		apiFilter: 'Source',
		value: {
			'Batch Server': 'Batch',
			'Json Server': 'Json',
			'Ocpi Server': 'Ocpi',
			'OData Server': 'OData',
			'Oicp Server': 'Oicp',
			'Rest Server': 'Rest',
			'Soap Server': 'Soap'
		}
	},
	[Filters.SMART_CHARGING]: {
		apiFilter: 'SmartCharging',
		value: {
			'Load Balancing': 'loadBalacing',
			'Demand management': 'demandManagement',
			'Not smart charging': 'NoSmartCharging',
		}
	},
	[Filters.ACCESS_MODE]: {
		apiFilter: 'FreeAccess',
		value: { 'users.user_with_freeAccess': true, 'users.user_without_freeAccess': false }
	},
	[Filters.ERROR]: {
		apiFilter: 'ErrorType',
		value: {
			'users.errors.failed_billing_synchro.title': 'failed_billing_synchro',
			'users.errors.inactive_user_account.title': 'inactive_user_account',
			'users.errors.inactive_user.title': 'inactive_user',
			'users.errors.unassigned_user.title': 'unassigned_user',
		}
	},
	[Filters.CHARGINGSTATIONERROR]: {
		apiFilter: 'ErrorType',
		value: {
			'chargers.errors.connector_error.title': 'connector_error',
			'chargers.errors.missing_settings.title': 'missing_settings',
			'chargers.errors.missing_site_area.title': 'missing_site_area',
			'chargers.errors.connection_broken.title': 'connection_broken',
		}
	},
	[Filters.ASSET]: {
		apiFilter: 'ErrorType',
		value: {
			'chargers.errors.missing_site_area.title': 'missing_site_area',
		}
	},
	[Filters.RFIDSTATUS]: {
		apiFilter: 'Status',
		value: { 'tags.activated': 'A', 'tags.deactivated': 'I' }
	},
	[Filters.SESSION_REFUNDING_STATUS]: {
		refundStatus: 'RefundStatus',
		value: { 'transactions.refund_undefined': 'notSubmitted', 'transactions.refund_submitted': 'submitted', 'transactions.refund_cancelled': 'cancelled', 'transactions.refund_approved': 'approved' }
	},
	[Filters.NEWCHARGINGTAB]: {
		apiFilter: 'Status',
		value: { 'Connected': 'connected', 'Registered': 'registered', 'Installed': 'installed' }
	},
	[Filters.CONNECTORS]: {
		apiFilter: 'Connectors',
		value: ListOfConnectors(),
	},
	[Filters.INERROR_ERROR]: {
		apiFilter: 'ErrorType',
		value: {
			'transactions.errors.average_consumption_greater_than_connector_capacity.title': 'average_consumption_greater_than_connector_capacity',
			'transactions.errors.long_inactivity.title': 'long_inactivity',
			'transactions.errors.incorrect_starting_date.title': 'incorrect_starting_date',
			'transactions.errors.low_consumption.title': 'low_consumption',
			'transactions.errors.low_duration.title': 'low_duration',
			'transactions.errors.missing_price.title': 'missing_price',
			'transactions.errors.negative_duration.title': 'negative_inactivity',
			'transactions.errors.negative_inactivity.title': 'negative_inactivity',
			'transactions.errors.no_consumption.title': 'no_consumption',
			'transactions.errors.no_billing_data.title': 'no_billing_data',
			'transactions.errors.missing_user.title': 'missing_user',
		}
	},
	[Filters.INVOICE_STATUS]: {
		apiFilter: 'Status',
		value: {
			'invoices.status.paid': 'paid',
			'invoices.status.unpaid': 'open',
			'invoices.status.draft': 'draft',
			'invoices.status.deleted': 'deleted',
			'invoices.status.uncollectible': 'uncollectible',
			'invoices.status.void': 'void',
		},
	},
	[Filters.TRANSFER_STATUS]: {
		apiFilter: 'Status',
		value: {
			'transfers.status.draft': 'draft',
			'transfers.status.pending': 'pending',
			'transfers.status.finalized': 'finalized',
			'transfers.status.transferred': 'transferred',
		},
	},
	[Filters.TRANSACTION_STATUS]: {
		apiFilter: 'Status',
		value: {
			'invoices.status.paid': 'paid',
			'invoices.status.unpaid': 'open',
			'invoices.status.draft': 'draft',
			'invoices.status.deleted': 'deleted',
			'invoices.status.uncollectible': 'uncollectible',
			'invoices.status.void': 'void',

		},
	},
	[Filters.CHARGINGSTATION_STATUS]: {
		apiFilter: 'Status',
		value: {
			'Connected': 'Connected',
			'Commissioned': 'Commissioned',
			'Active': 'Active',
			'Inactive': 'Inactive',
			'RMA': 'RMA',

		},
	},
	[Filters.SITES]: {
		apiFilter: 'Site',
		value: { 'issuer.local': true, 'issuer.foreign': false }
	},
};

export const UserInErrorTypeDetail = {
	[UserInErrorType.FAILED_BILLING_SYNCHRO]: {
		title: 'users.errors.failed_billing_synchro.title',
		description: 'users.errors.failed_billing_synchro.description',
		action: 'users.errors.failed_billing_synchro.action'
	},
	[UserInErrorType.INACTIVE_USER_ACCOUNT]: {
		title: 'users.errors.inactive_user_account.title',
		description: 'users.errors.inactive_user_account.description',
		action: 'users.errors.inactive_user_account.action'
	},
	[UserInErrorType.NOT_ACTIVE]: {
		title: 'users.errors.inactive_user.title',
		description: 'users.errors.inactive_user.description',
		action: 'users.errors.inactive_user.action'
	},
	[UserInErrorType.NOT_ASSIGNED]: {
		title: 'users.errors.unassigned_user.title',
		description: 'users.errors.unassigned_user.description',
		action: 'users.errors.unassigned_user.action'
	}
};

export const ChargingStationInErrorTypeDetail = {
	[ChargingStationInErrorType.MISSING_SETTINGS]: {
		title: 'chargers.errors.missing_settings.title',
		description: 'chargers.errors.missing_settings.description',
		action: 'chargers.errors.missing_settings.action'
	},
	[ChargingStationInErrorType.CONNECTION_BROKEN]: {
		title: 'chargers.errors.connection_broken.title',
		description: 'chargers.errors.connection_broken.description',
		action: 'chargers.errors.connection_broken.action'
	},
	[ChargingStationInErrorType.MISSING_SITE_AREA]: {
		title: 'chargers.errors.missing_site_area.title',
		description: 'chargers.errors.missing_site_area.description',
		action: 'chargers.errors.missing_site_area.action'
	},
	[ChargingStationInErrorType.CONNECTOR_ERROR]: {
		title: 'chargers.errors.connector_error.title',
		description: 'chargers.errors.connector_error.description',
		action: 'chargers.errors.connector_error.action'
	},
};

export const AssetInErrorTypeDetail = {
	[AssetInErrorType.MISSING_SITE_AREA]: {
		title: 'chargers.errors.missing_site_area.title',
		description: 'The asset needs to be assigned to a site area',
		action: 'Edit the asset and maintain the site area'
	},

};

export const TransactionInErrorTypeDetail = {
	[TransactionInErrorType.NO_CONSUMPTION]: {
		title: 'transactions.errors.no_consumption.title',
		description: `${i18next.t('transactions.errors.no_consumption.description')}`,
		action: `${i18next.t('transactions.errors.no_consumption.action')}`
	},
	[TransactionInErrorType.LOW_CONSUMPTION]: {
		title: 'transactions.errors.low_consumption.title',
		description: `${i18next.t('transactions.errors.low_consumption.description')}`,
		action: `${i18next.t('transactions.errors.low_consumption.action')}`
	},
	[TransactionInErrorType.OVER_CONSUMPTION]: {
		title: 'transactions.errors.average_consumption_greater_than_connector_capacity.title',
		description: `${i18next.t('transactions.errors.average_consumption_greater_than_connector_capacity.description')}`,
		action: `${i18next.t('transactions.errors.average_consumption_greater_than_connector_capacity.action')}`
	},
	[TransactionInErrorType.NEGATIVE_INACTIVITY]: {
		title: 'transactions.errors.negative_inactivity.title',
		description: `${i18next.t('transactions.errors.negative_inactivity.description')}`,
		action: `${i18next.t('transactions.errors.negative_inactivity.action')}`
	},
	[TransactionInErrorType.LONG_INACTIVITY]: {
		title: 'transactions.errors.long_inactivity.title',
		description: `${i18next.t('transactions.errors.long_inactivity.description')}`,
		action: `${i18next.t('transactions.errors.long_inactivity.action')}`
	},
	[TransactionInErrorType.NEGATIVE_DURATION]: {
		title: 'transactions.errors.negative_duration.title',
		description: `${i18next.t('transactions.errors.negative_duration.description')}`,
		action: `${i18next.t('transactions.errors.negative_duration.action')}`
	},
	[TransactionInErrorType.LOW_DURATION]: {
		title: 'transactions.errors.low_duration.title',
		description: `${i18next.t('transactions.errors.low_duration.description')}`,
		action: `${i18next.t('transactions.errors.low_duration.action')}`
	},
	[TransactionInErrorType.MISSING_PRICE]: {
		title: 'transactions.errors.missing_price.title',
		description: `${i18next.t('transactions.errors.missing_price.description')}`,
		action: `${i18next.t('transactions.errors.missing_price.action')}`
	},
	[TransactionInErrorType.INVALID_START_DATE]: {
		title: 'transactions.errors.incorrect_starting_date.title',
		description: `${i18next.t('transactions.errors.incorrect_starting_date.description')}`,
		action: `${i18next.t('transactions.errors.incorrect_starting_date.action')}`
	},
	[TransactionInErrorType.MISSING_USER]: {
		title: 'transactions.errors.missing_user.title',
		description: `${i18next.t('transactions.errors.missing_user.description')}`,
		action: `${i18next.t('transactions.errors.missing_user.action')}`
	},
	[TransactionInErrorType.NO_BILLING_DATA]: {
		title: 'transactions.errors.no_billing_data.title',
		description: `${i18next.t('transactions.errors.no_billing_data.description')}`,
		action: `${i18next.t('transactions.errors.no_billing_data.action')}`
	},
};

export const INFINITE_RECORDS = -1;

export const LogLevels = [
	{ key: 'E', value: 'logs.error', type: 'Faulted' },
	{ key: 'W', value: 'logs.warning', type: 'Pending' },
	{ key: 'I', value: 'logs.info', type: 'Available' },
	{ key: 'D', value: 'logs.debug', type: 'Reserved' },
];

export const URL_PATTERN = /^(?:https?|wss?):\/\/((?:[\w-]+)(?:\.[\w-]+)*)(?:[\w.,@?^=%&amp;:\\/~+#-]*[\w@?^=%&amp;\\/~+#-])?$/;

export const currencyOptions = [
	{ label: 'Australian Dollar', value: 'AUD' },
	{ label: 'Brazilian Real', value: 'BRL' },
	{ label: 'Canadian Dollar', value: 'CAD' },
	{ label: 'Czech Koruna', value: 'CZK' },
	{ label: 'Danish Krone', value: 'DKK' },
	{ label: 'Euro', value: 'EUR' },
	{ label: 'Hong Kong Dollar', value: 'HKD' },
	{ label: 'Hungarian Forint', value: 'HUF' },
	{ label: 'Israeli New Sheqel', value: 'ILS' },
	{ label: 'Japanese Yen', value: 'JPY' },
	{ label: 'Malaysian Ringgit', value: 'MYR' },
	{ label: 'Mexican Peso', value: 'MXN' },
	{ label: 'Norwegian Krone', value: 'NOK' },
	{ label: 'New Zealand Dollar', value: 'NZD' },
	{ label: 'Philippine Peso', value: 'PHP' },
	{ label: 'Polish Zloty', value: 'PLN' },
	{ label: 'Pound Sterling', value: 'GBP' },
	{ label: 'Singapore Dollar', value: 'SGD' },
	{ label: 'Swedish Krona', value: 'SEK' },
	{ label: 'Swiss Franc', value: 'CHF' },
	{ label: 'Taiwan New Dollar', value: 'TWD' },
	{ label: 'Thai Baht', value: 'THB' },
	{ label: 'Turkish Lira', value: 'TRY' },
	{ label: 'U.S. Dollar', value: 'USD' },
];

export const imageTypeOptions = [
	{ label: 'JPG', value: 'jpg' },
	{ label: 'PNG', value: 'png' },
	{ label: 'SVG', value: 'svg' },
	{ label: 'GIF', value: 'gif' },
];

export const categoryImageOptions = [
	{ label: 'Charger', value: 'CHARGER' },
	{ label: 'Entrance', value: 'ENTRANCE' },
	{ label: 'Location', value: 'LOCATION' },
	{ label: 'Network', value: 'NETWORK' },
	{ label: 'Operator', value: 'OPERATOR' },
	{ label: 'Owner', value: 'OWNER' },
	{ label: 'Other', value: 'OTHER' },
];

export const constMinDivisorBar = 40;
export const constMinDivisorPie = 40;

export class ChartConstants {
	public static STACKED_ITEM = 'item';
	public static STACKED_TOTAL = 'total';
}

export const languageLocale = {
	'cs': 'cs-CS',
	'fr': 'fr-FR',
	'es': 'es-ES',
	'de': 'de-DE',
	'pt': 'pt-BR',
	'it': 'it-IT',
	'en': 'en-US',
};

export const logConstantURLs = {
	system: process.env.REACT_APP_SYSTEM_LOG,
	application: process.env.REACT_APP_APPLICATION_LOG,
};