import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { connect } from 'react-redux';
import {
  BillingModel,
  IBillingFormPropsModel,
} from '../../shared/models/integrationSettings';
import {
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material';
import { Card } from '@/common/components/molecules/card/card';
import {
  Business,
  Clear,
  Info,
  InsertLink,
  Launch,
  Save,
  ToggleOff,
} from '@mui/icons-material';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  checkBillingConnection,
  clearBillingTestData,
  getBillingSettings,
  getBillingTaxes,
  saveBillingSettings,
} from '@/services/integrationSettings';
import { StatusCodes } from '@/common/constants/constants';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import EvesSelect from '@/common/components/atoms/select/select';
import ConnectedAccounts from './connectedAccounts';
import { EvesButton } from '@/common/components/atoms/button/button';
import { BillingSettingsType, TenantComponents } from '@/common/enums/enums';
import { NotifyType } from '@/common/utils/notificationService';
import { isActive } from '@/common/utils/utils';
import { formSchema } from '../../shared/schemas/billingFormSchema';
import { useTranslation } from 'react-i18next';

const Billing = (props: IBillingFormPropsModel) => {
  const { t: translate } = useTranslation();
  const billingForm = props.formService as UseFormReturn<BillingModel, any>;

  const [state, setStateData] = useState({
    billingSettings: {},
    isBillingTransactionEnabled: false,
    isClearTestDataVisible: false,
    isTransactionBillingActivated: false,
    taxes: [],
    taxOptions: [],
  } as any);

  const isBillingComponentActive: any = isActive(TenantComponents.BILLING);
  const isBillingPlatformActive: any = isActive(
    TenantComponents.BILLING_PLATFORM
  );

  const checkConnectionContext = (billingSettings: any) => {
    let isClearTestDataVisible = false;
    if (billingSettings?.billing?.isTransactionBillingActivated) {
      // TODO - Get the information via a dedicated endpoint!
      if (
        billingSettings?.type === 'stripe' &&
        billingSettings?.stripe?.publicKey?.startsWith('pk_test_')
      ) {
        isClearTestDataVisible = true;
      }
    }
    // Show the "Clear Test Data" button
    setStateData((currentData: any) => {
      return {
        ...currentData,
        isClearTestDataVisible,
        isTransactionBillingActivated: billingSettings
          ? billingSettings?.billing?.isTransactionBillingActivated
          : currentData.isTransactionBillingActivated,
      };
    });
  };

  const { refetch: fetchBillingSettings } = useQuery(
    'Billing Settings',
    () => {
      return getBillingSettings();
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (billingSettings: any) => {
        if (billingSettings) {
          setStateData((currentData: any) => {
            return {
              ...currentData,
              billingSettings,
              isTransactionBillingActivated: billingSettings
                ? billingSettings?.billing?.isTransactionBillingActivated
                : currentData.isTransactionBillingActivated,
            };
          });
          checkConnectionContext(billingSettings);
          updateFormData(billingSettings);
        }
      },
      onError: (error: any) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            props.notificationService?.notify({
              message: `${translate('settings.refund.not_found')}`,
              type: 'error',
            });
            break;
          default:
            props.notificationService?.notify({
              message: `${translate('general.unexpected_error_backend')}`,
              type: 'error',
            });
        }
      },
    }
  );

  const activateBillingTransaction = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate(
          'settings.billing.transaction_billing_activation_title'
        )}`,
        description: (
          <p
            dangerouslySetInnerHTML={{
              __html: translate(
                'settings.billing.transaction_billing_activation_confirm'
              ),
            }}
          ></p>
        ),
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          const billingSettings: any = { ...state.billingSettings };
          billingSettings.billing.isTransactionBillingActivated = true;
          updateBillingSettings(billingSettings);
        }
      }
    );
  };

  // const clearTestData = () => {
  //   props.dialogService?.showConfirm(
  //     {
  //       title: `${translate('settings.billing.billing_clear_test_data_title')}`,
  //       description: `${translate(
  //         'settings.billing.billing_clear_test_data_confirm'
  //       )}`,
  //       confirmType: 'YES_NO',
  //     },
  //     (result) => {
  //       if (result === 'YES') {
  //         triggerTestDataCleanup();
  //       }
  //     }
  //   );
  // };

  // const { mutate: triggerTestDataCleanup } = useMutation(
  //   () => clearBillingTestData(),
  //   {
  //     onSuccess: (data: any) => {
  //       const notify: NotifyType = {
  //         message: '',
  //         type: 'success',
  //       };
  //       if (data.succeeded) {
  //         (notify.message = `${translate(
  //           'settings.billing.billing_clear_test_data_success'
  //         )}`),
  //           (notify.type = 'success');
  //         props.notificationService?.notify(notify);
  //         fetchBillingSettings();
  //       } else {
  //         (notify.message = `${translate(
  //           'settings.billing.billing_clear_test_data_error'
  //         )}`),
  //           (notify.type = 'error');
  //         props.notificationService?.notify(notify);
  //       }
  //     },
  //   }
  // );

  // const checkConnection = async (
  //   activateTransactionBilling: boolean = false
  // ) => {
  //   const response: any = await checkBillingConnection();
  //   const notify: NotifyType = {
  //     message: '',
  //     type: 'success',
  //   };
  //   if (response.connectionIsValid) {
  //     notify.message = `${translate('settings.billing.connection_success')}`;
  //     notify.type = 'success';
  //     props.notificationService?.notify(notify);
  //     if (activateTransactionBilling) {
  //       activateBillingTransaction();
  //     }
  //   } else {
  //     notify.message = `${translate('settings.billing.connection_error')}`;
  //     notify.type = 'error';
  //     props.notificationService?.notify(notify);
  //   }
  // };

  const { refetch: fetchBillingTaxes } = useQuery(
    'Billing Taxes',
    () => {
      return getBillingTaxes();
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        const taxOptions: any[] = [];
        data.result.forEach((tax: any) => {
          const description = tax.description ? '- ' + tax.description : '';
          taxOptions.push({
            label: `${tax.displayName} ${description} - (${tax.percentage}%)`,
            value: tax.id,
          });
        });
        setStateData((currentData: any) => {
          return {
            ...currentData,
            taxes: data.result,
            taxOptions,
          };
        });
      },
      onError: (error: any) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            props.notificationService?.notify({
              message: `${translate('settings.refund.not_found')}`,
              type: 'error',
            });
            break;
          default:
            props.notificationService?.notify({
              message: `${translate('general.unexpected_error_backend')}`,
              type: 'error',
            });
        }
      },
    }
  );

  const updateFormData = (billingSettings: any) => {
    const billingFormData: any = {};
    const transactionBillingActivated =
      billingSettings?.billing?.isTransactionBillingActivated;
    if (transactionBillingActivated) {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isTransactionBillingActivated: transactionBillingActivated,
        };
      });
    }
    if (!(Object.keys(billingSettings?.stripe).length === 0)) {
      const stripeSetting = billingSettings.stripe;
      billingFormData.stripe = {
        ...stripeSetting,
        publicKey:
          stripeSetting.publicKey !== null ? stripeSetting.publicKey : '',
        secretKey:
          stripeSetting.secretKey !== null ? stripeSetting.secretKey : '',
        url: stripeSetting.url !== null ? stripeSetting.url : '',
      };
    }
    if (!(Object.keys(billingSettings?.billing).length === 0)) {
      const billingSetting = billingSettings.billing;
      billingFormData.billing = { ...billingSetting };
    }
    billingForm.reset({ ...billingFormData });
  };

  useEffect(() => {
    fetchBillingSettings();
    fetchBillingTaxes();
  }, []);

  const updateBillingSettings = async (billingSettings: any) => {
    const response: any = await saveBillingSettings(billingSettings);
    const notify: NotifyType = {
      message: '',
      type: 'success',
    };
    if (response.status === 'Success') {
      notify.message = billingSettings.id
        ? `${translate('settings.billing.update_success')}`
        : `${translate('settings.billing.create_success')}`;
      notify.type = 'success';
      props.notificationService?.notify(notify);
      fetchBillingSettings();
      fetchBillingTaxes();
    } else {
      notify.message = billingSettings.id
        ? `${translate('settings.billing.update_error')}`
        : `${translate('settings.billing.create_error')}`;
      notify.type = 'error';
      props.notificationService?.notify(notify);
    }
  };

  const onFormSubmit = () => {
    const newSettings: any = { ...billingForm.getValues() };
    const billingSettings: any = { ...state.billingSettings };
    billingSettings.type = BillingSettingsType.STRIPE;
    if (newSettings?.billing?.isTransactionBillingActivated) {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isTransactionBillingActivated:
            newSettings.billing.isTransactionBillingActivated,
        };
      });
    } else {
      setStateData((currentData: any) => {
        return {
          ...currentData,
          isTransactionBillingActivated:
            state.billingSettings.billing.isTransactionBillingActivated,
        };
      });
    }
    billingSettings.billing = newSettings.billing;
    billingSettings.billing.isTransactionBillingActivated =
      state.isTransactionBillingActivated;
    billingSettings.stripe = newSettings.stripe;
    updateBillingSettings(billingSettings);
  };

  const redirect = () => {
    window.open(billingForm.getValues().stripe.url, '_blank');
  };

  if (!isBillingComponentActive)
    return (
      <div className='information_block'>
        <Card icon={<Info />} title='Information' isTransparent={true}>
          <div className='information'>
            <p data-cy='roamingHubject-content1'>
              The billing interface enables billing capabilities for charging
              station providers.
            </p>
          </div>
          <div>
            <p></p>
            <p data-cy='roamingHubject-content2'>
              To enable this feature, please contact your EVES representative.
            </p>
          </div>
        </Card>
      </div>
    );
  return (
    <>
      {isBillingComponentActive && (
        <Stack direction='row' spacing={2} marginBottom={'40px'}>
          <EvesButton
            type='button'
            disabled={
              !(billingForm.formState.isValid && billingForm.formState.isDirty)
            }
            onClick={onFormSubmit}
            startIcon={<Save />}
            data-cy='save-btn'
            color={'error'}
            variant='contained'
          >
            {`${translate('general.save')}`}
          </EvesButton>
          {/* <EvesButton
            type='button'
            disabled={
              !billingForm.formState.isValid || billingForm.formState.isDirty
            }
            onClick={() => checkConnection()}
            startIcon={<InsertLink />}
            data-cy='connection-btn'
            variant='contained'
          >
            {`${translate('settings.billing.check_connection')}`}
          </EvesButton>
          <EvesButton
            type='button'
            disabled={
              !billingForm.formState.isValid ||
              billingForm.formState.isDirty ||
              !state.billingSettings?.billing?.taxID ||
              state.billingSettings?.billing?.isTransactionBillingActivated
            }
            onClick={() => checkConnection(true)}
            color='error'
            startIcon={<ToggleOff />}
            data-cy='billing-activation-btn'
            variant='contained'
          >
            {`${translate('settings.billing.transaction_billing_activation')}`}
          </EvesButton> */}
          {/* {state.isClearTestDataVisible && (
            <EvesButton
              type='button'
              sx={{
                background: !(
                  !billingForm.formState.isValid ||
                  billingForm.formState.isDirty
                )
                  ? '#D3302F !important'
                  : 'rgba(61, 58, 211, 0.2) !important',
                color: !(
                  !billingForm.formState.isValid ||
                  billingForm.formState.isDirty
                )
                  ? 'white !important'
                  : 'rgba(0, 0, 0, 0.26) !important',
              }}
              disabled={
                !billingForm.formState.isValid || billingForm.formState.isDirty
              }
              onClick={clearTestData}
              startIcon={<Clear />}
              color={'error'}
              data-cy='delete-test-data'
              variant='contained'
            >
              {`${translate('settings.billing.billing_clear_test_data')}`}
            </EvesButton>
          )} */}
        </Stack>
      )}
      <Card
        icon={<Business />}
        title={`${translate('settings.billing.title')}`}
        isTransparent={true}
        sx={{ marginBottom: '40px' }}
      >
        <>
          <Grid container rowSpacing={1} columnSpacing={3}>
            {/* {state.billingSettings?.billing?.isTransactionBillingActivated ? (
              <Grid item xs={12}>
                <p style={{ color: 'red' }}>{`${translate(
                  'settings.billing.deactivated_setting_message'
                )}`}</p>
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <Controller
                name='stripe.url'
                control={billingForm.control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id='authenticationUrl'
                    label={`${translate('settings.billing.stripe.url')}`}
                    data-cy='refunding-authenticationUrl'
                    fullWidth
                    disabled={state.isTransactionBillingActivated}
                    variant='standard'
                    error={!!billingForm.formState?.errors.stripe?.url}
                    InputProps={{
                      endAdornment: (
                        <>
                          <InputAdornment
                            position='end'
                            style={{ cursor: 'pointer' }}
                          >
                            <IconButton>
                              <Launch
                                onClick={() => redirect()}
                                sx={{ color: 'black', height: '15px' }}
                              />
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    helperText={`${translate(
                      billingForm.formState?.errors.stripe?.url?.message || ' '
                    )}`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Controller
                name='stripe.publicKey'
                control={billingForm.control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id='apiUrl'
                    label={`${translate('settings.billing.stripe.public_key')}`}
                    fullWidth
                    disabled={state.isTransactionBillingActivated}
                    variant='standard'
                    data-cy='apiUrl'
                    error={!!billingForm.formState?.errors.stripe?.publicKey}
                    helperText={`${translate(
                      billingForm.formState?.errors.stripe?.publicKey
                        ?.message || ' '
                    )}`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Controller
                name='stripe.secretKey'
                control={billingForm.control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id='clientId'
                    label={`${translate('settings.billing.stripe.secret_key')}`}
                    data-cy='clientId'
                    type='password'
                    fullWidth
                    disabled={state.isTransactionBillingActivated}
                    variant='standard'
                    error={!!billingForm.formState?.errors.stripe?.secretKey}
                    helperText={`${translate(
                      billingForm.formState?.errors.stripe?.secretKey
                        ?.message || ' '
                    )}`}
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              {`${translate('settings.billing.stripe.billing_method')}`}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='billing.immediateBillingAllowed'
                control={billingForm.control}
                render={({ field }) => (
                  <FormControlLabel
                    data-cy='user-free-access'
                    control={
                      <EvesCheckbox
                        {...field}
                        checked={billingForm.watch(
                          'billing.immediateBillingAllowed'
                        )}
                      />
                    }
                    label={`${translate(
                      'settings.billing.stripe.immediate_billing'
                    )}`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='billing.periodicBillingAllowed'
                control={billingForm.control}
                render={({ field }) => (
                  <FormControlLabel
                    data-cy='user-free-access'
                    control={
                      <EvesCheckbox
                        {...field}
                        checked={billingForm.watch(
                          'billing.periodicBillingAllowed'
                        )}
                      />
                    }
                    label={`${translate(
                      'settings.billing.stripe.periodic_billing'
                    )}`}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Controller
                name='billing.taxID'
                control={billingForm.control}
                render={({ field }) => (
                  <EvesSelect
                    label={`${translate('settings.billing.stripe.tax')}`}
                    options={state.taxOptions}
                    {...field}
                    onChange={(event: any) => {
                      field.onChange(event);
                    }}
                    inputProps={{
                      readOnly: state.taxOptions.length == 0 ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Controller
                name='billing.platformFeeTaxID'
                control={billingForm.control}
                render={({ field }) => (
                  <EvesSelect
                    label={`${translate('settings.billing.stripe.fee_tax')}`}
                    options={state.taxOptions}
                    {...field}
                    onChange={(event: any) => {
                      field.onChange(event);
                    }}
                    inputProps={{
                      readOnly: state.taxOptions.length == 0 ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      </Card>
      {isBillingComponentActive &&
        isBillingPlatformActive &&
        state.isTransactionBillingActivated && (
          <ConnectedAccounts billingData={state.billingSettings} />
        )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(Billing, {
      schema: formSchema,
      defaultValues: { ...new BillingModel() },
    })
  )
);
