import { Info, Save } from '@mui/icons-material';
import { connect, useSelector } from 'react-redux';
import { IPricingFormPropsModel, SimplePricingModel } from '../../shared/models/integrationSettings';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UseFormReturn} from 'react-hook-form';
import { withContext, withUseFormHook } from '@/common/utils/withContext';
import { PricingSettingsType, RestResponse, TenantComponents } from '@/common/enums/enums';
import { getPricingSettings, updatePricingSettings } from '@/services/integrationSettings';
import { StatusCodes } from '@/common/constants/constants';
import { convertToFloat, handleHttpError, isActive } from '@/common/utils/utils';
import { formSchema } from '../../shared/schemas/simplePricingFormSchema';
import Simple from './simple';
import { IUserContextModel } from '@/store/auth/types';
import { NotifyType } from '@/common/utils/notificationService';
import { Stack } from '@mui/material';
import { EvesButton } from '@/common/components/atoms/button/button';
import PriceList from '@/modules/shared/components/price/priceList';
import { Card } from '@/common/components/molecules/card/card';
import { useTranslation } from 'react-i18next';

const Pricing = (props: IPricingFormPropsModel) => {
	const { t: translate } = useTranslation();
	const pricingForm = props.formService as UseFormReturn<SimplePricingModel, any>;
	const [state, setStateData] = useState({
		showSimplePricing: false,
		isCurrencyCodeReadonly: false,
		showPricingDefinitions: false,
		isPriceModalOpen: true,
		siteName: '',
		pricingSettings: {},
		currencyData:''
	} as any);
    const [currency,setCurrency] = useState<any>();

	const notify: NotifyType = {
		message: '',
		type: 'success',
	};

	const isPricingComponentActive = isActive(TenantComponents.PRICING);

	const getPricingParams = () => {
		return {Identifier: TenantComponents.PRICING};
	};

	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);

	const checkSettingsContext = (settings: any) => {
		let showSimplePricing: boolean = false;
		let isCurrencyCodeReadonly: boolean = false;
		let showPricingDefinitions: boolean = false;
		if (settings?.type === PricingSettingsType.SIMPLE) {
			// Show the Simple pricing Form
			showSimplePricing = true;
			// Get the current currency code from the user token
			const currentCurrencyCode = (authContext.userInfo.currency) ? authContext.userInfo.currency : '';
			// Currency code cannot be changed once it is set
			isCurrencyCodeReadonly = !!currentCurrencyCode;
			// Show Pricing Definitions - only shown when the currency code is set
			showPricingDefinitions = showSimplePricing && isCurrencyCodeReadonly;
			// Check the User Token
			if ( settings?.simple.currency && currentCurrencyCode !== settings?.simple.currency ) {
				// Not in sync - Currency has been change by another user
				// TODO: Force logout?
			}
		}
		setStateData((currentData: any) => {
			return {
				...currentData,
				pricingSettings: settings,
				showSimplePricing,
				isCurrencyCodeReadonly,
				showPricingDefinitions
			};
		});

		pricingForm.reset({
			price: settings.simple.price,
			currency: settings.simple.currency
		});
	};

	const { refetch: fetchPricingSettings } = useQuery(
		'Pricing Settings',
		() => {
			return getPricingSettings(getPricingParams());
		},
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: false,
			onSuccess: async (res: any) => {
				const pricingSettings = {
					identifier: TenantComponents.PRICING,
				} as any;
				if (res) {
					const settings=res.setting;
					const config = settings.content;
                    setCurrency(config);
					// ID
					pricingSettings.id = settings.id;
					pricingSettings.sensitiveData = settings.sensitiveData;
					// Simple price
					if (config.simple) {
						pricingSettings.type = PricingSettingsType.SIMPLE;
						pricingSettings.simple = {
							price: config.simple.price ? convertToFloat(config.simple.price) : 0,
							currency: config.simple.currency ? config.simple.currency : '',
						};
					}
				}
				checkSettingsContext(pricingSettings);
			},
			onError: (error: any) => {
				switch (error.status) {
				case StatusCodes.NOT_FOUND:
					props.notificationService?.notify({
						message:`${translate('settings.pricing.not_found')}`,
						type: 'error'
					});
					break;
				default:
					props.notificationService?.notify({
						message:`${translate('general.unexpected_error_backend')}`,
						type: 'error'
					});
				}
			}
		}
	);
	useEffect(() => {
		fetchPricingSettings();
	}, []);

	const onPriceModalClose = () => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				isPriceModalOpen: false,
			};
		});
	};

	const save = (content: any) => {
	content.simple.currency = state.currencyData;

		// Built-in Pricing
		if (content.simple) {
			state.pricingSettings.type = PricingSettingsType.SIMPLE;
			state.pricingSettings.simple = content.simple;
		} 
		else{
			return;
		}
		if (content.simple && !!content.simple.currency
      && content.simple.currency !== authContext.userInfo.currency) {
			// Ask for confirmation
			props.dialogService?.showConfirm(
				{
					title:`${translate('settings.pricing.pricing_currency_changed_title')}`,
					description:`${translate('settings.pricing.pricing_currency_changed_confirm')}`,
					confirmType: 'YES_NO',
				},(result) => {
					if (result === 'YES') {	
						savePricingSettings();
					}
				}
			);
		}else {
			savePricingSettings();
		}
	};
	const saveCurrencyData = (selectedCurrency:any)=>{		
		setStateData((currentData: any) => { 
			return {
				...currentData,
				currencyData:selectedCurrency,
			};
		});
	};
	const savePricingSettings = () => {
		const settingsToSave = {
			id: state.pricingSettings.id,
			identifier: TenantComponents.PRICING,
			sensitiveData: [],
			content: JSON.parse(JSON.stringify(state.pricingSettings)),
			simple:JSON.parse(JSON.stringify(state.pricingSettings)).simple
		};
		// Delete IDS
		delete settingsToSave.content.id;
		delete settingsToSave.content.identifier;
		delete settingsToSave.content.sensitiveData;
		settingsToSave.content.simple.currency = state.currencyData;
		updatePricingSettings(settingsToSave).then((response: any) => {
			if (response.status === RestResponse.SUCCESS) {
				notify.message = !state.pricingSettings.id ? `${translate('settings.pricing.create_success')}` : `${translate('settings.pricing.update_success')}`;
				notify.type = 'success';
				props.notificationService?.notify(notify);
				fetchPricingSettings();
			} else {
				switch (response.status) {
				case StatusCodes.NOT_FOUND:
					notify.message =`${translate('settings.pricing.setting_do_not_exist')}`;
					notify.type = 'error';
					props.notificationService?.notify(notify);
					break;
				default:{
					const errorNotify = handleHttpError(response , !state.pricingSettings.id ? `${translate('settings.pricing.create_error')}` : `${translate('settings.pricing.update_error')}`);
					props.notificationService?.notify(errorNotify);
				}
				}
			}
		});
	};

	if(!isPricingComponentActive)
		return <div className="information_block">
			<Card icon={<Info />} title={`${translate('settings.information')}`} isTransparent={true}>
				<div className="information">
					<p data-cy="roamingHubject-content1">{`${translate('settings.pricing.full_description')}`}</p>
				</div>
				<div><p></p><p data-cy="roamingHubject-content2">{`${translate('settings.activation_contact_msg')}`}</p></div>
			</Card>	
		</div>;
	return (
		<>
			<Stack direction='row' spacing={10} marginBottom={'25px'}>
				<EvesButton type="button" onClick={()=>save(currency)} disabled={state.isCurrencyCodeReadonly || state.currencyData===''||state.currencyData===undefined} startIcon={<Save/>}
					variant="contained" 
					data-cy="save-btn">{`${translate('general.save')}`}
				</EvesButton>
			</Stack>
			{isPricingComponentActive && state.showSimplePricing && <Simple saveCurrencyData={saveCurrencyData} pricingForm={pricingForm} data-cy="currency" isCurrencyCodeReadonly={state.isCurrencyCodeReadonly} pricingSettings={state.pricingSettings}/>}
			{state.showPricingDefinitions && isPricingComponentActive && <PriceList
				showModal={state.isPriceModalOpen}
				siteId={authContext.userInfo.tenantId}
				siteName={state.siteName}
				onClose={onPriceModalClose}
				dialogService={props.dialogService}
				notificationService={props.notificationService}
				entityType='Tenant'
				isModal={false}
				listHeight={'calc(100vh - 480px)'}
			></PriceList>}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
	withContext(
		withUseFormHook(Pricing, {
			schema: formSchema,
			defaultValues: { ...new SimplePricingModel() },
		})
	)
);