import { EvesButton } from '@/common/components/atoms/button/button';
import { EvesChip } from '@/common/components/atoms/chip/chip';
import { Card } from '@/common/components/molecules/card/card';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { withContext } from '@/common/utils/withContext';
import {
  getBillingAccounts,
  onboardAccount,
} from '@/services/integrationSettings';
import { Add, Business, PersonAdd, Refresh } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import AddConnectedAccount from '../addConnectedAccount';
import { NotifyType } from '@/common/components/molecules/notification/notification';
import { BillingAccountStatus } from '@/common/enums/enums';
import { useTranslation } from 'react-i18next';
import { DefaultPageSize } from '@/common/constants/constants';

const ConnectedAccounts = (props: any) => {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    rows: [],
    totalRowsCount: 0,
    sortDescending: false,
    sortFields: 'companyName',
    page: 0,
    limit: DefaultPageSize,
  } as any);

  const { refetch: fetchBillingAccounts } = useQuery(
    'Billing Accounts',
    () => {
      return getBillingAccounts({
        Limit: state.limit,
        SortFields: state.sortFields,
        Page: state.page + 1,
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if (data) {
          const connectedAccounts: any[] = [];
          data.result.forEach((connectedAccount: any) => {
            connectedAccounts.push({
              ...connectedAccount,
              fullName: connectedAccount.businessOwner
                ? connectedAccount.businessOwner.firstName +
                  ' ' +
                  connectedAccount.businessOwner.name
                : '-',
              email: connectedAccount.businessOwner
                ? connectedAccount.businessOwner.email
                : '-',
            });
          });
          setStateData((currentData: any) => {
            return {
              ...currentData,
              rows: connectedAccounts,
              totalRowsCount: data.count,
            };
          });
        }
      },
    }
  );

  const { mutate: onboardAccountAsync } = useMutation(
    (accountID: any) => onboardAccount(accountID),
    {
      onSuccess: (data: any) => {
        const notify: NotifyType = {
          message: '',
          type: 'success',
        };
        if (data.status === 'Success') {
          notify.message = `${translate('accounts.message.onboard_success')}`;
          notify.type = 'success';
          fetchBillingAccounts();
          props.notificationService?.notify(notify);
          props.onFormClose();
          props.fetchAllConnectedAccounts();
        } else {
          notify.message = `${translate('accounts.message.onboard_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
          props.onFormClose();
          props.fetchAllConnectedAccounts();
        }
      },
    }
  );

  const accountOnboard = (record: any) => {
    onboardAccountAsync(record.id);
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () => translate('accounts.onboarding.onboard_action'),
      icon: () => PersonAdd,
      iconColor: 'primary',
      onClick: accountOnboard,
      visible: (record: any) =>
        record.status == BillingAccountStatus.IDLE ? true : false,
    },
    {
      title: () => '',
      icon: () => '',
      iconColor: '',
      visible: () => true,
      disabled: () => true,
    },
  ];

  const Status = (row: any) => {
    if (row.status === 'pending') {
      return (
        <EvesChip
          label={`${translate('accounts.status.account_pending')}`}
          type={'Pending'}
        />
      );
    } else if (row.status === 'idle') {
      return (
        <EvesChip
          label={`${translate('accounts.status.account_idle')}`}
          type={'Faulted'}
        />
      );
    } else {
      return (
        <EvesChip
          label={`${translate('accounts.status.account_active')}`}
          type={'Available'}
        />
      );
    }
  };

  const columns = [
    { id: 'actions', label: `${translate('general.actions')}`, minWidth: 20 },
    {
      id: 'status',
      label: `${translate('settings.billing.payment_methods_status')}`,
      textAlign: 'center',
      sortKey: 'status',
      minWidth: 70,
      maxWidth: 240,
      allowSort: true,
    },
    {
      id: 'companyName',
      label: `${translate('accounts.list.company_name')}`,
      textAlign: 'center',
      allowSort: true,
      sortKey: 'companyName',
      minWidth: 50,
      maxWidth: 210,
    },
    {
      id: 'fullName',
      label: `${translate('accounts.list.business_owner')}`,
      textAlign: 'center',
      allowSort: true,
      sortKey: 'businessOwner.name',
      minWidth: 100,
      maxWidth: 210,
    },
    {
      id: 'email',
      label: `${translate('users.email')}`,
      allowSort: true,
      sortKey: 'businessOwner.email',
      textAlign: 'center',
      minWidth: 120,
      maxWidth: 210,
    },
    {
      id: 'accountExternalID',
      label: `${translate('accounts.list.account_id')}`,
      allowSort: true,
      sortKey: 'accountExternalID',
      textAlign: 'center',
      minWidth: 100,
      maxWidth: 210,
    },
  ];

  const onFormClose = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showConnectedAccountForm: false,
      };
    });
  };

  const resetConnectedAccountForm = (val: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        connectdAccountId: val,
      };
    });
  };

  const createPaymentMethod = () => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showConnectedAccountForm: true,
      };
    });
  };

  useEffect(() => {
    fetchBillingAccounts();
  }, [state.sortFields, state.limit, state.page]);

  const onDataTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  return (
    <Card
      icon={<Business />}
      title={`${translate('accounts.title')}`}
      isTransparent={true}
    >
      <>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          marginBottom={'10px'}
        >
          <EvesButton
            startIcon={<Add style={{ fontSize: '22.4px' }} />}
            data-cy='connected-accounts-create-btn'
            onClick={createPaymentMethod}
            style={{ padding: '8px 24px' }}
          >
            {`${translate('general.create')}`}
          </EvesButton>
          <EvesButton
            data-cy='payment-refresh-btn'
            onClick={fetchBillingAccounts}
          >
            <Refresh />
          </EvesButton>
        </Stack>
        <DataTable
          className='companies-table'
          data-cy='companies-table'
          rows={state.rows}
          columns={columns}
          actionsMenu={primaryActions}
          totalRowsCount={state.totalRowsCount}
          onChange={onDataTableChange}
          defaultSortField={state.sortFields}
          height={{ height: 'calc(70vh - 328px)' }}
          count={state.totalRowsCount}
          limit={state.limit}
          page={state.page}
          tablePagination={true}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        >
          <Status slot='status' />
        </DataTable>
        {state.showConnectedAccountForm && (
          <AddConnectedAccount
            showConnectedAccountForm={state.showConnectedAccountForm}
            resetConnectedAccountForm={resetConnectedAccountForm}
            onFormClose={onFormClose}
            billingData={props.billingData}
            fetchAllConnectedAccounts={fetchBillingAccounts}
            canCreate={state.canCreate}
          />
        )}
      </>
    </Card>
  );
};

export default withContext(ConnectedAccounts);
