import img from '@/assets/img/theme/no-image.png';
import { EvesButton } from '@/common/components/atoms/button/button';
import { Logo } from '@/common/components/atoms/logo/logo';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { StatusCodes } from '@/common/constants/constants';
import { HTTPError, RestResponse, UserStatus } from '@/common/enums/enums';
import { disableLoaderForOnce } from '@/common/utils/loadingService';
import { blobToBase64String, buildMobileAppDeepLink, getSubdomain, handleError, handleHttpError, isInMobileApp } from '@/common/utils/utils';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { getTenantLogoBySubdomain, resendVerificationEmail as resendVerificationEmailToUser, verifyUserEmail } from '@/services/auth';
import { Email } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import Captcha from '../captcha/captcha';
import styles from './verifyEmail.module.scss';
import Config from '@/common/constants/config';

const VerifyEmail = (props: IContextPropsModel): JSX.Element => {
	const siteKey = Config.user.captchaSiteKey;
	const [state, setStateData] = useState({
		isVisible: false,
		tenantLogo: img,
	});
	const subdomain = getSubdomain();
	const search = useLocation().search;
	const { t: translate } = useTranslation();
	const navigate = useNavigate();
	let capToken:string;
	let mobileAppURL: string;
	let verifyEmailAction: boolean = false;
	
	// Get verificationToken & email
	const verificationToken = new URLSearchParams(search).get('VerificationToken');
	const resetToken = new URLSearchParams(search).get('ResetToken');
	const verificationEmail = new URLSearchParams(search).get('Email');
	
	const onCapResolve = (token: string) => {
		capToken = token;
	};
	// Handle Deep Linking
	if (isInMobileApp(subdomain || '')) {
		// Forward to Mobile App
		// getMobileVendor() is checked for not NULL in isInMobileApp() call.
		// So buildMobileAppDeepLink() returns URL and ( || '' ) added at the end to avoid TS undefined error.
		mobileAppURL= buildMobileAppDeepLink(
			`verifyAccount/${subdomain}/${verificationEmail}/${verificationToken}/${resetToken}`) || '' ;
		window.location.href = mobileAppURL;
	}

	const verifyEmail = (data: any) => {
		verifyUserEmail({ Email: data.email, VerificationToken: data.verificationToken })
			.then((response:any) => {
				if (response.status && response.status === RestResponse.SUCCESS) {
					if (resetToken) {
						// Show message
						props.notificationService?.notify({ 
							message: translate('authentication.verify_email_success_set_password'), 
							type: 'success'
						});
						// Go to reset password
						navigate({ pathname: 'auth/define-password', search: `?hash=${resetToken}`});
					} else {
						if (response?.userStatus === UserStatus.INACTIVE) {
							// Show message for inactive new account by default
							props.notificationService?.notify({ 
								message: translate('authentication.verify_email_success_inactive'), 
								type: 'info'
							});
						} else {
							// Show message for automatic activated account
							props.notificationService?.notify({ 
								message: translate('authentication.verify_email_success'), 
								type: 'success'
							});
						}
						// Go to login
						navigate({ pathname: '/login', search: `?email=${verificationEmail}`});
					}
					// Unexpected Error
				} else {
					// Unexpected error
					handleError(JSON.stringify(response), translate('authentication.verify_email_error') || '');
				}
			})
			.catch((error: any) => {
				// Hide
				disableLoaderForOnce();
				// Check status error code
				switch (error.status) {
				// Account already active
				case HTTPError.USER_ACCOUNT_ALREADY_ACTIVE_ERROR:
					// Report the error
					props.notificationService?.notify({ 
						message: translate('authentication.verify_email_already_active'), 
						type: 'info'
					});
					break;
					// VerificationToken no longer valid
				case HTTPError.INVALID_TOKEN_ERROR:
					// Report the error
					props.notificationService?.notify({ 
						message: translate('authentication.verify_email_token_not_valid'), 
						type: 'error'
					});
					break;
					// Email does not exist
				case StatusCodes.NOT_FOUND:
					// Report the error
					props.notificationService?.notify({ 
						message: translate('authentication.verify_email_email_not_valid'), 
						type: 'error'
					});
					break;
				default:
					// Unexpected Error
					handleHttpError(error, translate('authentication.verify_email_error'));
				}
				// Go to login
				navigate({ pathname: '/login', search: `?email=${verificationEmail}`});
			});
	};

	
	const resendVerificationEmail = (data: any) => {		
		if (capToken) {
			data['captcha'] = capToken;
		} 
		else {
			props.notificationService?.notify({ 
				message: translate('authentication.invalid_captcha_token'), 
				type: 'error'
			});
			return;
		}
		// Resend
		resendVerificationEmailToUser(data)
			.then( (response: any) => {
				disableLoaderForOnce();
				if (response.status && response.status === RestResponse.SUCCESS) {
					props.notificationService?.notify({ 
						message: translate('authentication.verify_email_resend_success'), 
						type: 'success'
					});
					// Go back to login
					navigate({ pathname: '/login', search: `?email=${verificationEmail}`});
				} else {
					handleError(JSON.stringify(response), translate('authentication.verify_email_resend_error') || '');
				}
			})
			.catch((error: any) => {
				disableLoaderForOnce();
				switch (error.status) {
				case HTTPError.USER_ACCOUNT_ALREADY_ACTIVE_ERROR:
					props.notificationService?.notify({ 
						message: translate('authentication.verify_email_already_active'), 
						type: 'info'
					});
					navigate({ pathname: '/login', search: `?email=${verificationEmail}`});
					break;
				case StatusCodes.NOT_FOUND:
					props.notificationService?.notify({ 
						message: translate('authentication.verify_email_email_not_valid'), 
						type: 'error'
					});
					break;
				default:
					handleHttpError(error, translate('authentication.verify_email_resend_error'));
					break;
				}
			});
	};
	
	const { refetch: fetchTenantLogo } = useQuery('tenantLogo',
		() => {
			if (subdomain) {
				return getTenantLogoBySubdomain(subdomain);
			}
		}, {
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: false,
			onSuccess: async (data: any) => {
				if (data) {
					const base64String = await blobToBase64String(data);
					setStateData((currentData) => {
						return {
							...currentData,
							tenantLogo: base64String
						};
					});
				}
			}
		});
	
	const  onInit = ()=> {
		// Check email
		if (verificationEmail && !isInMobileApp(subdomain || '')) {
			// Check if verificationToken
			if (verificationToken) {
				// Disable resend verification email
				verifyEmailAction = true;
				// Verify Email
				verifyEmail({
					email:verificationEmail,
					verificationToken: verificationToken,
				});
			} else {
				// Enable resend verification email
				verifyEmailAction = false;
			}
		}
	};

	useEffect(() => {
		fetchTenantLogo();
		onInit();
	}, []);
	
	return (<>
		<GoogleReCaptchaProvider reCaptchaKey={`${siteKey}`}>
			<div className={styles.register}>
				<div className={styles.registerCard}>
					<div className={styles.registerCardHeader}>
						<Logo src={state.tenantLogo} alt='tenant-logo' />
					</div>
					<form id='reset-password' data-cy='reset-password-main'>
						<div className={styles.registerFormRow}>
							<div className={styles.registerFormControl}>
								<Email sx={{fontSize: '20px', marginTop: '18px'}}/>
								<EvesTextbox
									id='email'
									label='Email'
									type='text'
									fullWidth
									variant='standard'
									value={verificationEmail}
									readonly
								/>
							</div>
						</div>
						<div className={styles.recaptcha_text} data-cy="recap-text">
							{translate('general.captcha_text_1')}{' '}
							<a href='https://policies.google.com/privacy'>{translate('general.captcha_text_2')}</a>{' '}
							{translate('general.captcha_text_3')}{' '}
							<a href='https://policies.google.com/terms'>{translate('general.captcha_text_4')}</a>{' '}
							{translate('general.captcha_text_5')}
						</div>
						<div className='hide-cap'>
							<Captcha onCapResolve={onCapResolve} />
						</div>
						<div className={styles.registerCenterRegBtn}>
							<EvesButton
								data-cy='sign-in'
								size='medium'
								onClick={()=> resendVerificationEmail({email: verificationEmail})}
								id='btnLogin'
								variant='primary'
								hidden={verifyEmailAction}
							>
								Send
							</EvesButton>
						</div>
					</form>
				</div>
			</div>
		</GoogleReCaptchaProvider>
	</>);
};

export default withContext(VerifyEmail);