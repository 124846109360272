import { httpClient } from './httpClient';

export const getRefundSettings = (params: any) => {
	return httpClient.get('/api/settings', params);
};

export const updateRefundingContent = (refundingData: any) => {
	return httpClient.put(`/api/settings/${refundingData.id}`, refundingData);
};

export const getPricingSettings = (params: any) => {
	return httpClient.get('/api/settings', params);
};

export const updatePricingSettings = (settings: any) => {
	return httpClient.put(`/api/settings/${settings.id}`, settings);
};

export const createBillingAccount = (billingAccount: any) => {
	return httpClient.post('/api/billing/accounts', billingAccount);
};

export const refreshBillingAccount = (accountID: any,payload:any) => {
	return httpClient.patch(`/util/billing/accounts/${accountID}/refresh`, payload);
};

export const getBillingSettings = () => {
	return httpClient.get('/api/billing-setting');
};

export const getBillingTaxes = () => {
	return httpClient.get('/api/billing/taxes');
};

export const getBillingAccounts = (params: any) => {
	return httpClient.get('/api/billing/accounts', params);
};

export const saveBillingSettings = (billingSettings: any) => {
	return httpClient.put('/api/billing-setting', billingSettings);
};

export const onboardAccount  = (accountID: any) => {
	return httpClient.patch(`/api/billing/accounts/${accountID}/onboard`, {});
};

export const checkBillingConnection = (id:any) => {
	return httpClient.post('/api/billing/check', {id});
};

export const clearBillingTestData = (id:any) => {
	return httpClient.post('/api/billing/clearTestData', {id});
};

export const getCarConnectorSettings = (params: any) => {
	return httpClient.get('/api/settings', params);
};

export const updateSetting = (id: string, params: any) => {
	return httpClient.put(`/api/settings/${id}`, params);
};

export const updateCarConnectorSetting = (setting: any) => {
	return httpClient.put(`/api/settings/${setting.id}`, setting);
};

export const checkSmartChargingConnection = () => {
	return httpClient.get('/api/charging-stations/smartcharging/connection/check');
};

export const getSettingsList = (params: any) => {
	return httpClient.get('/api/settings', params);
};

export const saveAssetList = (id:any,params: any) => {
	return httpClient.put(`/api/settings/${id}`, params);
};

export const checkAssetConnection = (id:any,params:any) => {
	return httpClient.get(`/api/assets/connectors/${id}/connection/check`, params);
};

export const createIOPAccount = (IOPAccount: any) => {
	return httpClient.post('/api/ocpi/endpoints', IOPAccount);
};

export const updateIOP = (id:any,params: any) => {
	return httpClient.put(`/api/ocpi/endpoints/${id}`,params);
};

export const saveAnalyticsList = (id: any, params: any) => {
	return httpClient.put(`/api/settings/${id}`, params);
};

export const createOicpEndpoint = (params: any) => {
	return httpClient.post('/api/oicp/endpoints', params);
};

export const updateOicpEndpoint = (params: any) => {
	return httpClient.put(`/api/oicp/endpoints/${params.id}`, params);
};

export const registerOicpEndpoint = (id: any) => {
	return httpClient.put(`/api/oicp/endpoints/${id}/register`,{});
};

export const unregisterOicpEndpoint = (id: any) => {
	return httpClient.put(`/api/oicp/endpoints/${id}/unregister`,{});
};

export const deleteOicpEndpoint = (id: any) => {
	return httpClient.delete(`/api/oicp/endpoints/${id}`);
};

export const pushOicpData = (id: any) => {
	return httpClient.put(`/api/oicp/endpoints/${id}/evses/send`,{});
};

export const pushOicpStatusData = (id: any) => {
	return httpClient.put(`/api/oicp/endpoints/${id}/evses/statuses/send`,{});
};

export const updateIOPAccount = (params: any) => {
	return httpClient.post('/api/oicp/endpoints/', params);
};

export const getOicpEndpoints = (oicpParams: any) => {
	return httpClient.get('/api/oicp/endpoints', oicpParams);
};