import { getTenantLogoBySubdomain, loginUser } from '@/services/auth';
import { setUserContext } from '@/store/auth/reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import store from '@/store';
import img from '@/assets/img/theme/no-image.png';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from '../../shared/schemas/loginFormSchema';
import { LoginFormModel } from '../../shared/models/models';
import { showNotification } from '@/store/app/reducer';
import {
  blobToBase64String,
  getSubdomain,
  handleHttpError,
  parseAccessToken,
} from '@/common/utils/utils';
import { useQuery } from 'react-query';
import { StatusCodes } from '@/common/constants/constants';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { useTranslation } from 'react-i18next';
import { HTTPError } from '@/common/enums/enums';
import CommonLogin from '../CommonLogin';
const SignIn = (props: IContextPropsModel) => {
  const [state, setStateData] = useState({
    isVisible: false,
    tenantLogo: img,
  });
  const { t: translate } = useTranslation();
  const { control, formState, watch, getValues } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: new LoginFormModel(),
    mode: 'onTouched',
  });
  const { errors, isValid } = formState;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subdomain = getSubdomain();

  const { refetch: fetchTenantLogo } = useQuery(
    'tenantLogo',
    () => {
      if (subdomain) {
        return getTenantLogoBySubdomain(subdomain);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: any) => {
        if (data?.status >= 400 && data?.status < 500) {
          setStateData((currentData: any) => {
            return {
              ...currentData,
              tenantLogo: img,
            };
          });
        } else if (data) {
          const base64String = await blobToBase64String(data);
          setStateData((currentData) => {
            return {
              ...currentData,
              tenantLogo: base64String,
            };
          });
        }
      },
    }
  );

  useEffect(() => {
    fetchTenantLogo();
  }, []);

  const handleClickShowPassword = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isVisible: !currentData.isVisible,
      };
    });
  };

  const onLogin = () => {
    const loginForm = getValues();
    if(isValid){
      loginUser(loginForm.userName, loginForm.password).then((res: any) => {
        if (res.status === 404) {
          store.dispatch(
            showNotification({
              showNotification: true,
              message: `${translate('authentication.wrong_email_or_password')}`,
              type: 'error',
            })
          );
          return;
        }
  
        switch (res.status) {
          // Wrong email or password
          case StatusCodes.NOT_FOUND:
            props.notificationService?.notify({
              message: translate('authentication.wrong_email_or_password'),
              type: 'error',
            });
            break;
          // Account is locked
          case HTTPError.USER_ACCOUNT_LOCKED_ERROR:
            props.notificationService?.notify({
              message: translate('authentication.account_locked'),
              type: 'error',
            });
            break;
          // Account is inactive
          case HTTPError.USER_ACCOUNT_INACTIVE_ERROR:
            props.notificationService?.notify({
              message: translate('authentication.account_inactive'),
              type: 'error',
            });
            break;
          // Account Suspended
          case HTTPError.USER_ACCOUNT_BLOCKED_ERROR:
            props.notificationService?.notify({
              message: translate('authentication.account_suspended'),
              type: 'error',
            });
            break;
          // API User
          case HTTPError.TECHNICAL_USER_CANNOT_LOG_TO_UI_ERROR:
            props.notificationService?.notify({
              message: translate(
                'authentication.technical_user_cannot_login_to_ui'
              ),
              type: 'error',
            });
            break;
          // Account Pending
          case HTTPError.USER_ACCOUNT_PENDING_ERROR:
            // Pending Users from the Super Tenant should not be able to request an activation email
            if (subdomain?.length) {
              // Usual Users
              props.notificationService?.notify({
                message: translate('authentication.account_pending'),
                type: 'warning',
              });
              // No Create and show dialog data
              props.dialogService?.showConfirm(
                {
                  confirmType: 'YES_NO',
                  title: `${translate('authentication.verify_email_title')}`,
                  description: translate(
                    'authentication.verify_email_resend_confirm'
                  ),
                },
                (result) => {
                  if (result === 'YES') {
                    navigate({
                      pathname: '/verify-email',
                      search: `?Email=${loginForm.userName}`,
                    });
                  }
                }
              );
            } else {
              // Super Admin Users
              props.notificationService?.notify({
                message: translate('authentication.super_user_account_pending'),
                type: 'warning',
              });
            }
            break;
          default:
            handleHttpError(res, translate('general.unexpected_error_backend'));
        }
  
        const userInfo = parseAccessToken(res.token);
  
        // set to store
        dispatch(
          setUserContext({
            isAuthenticated: true,
            userInfo,
          })
        );
  
        // set to local TODO: move keys to constants
        localStorage.setItem('access_token', res.token);
        localStorage.setItem('user_info', JSON.stringify(userInfo));
        const access = userInfo?.scopes?.includes(`${'Dashboard'}:${'List'}`);
        const charginStationAccess = userInfo?.scopes?.includes(
          `${'ChargingStation'}:${'List'}`
        );
        if (access) {
          navigate('/dashboard');
        } else if (charginStationAccess) {
          navigate('/charging-stations');
        } else {
          navigate('/tenants');
        }
      });
    }
   
  };

  const isAgreementAccepted = watch('acceptAgreement');

  const canSubmitForm = isValid;
  return (
    <CommonLogin
      {...{
        state,
        control,
        errors,
        handleClickShowPassword,
        onLogin,
        canSubmitForm,
        isForgot: false,
        emailName: 'userName',
        headLabel: 'Welcome to EVES!',
      }}
    />
  );
};

export default withContext(SignIn);
