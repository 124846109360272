import DataTable from '@/common/components/organisms/table/dataTable';
import { DefaultPageSize, StatusCodes } from '@/common/constants/constants';
import {
  AssetConnectionType,
  RestResponse,
  TenantComponents,
} from '@/common/enums/enums';
import { columnsType } from '@/common/models/model';
import {
  checkAssetConnection,
  getSettingsList,
  saveAssetList,
} from '@/services/integrationSettings';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Card } from '@/common/components/molecules/card/card';
import {
  AccountBalanceRounded,
  Add,
  Delete,
  Edit,
  Info,
  InsertLink,
  Refresh,
  Save,
} from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { EvesButton } from '@/common/components/atoms/button/button';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import { AssetModel } from '@/services/models/integrationSetting';
import AssetFrom from './components/assetForm';
import { NotifyType } from '@/common/utils/notificationService';
import { withContext } from '@/common/utils/withContext';
import { handleHttpError, isActive } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';

const Asset = (props: any) => {
  const { t: translate } = useTranslation();
  const dataTableRef = useRef<any>();
  const [state, setStateData] = useState({
    limit: DefaultPageSize,
    page: 0,
    sortDescending: false,
    sortFields: '',
    rows: [],
    totalRowsCount: 0,
    selectedPriceId: '',
    canCreate: false,
    projectFields: [],
    showAssetForm: false,
    saveDisabled: true,
    assetId: '',
    assetRecordId: '',
  } as any);
  const notify: NotifyType = {
    message: '',
    type: 'success',
  };
  let resultData: any = [];
  const columns: columnsType[] = [
    {
      id: 'actions',
      label: `${translate('general.actions')}`,
      minWidth: 90,
      maxWidth: 150,
      textAlign: 'center',
      projectField: 'action',
    },
    {
      id: 'name',
      label: `${translate('settings.asset.connection.name')}`,
      minWidth: 220,
      textAlign: 'left',
      maxWidth: 240,
      projectField: 'name',
    },
    {
      id: 'description',
      label: `${translate('settings.asset.connection.description')}`,
      minWidth: 320,
      textAlign: 'left',
      maxWidth: 350,
    },
    {
      id: 'type',
      label: `${translate('settings.asset.connection.type')}`,
      minWidth: 220,
      textAlign: 'left',
      maxWidth: 250,
    },
  ];

  const isAssetComponentActive = isActive(TenantComponents.ASSET);

  const onEditAssetForm = (record: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showAssetForm: true,
        assetRecordId: record.id,
      };
    });
  };

  const onDeleteItem = (record: any) => {
    props.dialogService?.showConfirm(
      {
        title: translate('settings.asset.connection.delete_title'),
        description: `${translate(
          'settings.asset.connection.delete_confirm'
        ).replace(/{{assetConnectionName}}/, `'${record.name}'`)},?`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          setStateData((currentData: any) => {
            const updatedItems = state.rows.filter(
              (item) => item.id !== record.id
            );
            return {
              ...currentData,
              rows: updatedItems,
              totalRowsCount: updatedItems.length,
              saveDisabled: false,
            };
          });
        }
      }
    );
  };

  const onTestConnection = (record: any) => {
    if (state.saveDisabled == false) {
      props.dialogService?.showConfirm({
        title: `${translate('settings.settings_not_saved_title')}`,
        description: `${translate('settings.settings_not_saved')}`,
        confirmType: 'OK',
      });
    } else {
      const params = { ID: record.id };
      checkAssetConnection(record.id, params).then(
        (response: any) => {
          if (response.status && response.status === RestResponse.SUCCESS) {
            if (response.connectionIsValid) {
              notify.message = `${translate(
                'settings.asset.connection_success'
              )}`;
              notify.type = 'success';
            } else {
              notify.message = `${translate(
                'settings.asset.connection_failed'
              )}`;
              notify.type = 'error';
            }
          } else {
            notify.message = `${translate(
              'settings.asset.unknown_connection_error'
            )}`;
            notify.type = 'error';
          }
          props.notificationService?.notify(notify);
        },
        (error) => {
          handleHttpError(
            error,
            `${translate('settings.asset.unknown_connection_error')}`
          );
        }
      );
    }
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('general.edit')}`,
      icon: () => Edit,
      iconColor: 'primary',
      onClick: (record: any) => onEditAssetForm(record),
      'data-cy': 'edit-btn',
    },
    {
      title: () => `${translate('general.test_connection')}`,
      icon: () => InsertLink,
      iconColor: 'primary',
      onClick: (record: any) => onTestConnection(record),
      'data-cy': 'test-connection',
    },
    {
      title: () => `${translate('general.delete')}`,
      icon: () => Delete,
      iconColor: 'error',
      onClick: (record: any) => onDeleteItem(record),
      'data-cy': 'delete-btn',
    },
  ];

  const loadAssetList = () => {
    const params: any = {
      Identifier: TenantComponents.ASSET,
      Limit: state.limit,
      SortFields: state.sortFields,
      Page: state.page + 1,
    };
    return getSettingsList(params);
  };

  const resetAssetForm = (val: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        assetRecordId: val,
      };
    });
  };

  const { refetch: fetchAssetList } = useQuery(
    '',
    () => {
      return loadAssetList();
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        resultData = data?.setting?.content.asset.connections.map(
          (res: any) => new AssetModel(res)
        );
        resultData?.forEach((element) => {
          if (element.type !== AssetConnectionType.GREENCOM) {
            delete element.greencomConnection;
          }
          if (element.type !== AssetConnectionType.SCHNEIDER) {
            delete element.schneiderConnection;
          }
          if (element.type !== AssetConnectionType.IOTHINK) {
            delete element.iothinkConnection;
          }
          if (element.type !== AssetConnectionType.WIT) {
            delete element.witConnection;
          }
          if (element.type !== AssetConnectionType.LACROIX) {
            delete element.lacroixConnection;
          }
        });
        setStateData((currentData) => {
          return {
            ...currentData,
            rows: resultData,
            totalRowsCount: data.count,
            assetId: data?.setting?.id,
          };
        });
      },
    }
  );

  useEffect(() => {
    fetchAssetList();
  }, [state.sortFields, state.page, state.limit]);

  const handleRowData = (data: any) => {
    const index = state.rows.findIndex((item) => item.id === data.id);
    if (index === -1) {
      state.rows.push(data);
    } else {
      state.rows[index] = data;
    }
    setStateData((currentData) => {
      return {
        ...currentData,
        totalRowsCount: state.rows?.length,
        saveDisabled: false,
      };
    });
  };

  const onAssetCreate = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showAssetForm: true,
      };
    });
  };

  const TypeCell = (row: any) => {
    const assetType: string = row.type;
    let assetTypeMessage: string;
    switch (assetType) {
      case AssetConnectionType.SCHNEIDER:
        assetTypeMessage = `${translate('settings.asset.types.schneider')}`;
        break;
      case AssetConnectionType.GREENCOM:
        assetTypeMessage = `${translate('settings.asset.types.greencom')}`;
        break;
      case AssetConnectionType.IOTHINK:
        assetTypeMessage = `${translate('settings.asset.types.iothink')}`;
        break;
      case AssetConnectionType.WIT:
        assetTypeMessage = `${translate('settings.asset.types.wit')}`;
        break;
      case AssetConnectionType.LACROIX:
        assetTypeMessage = `${translate('settings.asset.types.lacroix')}`;
        break;
      default:
        assetTypeMessage = '';
    }
    return <p>{assetTypeMessage}</p>;
  };

  const onFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showAssetForm: false,
      };
    });
  };

  const onSaveClick = () => {
    saveAssetConnectionSettings(state.rows);
  };

  const saveAssetConnectionSettings = (settings: any) => {
    const settingsToSave: any = {
      id: state.assetId,
      identifier: TenantComponents.ASSET,
      sensitiveData: [],
      content: {
        type: TenantComponents.ASSET,
        asset: {
          connections: settings,
        },
      },
    };
    settingsToSave.content?.asset?.connections?.forEach(
      (settingConnection: any, index: any) => {
        switch (settingConnection.type) {
          case AssetConnectionType.SCHNEIDER:
            settingsToSave.sensitiveData.push(
              `content.asset.connections[${index}].schneiderConnection.password`
            );
            break;
          case AssetConnectionType.GREENCOM:
            settingsToSave.sensitiveData.push(
              `content.asset.connections[${index}].greencomConnection.clientSecret`
            );
            break;
          case AssetConnectionType.IOTHINK:
            settingsToSave.sensitiveData.push(
              `content.asset.connections[${index}].iothinkConnection.password`
            );
            break;
          case AssetConnectionType.WIT:
            settingsToSave.sensitiveData.push(
              `content.asset.connections[${index}].witConnection.password`
            );
            settingsToSave.sensitiveData.push(
              `content.asset.connections[${index}].witConnection.clientSecret`
            );
            break;
          case AssetConnectionType.LACROIX:
            settingsToSave.sensitiveData.push(
              `content.asset.connections[${index}].lacroixConnection.password`
            );
            break;
        }
      }
    );

    saveAssetList(state.assetId, settingsToSave).then(
      (res: any) => {
        if (res.status === 'Success') {
          notify.message = `${translate('settings.asset.update_success')}`;
          notify.type = 'success';
          fetchAssetList();
        } else {
          notify.message = `${translate('settings.asset.update_error')}`;
          notify.type = 'error';
        }
        props.notificationService?.notify(notify);
      },
      (error) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            notify.message = `${translate(
              'settings.asset.setting_do_not_exist'
            )}`;
            notify.type = 'error';
            break;
          default:
            handleHttpError(
              error,
              `${translate('general.unexpected_error_backend')}`
            );
        }
      }
    );
    setStateData((currentData) => {
      return {
        ...currentData,
        saveDisabled: true,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  if (!isAssetComponentActive)
    return (
      <div className='information_block'>
        <Card icon={<Info />} title='Information' isTransparent={true}>
          <div className='information'>
            <p data-cy='roamingHubject-content1'>{`${translate(
              'settings.asset.description'
            )}`}</p>
          </div>
          <div>
            <p></p>
            <p data-cy='roamingHubject-content2'>{`${translate(
              'settings.activation_contact_msg'
            )}`}</p>
          </div>
        </Card>
      </div>
    );

  return (
    <>
      <Stack direction='row' marginBottom={'40px'}>
        <EvesButton
          startIcon={<Save style={{ fontSize: '22.4px' }} />}
          data-cy='save-btn'
          color='error'
          onClick={onSaveClick}
          style={{ padding: '8px 24px' }}
          disabled={state.saveDisabled}
        >{`${translate('general.save')}`}</EvesButton>
      </Stack>
      <Card
        icon={<AccountBalanceRounded />}
        title={`${translate('settings.asset.connection.title')}`}
        isTransparent={true}
        sx={{ height: 'fit-content' }}
      >
        <>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            marginBottom={'10px'}
            sx={{
              '@media (max-width: 1366px)': {
                marginTop: '35px',
              },
            }}
          >
            <EvesButton
              variant='contained'
              startIcon={<Add />}
              data-cy='add-btn'
              onClick={onAssetCreate}
            >{`${translate('general.add')}`}</EvesButton>
            <Tooltip
              title={`${translate('Refresh')}`}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'common.white',
                    color: 'black',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                  },
                },
              }}
              followCursor={false}
              placement={'bottom-start'}
              enterNextDelay={500}
            >
              <EvesButton
                onClick={fetchAssetList}
                sx={{
                  backgroundColor: '#3D3AD3',
                  ':hover': { backgroundColor: '#3D3AD3' },
                }}
              >
                <Refresh />
              </EvesButton>
            </Tooltip>
          </Stack>
          <DataTable
            className='asset-table'
            ref={dataTableRef}
            rows={state.rows}
            height={{ height: 'calc(100vh - 350px)' }}
            columns={columns}
            totalRowsCount={state.totalRowsCount}
            actionsMenu={primaryActions}
            defaultSortField={state.sortFields}
            data-cy='asset-table'
            count={state.totalRowsCount}
            limit={state.limit}
            page={state.page}
            tablePagination={true}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          >
            <TypeCell slot='type' />
          </DataTable>
          {state.showAssetForm && (
            <AssetFrom
              showAssetForm={state.showAssetForm}
              onFormClose={onFormClose}
              handleRowData={handleRowData}
              state={state}
              resetAssetForm={resetAssetForm}
            />
          )}
        </>
      </Card>
    </>
  );
};

export default withContext(Asset);
