import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { DefaultPageSize } from '@/common/constants/constants';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { Close, Delete, Edit, RemoveRedEye } from '@mui/icons-material';
import { Grid, InputAdornment } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { OWNER_FILTER_ITEMS as Owner } from '@/common/constants/constants';
import LookupComponent from '@/common/components/lookupComponent';
import { LookupEntityType } from '@/common/enums/enums';
import { Content } from '@/common/components/templates/content/content';
import DataTable from '@/common/components/organisms/table/dataTable';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import { getChargingStationById } from '@/services/chargingStations';
import { useQuery } from 'react-query';
import { SearchBox } from '@/common/components/searchBox';
import noImage from '@/assets/img/theme/no-image.png';
import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { columnsType } from '@/common/models/model';
import { useTranslation } from 'react-i18next';
import { deleteElectricVehicle, getElectricVehicles } from '@/services/cars';
import { ElectricVehicleModel } from '@/services/models/cars';
import CreateElectricForm from './components/electricVehicleForm';
import { canUpdateCar } from '@/common/utils/utils';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';

const ElectricVehicles = (props: IContextPropsModel) => {
  const [state, setStateData] = useState({
    defaultPageSize: DefaultPageSize,
    issuer: true,
    withUser: true,
    limit: DefaultPageSize,
    page: 0,
    sortFields: 'id',
    search: '',
    issuerName: '',
    defaultIssuer: true,
    isResetBtnDisabled: true,
    rows: [],
    totalRowsCount: 0,
    projectFields: [],
    canUpdateChargingStation: false,
    showRegistrationElectricVehicleModal: false,
    evMaker: '',
    evMakerId: '',
    userId: '',
    userName: '',
  } as any);

  const searchBoxRef = useRef<any>();
  const UserLookupRef: any = useRef(null);
  const EvMakerRef: any = useRef(null);
  const updateCarAccess = canUpdateCar();
  const { t: translate } = useTranslation();
  const [showfilter, setShowfilter] = useState<boolean>(false);

  const buildTableColumns = (): columnsType[] => {
    const columns: columnsType[] = [
      {
        id: 'actions',
        label: `${translate('general.actions')}`,
        minWidth: 90,
        textAlign: 'center',
        maxWidth: 180,
        projectField: 'action',
      },
      {
        id: 'image',
        label: `${translate('cars.image')}`,
        allowSort: false,
        minWidth: 60,
        sortKey: 'id',
        textAlign: 'start',
        maxWidth: 210,
        projectField: 'carCatalog.image',
      },
    ];

    columns.push(
      {
        id: 'carCatalogId',
        label: `${translate('cars.id')}`,
        minWidth: 120,
        allowSort: true,
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'carCatalog.id',
      },
      {
        id: 'carCatalogVehicleMake',
        label: `${translate('cars.vehicle_make')}`,
        minWidth: 120,
        allowSort: true,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'carCatalog.vehicleMake',
      },
      {
        id: 'carCatalogVehicleModel',
        label: `${translate('cars.vehicle_model')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'carCatalog.vehicleModel',
      },
      {
        id: 'carCatalogVehicleModelVersion',
        label: `${translate('cars.vehicle_model_version')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'carCatalog.vehicleModelVersion',
      },
      {
        id: 'userName',
        label: `${translate('cars.users')}`,
        minWidth: 120,
        allowSort: true,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'user.name',
      },
      {
        id: 'licensePlate',
        label: `${translate('cars.license_plate')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'licensePlate',
      },
      {
        id: 'vin',
        label: `${translate('cars.vin')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'vin',
      },
      {
        id: 'defaultEV',
        label: `${translate('cars.defaultEV')}`,
        minWidth: 120,
        allowSort: true,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'default',
      },
      {
        id: 'acConverters',
        label: `${translate('cars.charge_standard_tables')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'converter',
      },
      {
        id: 'dcPower',
        label: `${translate('cars.fast_charge_power_max')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'carCatalog.fastChargePowerMax',
      },
      {
        id: 'typeCustom',
        label: `${translate('cars.type')}`,
        minWidth: 120,
        allowSort: false,
        sortKey: 'connectors',
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'type',
      },
      {
        id: 'createdOnDateFormated',
        label: `${translate('cars.createdOn')}`,
        minWidth: 45,
        textAlign: 'center',
        allowSort: true,
        sortKey: 'createdOn',
        projectField: 'createdOn',
      },
      {
        id: 'createdByFormated',
        label: `${translate('cars.createdBy')}`,
        minWidth: 45,
        textAlign: 'center',
        allowSort: true,
        sortKey: 'createdBy',
        projectField: 'createdOn',
      },
      {
        id: 'lastChangedOnDateFormated',
        label: `${translate('cars.lastChangedOn')}`,
        minWidth: 45,
        textAlign: 'center',
        allowSort: true,
        sortKey: 'lastChangedOn',
        projectField: 'lastChangedOn',
      },
      {
        id: 'changedByFormated',
        label: `${translate('cars.lastChangedBy')}`,
        minWidth: 45,
        textAlign: 'center',
        allowSort: true,
        sortKey: 'lastChangedBy',
        projectField: 'lastChangedBy',
      }
    );
    return columns;
  };

  const onDeleteElectricVehicleClick = (e: any) => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('cars.delete_title')}`,
        description: `${translate('cars.delete_confirm').replace(
          /{{carName}}/g,
          `${e.carCatalog.vehicleMake} ${e.carCatalog.vehicleModel} ${e.carCatalog.vehicleModelVersion}`
        )}`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          deleteElectricVehicle(e.id).then((res: any) => {
            if (res.status === 'Success') {
              fetchAllElectricVehicles();
              props.notificationService?.notify({
                message: `${translate('cars.delete_success').replace(
                  /{{carName}}/g,
                  `${e.carCatalog.vehicleMake} ${e.carCatalog.vehicleModel} ${e.carCatalog.vehicleModelVersion}`
                )}`,
                type: 'success',
              });
            } else {
              props.notificationService?.notify({
                message: `${translate('cars.delete_error')}`,
                type: 'error',
              });
            }
          });
        }
      }
    );
  };

  const editChargingStation = (record: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRegistrationElectricVehicleModal: true,
        electricVehicleId: record.id,
      };
    });
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () =>
        updateCarAccess
          ? `${translate('general.tooltips.edit')}`
          : `${translate('general.tooltips.view')}`,
      icon: () => (updateCarAccess ? Edit : RemoveRedEye),
      iconColor: 'primary',
      onClick: editChargingStation,
      visible: () => updateCarAccess,
    },
  ];

  const secondaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('general.delete')}`,
      icon: () => Delete,
      iconColor: 'error',
      onClick: onDeleteElectricVehicleClick,
      visible: (record) => record?.canDelete,
      'data-cy': 'charging-station-delete',
    },
  ];

  const { refetch: fetchAllElectricVehicles } = useQuery(
    'ElectricVehicles',
    () => {
      return getElectricVehicles(getServiceParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        if (data) {
          const canCreate = data.canCreate;
          const canExport = data.canExport;
          const canListCompanies = data.canListCompanies;
          const canListSiteAreas = data.canListSiteAreas;
          const canListSites = data.canListSites;
          const canListUsers = data.canListUsers;
          const companiesData = data.result.map(
            (d) => new ElectricVehicleModel(d)
          );
          setStateData((currentData) => {
            return {
              ...currentData,
              rows: companiesData,
              projectFields: data.projectFields && [...data.projectFields],
              canCreate: canCreate,
              canExport,
              canListCompanies,
              canListSiteAreas,
              canListSites,
              canListUsers,
              canUnlockConnector: data?.canUnlockConnector || false,
            };
          });
        }
      },
    }
  );

  const { refetch: fetchAllElectricVehiclesCount } = useQuery(
    'ElectricVehiclesCount',
    () => {
      const electricVehiclesParam = getServiceParams();
      electricVehiclesParam.OnlyRecordCount = true;
      return getElectricVehicles(electricVehiclesParam);
    },
    {
      onSuccess: (data: any) => {
        if (data) {
          setStateData((currentData) => {
            return {
              ...currentData,
              totalRowsCount: data.count,
            };
          });
        }
      },
    }
  );

  useEffect(() => {
    fetchAllElectricVehicles();
    fetchAllElectricVehiclesCount();
  }, [
    state.page,
    state.sortFields,
    state.search,
    state.issuerName,
    state.isResetBtnDisabled,
    state.userId,
    state.evMakerId,
  ]);

  const getServiceParams = (): any => {
    const serviceGetParams: any = {
      Limit: state.limit,
      WithUser: state.withUser,
      Page: state.page + 1,
    };
    if (state.search !== '') {
      serviceGetParams['Search'] = state.search;
    }
    if (state.evMakerId !== '') {
      serviceGetParams['CarMaker'] = state.evMakerId;
    }
    if (state.userId !== '') {
      serviceGetParams['UserID'] = state.userId;
    }
    return serviceGetParams;
  };

  const ImageCell = (row: any) => {
    const imgScr = row?.carCatalog?.image ? row?.carCatalog?.image : noImage;
    return (
      <img
        width={55}
        height={40}
        alt='car'
        crossOrigin='anonymous'
        src={imgScr}
        style={{ marginRight: '15px' }}
        className='responsiveImage'
      />
    );
  };

  const DefaultEV = (row: any) => {
    return <div>{row.default ? 'Yes' : 'No'}</div>;
  };

  const onClearSelectedUser = (event: any) => {
    event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        userID: '',
        userName: '',
        isResetBtnDisabled: !state.search && !state.userId && !state.evMakerId,
      };
    });
  };

  const setUserFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, showUserLookUpModal: true };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return { ...currentData, showUserLookUpModal: false };
    });
  };

  const onEvMakersFilterModalClose = () => {
    setStateData((currentData) => {
      return { ...currentData, showEvMakerLookUpModal: false };
    });
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userId: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onEVMakerSelected = (evMaker: any) => {
    const evMakerName =
      evMaker?.length > 1
        ? `${evMaker[0].carMaker} (+${evMaker?.length - 1})`
        : evMaker?.length && `${evMaker[0].carMaker}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        evMaker: evMakerName,
        evMakerId: evMaker.map((evMaker: any) => evMaker.carMaker).join('|'),
        showEvMakerLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedEvMakers = (event: any) => {
    event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        evMaker: '',
        evMakerId: '',
        isResetBtnDisabled:
          !currentData.userId && !currentData.evMakerId && !currentData.search,
      };
    });
  };

  const setEvMakerFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, showEvMakerLookUpModal: true };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === '' &&
          currentData.userId === '' &&
          currentData.evMakerId === '',
      };
    });
  };

  const resetFilters = () => {
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: '',
        issuerName: Owner.CurrentOrganization,
        defaultIssuer: true,
        isResetBtnDisabled: true,
        evMaker: '',
        evMakerId: '',
        userId: '',
        userName: '',
      };
    });
  };

  const { refetch: getChargingStation } = useQuery(
    ['data', state.chargingStationId],
    () => {
      const chargingStationParams = {
        WithSite: true,
        WithSiteArea: true,
      };
      return getChargingStationById(
        state.chargingStationId,
        chargingStationParams
      );
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (state.chargingStationId) getChargingStation();
  }, [state.chargingStationId]);

  const onChargingStationTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const onClickAddElectricVehicle = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRegistrationElectricVehicleModal: true,
      };
    });
  };

  const onCloseSiteAreaForm = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRegistrationElectricVehicleModal: false,
      };
    });
  };

  const resetElectricForm = (val: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        electricVehicleId: val,
      };
    });
  };

  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };

  return (
    <>
      <Content
        contentTop={
          <Grid
            container
            item
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item xs={10} lg={8} sm={6} md={8} xl={9}>
              {showfilter && (
                <Grid container item spacing={2} rowSpacing={1} sx={alignStyle}>
                  <Grid
                    data-cy='owner-dropdown-filter'
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={6}
                    xl={6}
                  >
                    <EvesTextbox
                      value={state.userName}
                      id='User-standard-search'
                      label={`${translate('logs.users')}`}
                      data-cy='User-filter'
                      type='text'
                      variant='standard'
                      onClick={setUserFilter}
                      onChange={onFilterApplied}
                      isFilter={true}
                      ref={UserLookupRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              fontSize={15}
                              onClick={onClearSelectedUser}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    data-cy='owner-dropdown-filter'
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={6}
                    xl={6}
                  >
                    <EvesTextbox
                      value={state.evMaker}
                      id='User-standard-search'
                      label={`${translate('cars.ev_makers')}`}
                      data-cy='User-filter'
                      type='text'
                      variant='standard'
                      onClick={setEvMakerFilter}
                      onChange={onFilterApplied}
                      isFilter={true}
                      ref={EvMakerRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              fontSize={15}
                              onClick={onClearSelectedEvMakers}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid
                    data-cy='search-field'
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={12}
                    xl={12}
                  >
                    <SearchBox
                      ref={searchBoxRef}
                      onChange={onFilterApplied}
                      onRemoveClick={fetchAllElectricVehicles}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item gap={'10px'} mt={1} xs={2} sm={6} md={4} lg={4} xl={3}>
              {/* <Grid item display={'flex'} justifyContent={'end'} gap={'10px'}>
                <Tooltip
                  title={`${translate(!showfilter ?'Filter':'Close')}`}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'common.white',
                        color: 'black',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                      },
                    },
                  }}
                  followCursor={false}
                  placement={'bottom-start'}
                  enterNextDelay={500}
                >
                  <Grid item>
                              {!showfilter ? (
                                
                                 <EvesButton
                                 
                                    onClick={() => {
                                       setShowfilter(!showfilter);
                                    }}
                                    data-cy='show-filter'
                                 >
                                    <img src={sessionfilter} />
                                 </EvesButton>
                                 
                              ) : (
                                 <EvesButton
                                    sx={{                                       
                                       border: '1px solid #3D3AD3 !important',
                                       background:
                                          'rgba(61, 58, 211, 0.10) !important',
                                       '&:hover': {
                                          background:
                                             'rgba(61, 58, 211, 0.10) !important',
                                       },
                                    }}
                                    onClick={() => {
                                       setShowfilter(!showfilter);
                                       resetFilters();
                                    }}
                                    data-cy='close-filter'
                                 >
                                    <CloseIcon style={{ color: '3D3AD3' }} />
                                 </EvesButton>
                                
                              ) }</Grid>
                </Tooltip>
                {state.canCreate && (
                  <Grid item>
                    <Tooltip
                      title={`${translate('Create')}`}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'common.white',
                            color: 'black',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                          },
                        },
                      }}
                      followCursor={false}
                      placement={'bottom-start'}
                      enterNextDelay={500}
                    >
                      <Grid item>
                        <EvesButton
                          sx={{ height: '40px' }}
                          onClick={onClickAddElectricVehicle}
                          data-cy="create-registration-token-button"
                        >
                          <Add />
                        </EvesButton>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
               
                    <TableRefreshComponent
                      onRefresh={fetchAllElectricVehicles}
                      componentName="chargingStation"
                      autoRefreshStatus={true}
                      hideLabel={true}
                    />
               
              </Grid>
              {!state.isResetBtnDisabled && (
                <Grid
                  item
                  display={'flex'}
                  justifyContent={'end'}
                  mt={2}
                  ml={3}
                  gap={'12px'}
                >
                  <Tooltip
                    title={`${translate('general.reset_filter')}`}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          color: 'black',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                        },
                      },
                    }}
                    followCursor={false}
                    placement={'bottom-start'}
                    enterNextDelay={500}
                  >
                    <Grid item>
                      <EvesButton
                        sx={{ height: '40px' }}
                        onClick={resetFilters}
                        data-cy="resetFilterBtn"
                      >
                        <Cancel />
                      </EvesButton>
                    </Grid>
                  </Tooltip>
                </Grid>
              )} */}
              <ButtonGroup
                {...{
                  showfilter,
                  setShowfilter,
                  resetFilters: resetFilters,
                  state,
                  onClickCreate: onClickAddElectricVehicle,
                  onRefresh: fetchAllElectricVehicles,
                  isCreate: true,
                }}
              />
            </Grid>
          </Grid>
        }
        contentBody={
          <DataTable
            className='charging-stations-table'
            rows={state.rows}
            columns={buildTableColumns()}
            totalRowsCount={state.totalRowsCount}
            actionsMenu={primaryActions}
            contextActionsMenu={secondaryActions}
            onChange={onChargingStationTableChange}
            data-cy='charging-stations-table'
            projectFields={state.projectFields}
            defaultSortField={state.sortFields}
            count={state.totalRowsCount}
            limit={state.limit}
            page={state.page}
            tablePagination={true}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          >
            <ImageCell slot='image' />
            <DefaultEV slot='defaultEV' />
          </DataTable>
        }
      ></Content>

      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='single'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}
      {state.showEvMakerLookUpModal && (
        <LookupComponent
          title={`${translate('cars.select_ev_makers')}`}
          showLookupModal={state.showEvMakerLookUpModal}
          onModalClose={onEvMakersFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.EV_MAKER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onEVMakerSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}
      {state.showRegistrationElectricVehicleModal && (
        <CreateElectricForm
          electricVehicleId={state.electricVehicleId}
          openElectricVehicle={state.showRegistrationElectricVehicleModal}
          onFormClose={onCloseSiteAreaForm}
          pageLimit={DefaultPageSize}
          fetchAllElectricVehicles={() => fetchAllElectricVehicles()}
          resetElectricVehicleForm={resetElectricForm}
          canCreate={state.canCreate}
        />
      )}
    </>
  );
};

export default withContext(ElectricVehicles);
