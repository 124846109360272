
import { KeyboardBackspace } from '@mui/icons-material';
import logo from '@/assets/img/mainLogo.svg';
import { ReactComponent as FirstConnector } from '@/assets/img/firstConnector.svg';
import { ReactComponent as SecondConnector } from '@/assets/img/secondConnector.svg';
import styles from './styles.module.scss';
export const ErrorContent = () => {
    const handleReload = () => {
        window.location.href = '/dashboard#/dashboard';
        window.location.reload();
    };
    return (
        <div style={{ textAlign: 'center', height: '95vh' }}>
            <img
                src={logo}
                alt='logo'
            />
            <div className={styles.errorPageContainer}>
                <h1 className={styles.errorPageHeaderText}>This site is currently down for maintenance</h1>
                <p className={styles.errorPageDescText}>We apologize for any inconveniences caused.
                    We’ve almost done.</p>
                <button onClick={handleReload} className={styles.errorPageBackButton}><KeyboardBackspace /> Back to Home</button>
            </div>
            <FirstConnector className={styles.errorPageFirstConnector} />
            <SecondConnector className={styles.errorPageSecondConnector} />
        </div>
    );
};