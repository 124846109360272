import { EvesButton } from '@/common/components/atoms/button/button';
import EvesSelect from '@/common/components/atoms/select/select';
import { Card } from '@/common/components/molecules/card/card';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { convertWattToAmp, withContext } from '@/common/utils/withContext';
import { Grid, FormControlLabel } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import NacsIcon from '@/assets/img/connectors/nscc.svg';
import Type2Icon from '@/assets/img/connectors/type2.svg';
import Type2ComboCcsIcon from '@/assets/img/connectors/combo-ccs.svg';
import ChademoIcon from '@/assets/img/connectors/chademo.svg';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {
  phaseAssignmentToGridMapSinglePhased,
  phaseAssignmentToGridMapThreePhased,
} from '@/common/constants/constants';
import { useState } from 'react';
import { CurrentType, TenantComponents } from '@/common/enums/enums';
import GenerateQRCode from './generateQrCode';
import Type1Icon from '@/assets/img/connectors/type1.svg';
import Type1CCSIcon from '@/assets/img/connectors/type1-ccs-new.svg';
import NoConnector from '@/assets/img/connectors/no-connector.svg';
import { GroupWork } from '@mui/icons-material';
import {
  IconType1,
  IconType2,
  IconType2ComboCcs,
  IconChademo,
  IconType1CcsNew,
  IconNoConnector,
  IconNacs,
} from '@/common/components/atoms/icon/icon';
import { useTranslation } from 'react-i18next';
import { isActive } from '@/common/utils/utils';
import { chargingStationschemaConstants } from '@/modules/chargingStation/shared/shemas/chargingStationSchema';
import { generateChargingStationQRCode } from '@/services/chargingStations';
import { useQuery } from 'react-query';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';

const Connector = (props: any) => {
  const chargingStationForm = props.chargingStationForm;
  const { control, watch } = props.chargingStationForm;
  const chargingStationFormValues = props.chargingStationForm.getValues();
  const [QRcode, setQRcode] = useState(false);
  const [connectorId, setConnectorId] = useState();
  const chargingStationId = chargingStationFormValues.id;
  const { t: translate } = useTranslation();
  const isOCPIActive = isActive(TenantComponents.OCPI);
  const handleInfinity = (value: number): number =>
    value === Infinity ? 0 : value;
  const [qrCodeImg, setQrCodeImg] = useState('');
  const refreshPower = (index: number) => {
    const amperage = watch(`connectors.${index}.amperage`);
    const voltage = watch(`connectors.${index}.voltage`);
    if ((amperage as number) > 0 && (voltage as number) > 0) {
      props.chargingStationForm.setValue(
        `connectors.${index}.power`,
        Math.floor((amperage as number) * (voltage as number))
      );
    } else {
      props.chargingStationForm.setValue(`connectors.${index}.power`, 0);
    }
    connectorChanged();
  };

  const { fields: connectorFields } = useFieldArray({
    control,
    name: 'connectors',
  });

  const connectorChanged = () => {
    let totalPower = 0;
    if (chargingStationFormValues?.connectors) {
      chargingStationFormValues.connectors.map((connectorControl, index) => {
        const power = watch(`connectors.${index}.power`);
        if (power) {
          if ((power as number) > 0) {
            totalPower += power;
          }
        }
      });
    }

    // props.chargingStationForm.setValue(
    //   'maximumPower',
    //   handleInfinity(totalPower)
    // );
    props.chargingStationForm.trigger('maximumPower');
    props.chargingStationForm.setValue(
      'maximumAmps',
      convertWattToAmp(chargingStationFormValues, 0, handleInfinity(totalPower))
    );
  };

  const refreshNumberOfPhases = (index: number) => {
    const currentType = watch(`connectors.${index}.currentType`);
    if (currentType === CurrentType.DC) {
      props.chargingStationForm.setValue(
        `connectors.${index}.numberOfConnectedPhase`,
        3
      );
      props.chargingStationForm.setValue(
        `connectors.${index}.phaseAssignmentToGrid`,
        { csPhaseL1: 'L1', csPhaseL2: 'L2', csPhaseL3: 'L3' }
      );
    } else if (currentType === CurrentType.AC) {
      props.chargingStationForm.setValue(
        `connectors.${index}.numberOfConnectedPhase`,
        1
      );
      props.chargingStationForm.setValue(
        `connectors.${index}.phaseAssignmentToGrid`,
        { csPhaseL1: 'L1', csPhaseL2: null, csPhaseL3: null }
      );
    }
  };

  const refreshVoltage = (index: number) => {
    const currentType = watch(`connectors.${index}.currentType`);
    if (currentType === CurrentType.DC) {
      props.chargingStationForm.setValue(`connectors.${index}.voltage`, 277);
    } else if (currentType === CurrentType.AC) {
      props.chargingStationForm.setValue(`connectors.${index}.voltage`, 240);
    }
  };

  const refreshTotalAmperage = (index: number) => {
    const amperagePerPhase = watch(`connectors.${index}.amperagePerPhase`);
    const numberOfConnectedPhase = watch(
      `connectors.${index}.numberOfConnectedPhase`
    );
    if ((amperagePerPhase as number) > 0) {
      props.chargingStationForm.setValue(
        `connectors.${index}.amperage`,
        handleInfinity(
          (amperagePerPhase as number) * (numberOfConnectedPhase as number)
        )
      );
    } else {
      if (!new RegExp(/.*[e|E]$/).test(amperagePerPhase)) {
        props.chargingStationForm.setValue(`connectors.${index}.amperage`, 0);
      }
    }
    props.chargingStationForm.trigger(`connectors.${index}.amperagePerPhase`);
    connectorChanged();
  };

  const connectorTypeOptions = [
    {
      label: `${translate('chargers.connector_type_type2')}`,
      value: 'T2',
      img: Type2Icon,
      icon: <IconType2 />,
    },
    {
      label: `${translate('chargers.connector_type_combo')}`,
      value: 'CCS',
      img: Type2ComboCcsIcon,
      icon: <IconType2ComboCcs />,
    },
    {
      label: `${translate('chargers.connector_type_chademo')}`,
      value: 'C',
      img: ChademoIcon,
      icon: <IconChademo />,
    },
    {
      label: `${translate('chargers.connector_type_type1')}`,
      value: 'T1',
      img: Type1Icon,
      icon: <IconType1 />,
    },
    {
      label: `${translate('chargers.connector_type_type1ccs')}`,
      value: 'T1CCS',
      img: Type1CCSIcon,
      icon: <IconType1CcsNew />,
    },
    {
      label: `${translate('chargers.connector_type_nacs')}`,
      value: 'NACS',
      img: NacsIcon,
      icon: <IconNacs />,
    },
    {
      label: `${translate('chargers.connector_type_unknown')}`,
      value: 'U',
      img: NoConnector,
      icon: <IconNoConnector />,
    },
  ];

  const currentTypeOptions = [
    {
      label: `${translate('chargers.alternating_current')}`,
      value: 'AC',
      img: require('@/assets/img/integrations/Ac icon_dark.png'),
    },
    {
      label: `${translate('chargers.direct_current')}`,
      value: 'DC',
      img: require('@/assets/img/integrations/DC icon_dark.png'),
    },
  ];

  const { refetch: generateQrCode } = useQuery(
    ['Qrcode', connectorId],
    () => {
      return generateChargingStationQRCode(chargingStationId, connectorId);
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        setQrCodeImg(data.image);
      },
    }
  );

  const onDisplayQRCode = async (connector: any) => {
    await setConnectorId(connector.connectorId);
    await generateQrCode();
    setQRcode(true);
  };

  const onQRCodeClose = () => {
    setQRcode(false);
  };

  const connectedPhaseOptions = [
    { label: `${translate('chargers.single_phase')}`, value: 1 },
    { label: `${translate('chargers.tri_phases')}`, value: 3 },
  ];

  const currentTypeChanged = (index: number) => {
    refreshNumberOfPhases(index);
    refreshTotalAmperage(index);
    refreshVoltage(index);
  };

  const updateMaxPower = (index: number) => {
    const currentType = watch(`connectors.${index}.numberOfConnectedPhase`);
    const current = watch(`connectors.${index}.amperagePerPhase`);
    const voltage = watch(`connectors.${index}.voltage`);
    if (currentType === 3) {
      props.chargingStationForm.setValue(
        `connectors.${index}.power`,
        3 * current * voltage
      );
      // props.chargingStationForm.setValue(
      //   'maximumPower',
      //   3 * current * voltage
      // );
    } else if (currentType === 1) {
      props.chargingStationForm.setValue(
        `connectors.${index}.power`,
        current * voltage
      );
      // props.chargingStationForm.setValue(
      //   'maximumPower',
      //   current * voltage
      // );
    }
  };

  const connectorTypeChanged = (index: number) => {
    const type = watch(`connectors.${index}.type`);
    if (type === 'T1' || type == 'T2') {
      props.chargingStationForm.setValue(
        `connectors.${index}.currentType`,
        'AC'
      );
      props.chargingStationForm.setValue(`connectors.${index}.voltage`, 240);
    } else if (type === 'CCS' || type === 'T1CCS' || type === 'C') {
      props.chargingStationForm.setValue(
        `connectors.${index}.currentType`,
        'DC'
      );
      props.chargingStationForm.setValue(`connectors.${index}.voltage`, 277);
    } else if (type === 'D') {
      props.chargingStationForm.trigger(`connectors.${index}.currentType`);
    }
    refreshNumberOfPhases(index);
    updateMaxPower(index);
  };

  const getVoltageOption = (currentType) => {
    let updatedOptions: any = [];
    if (currentType == 'AC') {
      updatedOptions = [
        { label: '400', value: 400 },
        { label: '380', value: 3800 },
        { label: '240', value: 240 },
        { label: '230', value: 230 },
        { label: '208', value: 208 },
        { label: '120', value: 120 },
        { label: '110', value: 110 },
      ];
    } else {
      updatedOptions = [
        { label: '346', value: 346 },
        { label: '277', value: 277 },
        { label: '230', value: 230 },
        { label: '220', value: 220 },
      ];
    }
    return updatedOptions;
  };

  const amperageChanged = (index: number) => {
    refreshTotalAmperage(index);
    refreshPower(index);
    updateMaxPower(index);
  };

  const voltageChanged = (index: number) => {
    refreshPower(index);
    updateMaxPower(index);
  };

  const numberOfConnectedPhaseChanged = (index: number) => {
    refreshTotalAmperage(index);
    updateMaxPower(index);
    refreshPower(index);
    if (watch(`connectors.${index}.numberOfConnectedPhase`) === 1) {
      props.chargingStationForm.setValue(
        `connectors.${index}.phaseAssignmentToGrid`,
        { csPhaseL1: 'L1', csPhaseL2: null, csPhaseL3: null }
      );
    } else {
      props.chargingStationForm.setValue(
        `connectors.${index}.phaseAssignmentToGrid`,
        { csPhaseL1: 'L1', csPhaseL2: 'L2', csPhaseL3: 'L3' }
      );
    }
    // props.chargingStationForm.trigger(`connectors.${index}.phaseAssignmentToGrid`);
    // props.chargingStationForm.setValue(`connectors.${index}.shrink`, false);
  };

  const getPhaseAssignementToGridOptions = (
    numberOfConnectedPhase?: number
  ) => {
    const phaseAssignementToGridOptions: any =
      numberOfConnectedPhase == 1
        ? phaseAssignmentToGridMapSinglePhased
        : phaseAssignmentToGridMapThreePhased;
    return phaseAssignementToGridOptions.map(({ label, value }) => ({
      label,
      value: JSON.stringify(value),
    }));
  };

  const isValidNumber = (event: any): boolean => {
    const numRule = new RegExp(
      /^[+-]?([0-9]+(?:[\\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]*)?$|^$|^[+-]/
    );
    return numRule.test(event.target.value);
  };
  const handleNaN = (value: any, index: number) => {
    if (isNaN(watch(`connectors.${index}.amperagePerPhase`))) {
      if (!new RegExp(/.*[e|E]$/).test(value)) {
        props.chargingStationForm.setValue(
          `connectors.${index}.amperagePerPhase`,
          0
        );
      }
    }
    return value;
  };

  const getDisableCurrentType = (index) => {
    const type = watch(`connectors.${index}.type`);
    if (
      type === 'T1' ||
      type == 'T2' ||
      type === 'CCS' ||
      type === 'T1CCS' ||
      type === 'C'
    ) {
      return true;
    } else {
      return !props.canUpdateChargingStation;
    }
  };

  const getConnectors = (connecotrIDs) => {
    const NACType = 'NACS';
    const currentType = 'AC';
    return (
      <div
        id='connectors'
        key='connecotrs'
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: '25px',
        }}
      >
        {QRcode && (
          <GenerateQRCode
            connectorId={connectorId}
            isOpen={QRcode}
            onClose={onQRCodeClose}
            chargingStationId={chargingStationId}
            qrCodeImg={qrCodeImg}
          />
        )}
        {connectorFields?.map((item: any, index) => {
          if (
            connecotrIDs?.length > 0
              ? connecotrIDs.includes(item.connectorId)
              : true
          ) {
            const numberOfConnectedPhase = props.chargingStationForm.getValues(
              `connectors.${index}.numberOfConnectedPhase`
            );
            const phaseAssignementToGridOptions =
              getPhaseAssignementToGridOptions(numberOfConnectedPhase);
            const voltageOptions = getVoltageOption(
              watch(`connectors.${index}.currentType`)
            );
            const selectedOption = connectorTypeOptions.find(
              (option) => option.value === watch(`connectors.${index}.type`)
            );
            const Icon = selectedOption ? selectedOption.icon : <></>;
            return (
              <Card
                key={index}
                icon={Icon}
                title={
                  index == 0
                    ? `${translate('chargers.connector')} A`
                    : `${translate('chargers.connector')} B`
                }
              >
                <Grid container direction='row' spacing={0.5} key={index}>
                  <Grid container direction='row' spacing={1}>
                    <Grid item xs={6}>
                      <Controller
                        name={`connectors.${index}.type`}
                        control={chargingStationForm.control}
                        render={({ field }) => {
                          return (
                            <EvesSelect
                              disabled={!props.canUpdateChargingStation}
                              label={`${translate(
                                'chargers.connector_type'
                              )} *`}
                              {...field}
                              options={connectorTypeOptions}
                              onChange={(event: any) => {
                                field.onChange(event);
                                props.chargingStationForm.trigger(
                                  `connectors.${index}.type`
                                );
                                connectorTypeChanged(index);
                              }}
                              error={
                                !!chargingStationForm.formState?.errors
                                  ?.connectors?.[index]?.type
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`connectors.${index}.power`}
                        control={chargingStationForm.control}
                        render={({ field }) => (
                          <EvesTextbox
                            {...field}
                            id='power'
                            label={translate('chargers.maximum_energy')}
                            fullWidth
                            disabled={!props.canUpdateChargingStation || true}
                            variant='standard'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} data-cy='currentImg'>
                      <Controller
                        name={`connectors.${index}.currentType`}
                        control={chargingStationForm.control}
                        render={({ field }) => (
                          <EvesSelect
                            label={`${translate('chargers.current_type')} *`}
                            options={currentTypeOptions}
                            disabled={getDisableCurrentType(index)}
                            {...field}
                            onChange={(event: any) => {
                              field.onChange(event);
                              currentTypeChanged(index);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`connectors.${index}.numberOfConnectedPhase`}
                        control={chargingStationForm.control}
                        render={({ field }) => (
                          <EvesSelect
                            disabled={
                              watch(`connectors.${index}.currentType`) ==
                                'DC' ||
                              !props.canUpdateChargingStation ||
                              watch(`connectors.${index}.type`) == 'T1' ||
                              watch(`connectors.${index}.type`) == 'D'
                            }
                            label={`${translate(
                              'chargers.nb_connected_phase'
                            )} *`}
                            options={
                              watch(`connectors.${index}.type`) ===
                                `${NACType}` &&
                              watch(`connectors.${index}.currentType`) ===
                                `${currentType}`
                                ? connectedPhaseOptions.slice(0, 1)
                                : connectedPhaseOptions
                            }
                            {...field}
                            onChange={(event: any) => {
                              field.onChange(event);
                              numberOfConnectedPhaseChanged(index);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`connectors.${index}.amperagePerPhase`}
                        control={chargingStationForm.control}
                        render={({ field }) => (
                          <EvesTextbox
                            {...field}
                            id='amperagePerPhase'
                            label={`${translate(
                              'chargers.amperagePerPhase'
                            )} *`}
                            error={
                              !!chargingStationForm.formState?.errors
                                ?.connectors?.[index]?.amperagePerPhase
                            }
                            helperText={
                              chargingStationForm.formState?.errors
                                ?.connectors?.[index]?.amperagePerPhase
                                ?.message &&
                              translate(
                                chargingStationForm.formState?.errors
                                  ?.connectors?.[index]?.amperagePerPhase
                                  ?.message || ' '
                              )
                            }
                            disabled={false || !props.canUpdateChargingStation}
                            onChange={(event: any) => {
                              if (!isValidNumber(event)) return;
                              field.onChange(event);
                              amperageChanged(index);
                            }}
                            variant='standard'
                            value={handleNaN(field.value, index)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`connectors.${index}.amperage`}
                        control={chargingStationForm.control}
                        render={({ field }) => (
                          <EvesTextbox
                            {...field}
                            id='tariffID'
                            label={translate('chargers.amperage')}
                            fullWidth
                            disabled={!props.canUpdateChargingStation || true}
                            variant='standard'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`connectors.${index}.voltage`}
                        control={chargingStationForm.control}
                        render={({ field }) => (
                          <EvesSelect
                            disabled={!props.canUpdateChargingStation}
                            label={`${translate('chargers.voltage')} *`}
                            options={voltageOptions}
                            {...field}
                            onChange={(event: any) => {
                              field.onChange(event);
                              voltageChanged(index);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {chargingStationFormValues.issuer && (
                      <Grid item xs={6}>
                        <Controller
                          name={`connectors.${index}.phaseAssignmentToGrid`}
                          control={chargingStationForm.control}
                          render={({ field }) => {
                            return (
                              <EvesSelect
                                disabled={!props.canUpdateChargingStation}
                                label={`${translate(
                                  'chargers.phase_assignment'
                                )} *`}
                                options={phaseAssignementToGridOptions}
                                {...field}
                                error={
                                  !!chargingStationForm.formState?.errors
                                    ?.connectors?.[index]?.phaseAssignmentToGrid
                                }
                                helperText={translate(
                                  chargingStationForm.formState?.errors
                                    ?.connectors?.[index]?.phaseAssignmentToGrid
                                    ?.message || ' '
                                )}
                                value={JSON.stringify(field.value)}
                                onChange={(event: any) => {
                                  field.onChange(
                                    JSON.parse(event.target.value)
                                  );
                                  props.chargingStationForm.trigger(
                                    `connectors.${index}.phaseAssignmentToGrid`
                                  );
                                  props.chargingStationForm.setValue(
                                    `connectors.${index}.shrink`,
                                    true
                                  );
                                }}
                                shrink={props.chargingStationForm.watch(
                                  `connectors.${index}.shrink`
                                )}
                              />
                            );
                          }}
                        />
                      </Grid>
                    )}
                    {props.isPublic && isOCPIActive && (
                      <Grid item xs={6}>
                        <Controller
                          name={`connectors.${index}.tariffID`}
                          control={chargingStationForm.control}
                          render={({ field }) => (
                            <EvesTextbox
                              {...field}
                              id='connctorTariffID'
                              label={translate('ocpi.tariff_id')}
                              fullWidth
                              disabled={false}
                              variant='standard'
                              error={
                                !!chargingStationForm.formState?.errors
                                  ?.connectors?.[index]?.tariffID
                              }
                              helperText={translate(
                                chargingStationForm.formState?.errors
                                  ?.connectors?.[index]?.tariffID?.message ||
                                  ' '
                              ).replace(
                                /{{length}}/g,
                                `'${chargingStationschemaConstants.tariffIDMaxLength}'`
                              )}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {chargingStationFormValues.canGetConnectorQRCode && (
                        <EvesButton
                          type='button'
                          startIcon={<QrCode2Icon />}
                          id={`connectors.${index}.generateQRCode`}
                          onClick={() =>
                            onDisplayQRCode(connectorFields[index])
                          }
                          variant='contained'
                        >
                          {translate('general.display_qr')}
                        </EvesButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            );
          }
        })}
      </div>
    );
  };

  return (
    <>
      {chargingStationFormValues?.chargePoints?.length > 0 ? (
        <>
          <div
            id='connectors'
            key='connecotrs'
            style={{
              display: 'grid',
              gridTemplateRows: '1fr',
              gridRowGap: '25px',
            }}
          >
            {chargingStationFormValues?.chargePoints.map((item, index) => {
              return (
                <Card
                  key={index}
                  icon={<GroupWork />}
                  title={`${translate('chargers.charge_point')} ${
                    item?.chargePointID
                  }`}
                >
                  <Grid item xs={3} mb={2}>
                    <Controller
                      name={`chargePoints.${index}.excludeFromPowerLimitation`}
                      control={chargingStationForm.control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <EvesCheckbox
                              id={`chargePoints.${index}.excludeFromPowerLimitation`}
                              {...field}
                              checked={props.chargingStationForm.watch(
                                `chargePoints.${index}.excludeFromPowerLimitation`
                              )}
                            />
                          }
                          label={translate(
                            'chargers.exclude_from_power_limitation'
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {getConnectors(item?.connectorIDs)}
                </Card>
              );
            })}
          </div>
        </>
      ) : (
        getConnectors([])
      )}
    </>
  );
};

export default withContext(Connector);
