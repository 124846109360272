import { IUserProfileModel } from '@/store/auth/types';
import { IUserContextModel } from '@/store/auth/types';
import { useSelector } from 'react-redux';
import { ChartConstants, DEFAULT_CURRENCY_CODE, StatusCodes, constMinDivisorBar, constMinDivisorPie, languageLocale } from '../constants/constants';
import { PermissionRoleConfig } from '../constants/permissionRoleConfig';
import { NotifyType } from './notificationService';
import { Action, ChartTypeValues, Entity, MobileType, Size, StartTransactionErrorCode, StaticLimitAmps, TenantComponents, Unit, UserRole, Voltage } from '../enums/enums';
import { columnsType } from '../models/model';
import dayjs from 'dayjs';
import i18next from 'i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/cs';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import store from '@/store';

// These imports are for mui date picker to display calendar in user's locale language
import fr from 'date-fns/locale/fr';
import cs from 'date-fns/locale/cs';
import es from 'date-fns/locale/es';
import it from 'date-fns/locale/it';
import de from 'date-fns/locale/de';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';

/**
 * Parse the access token and fetch user info
 * @param token Access token to parse
 * @returns IUserProfileModel Params with user data
 */
export const parseAccessToken = (token: string): IUserProfileModel => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	const userInfo: any = JSON.parse(jsonPayload);
	return {
		...userInfo,
		email: userInfo.email,
		userId: userInfo.id,
		tenantId: userInfo.tenantID,
		firstName: userInfo.firstName,
		lastName: userInfo.name,
		currency: userInfo.currency,
		language: userInfo.language,
		locale: userInfo.locale
	};
};

export const canAccess = (resource: string, action: string): boolean => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	return !!authContext.userInfo && !!authContext.userInfo.scopes && authContext.userInfo.scopes.includes(`${resource}:${action}`);
};

export const hasPermission = (path: string, isVisibleWithoutPermission?: boolean) => {
	if (isVisibleWithoutPermission) return true;

	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);

	const permissionRoleConfig = PermissionRoleConfig[path];

	const userHasRequiredRole = (authContext.isAuthenticated && authContext.userInfo.role) && permissionRoleConfig?.roles.includes(authContext.userInfo.role) ? true : false;
	const userHasRequiredPermission = permissionRoleConfig?.permissions.some((permission: { entity: string; action: string; }) => { return canAccess(permission.entity, permission.action);});
	if (!userHasRequiredRole || !userHasRequiredPermission) {
		return false;
	}

	return true;
};

export const getSubdomain = () => {
	if (window.location.host.includes('localhost')) {
		const subdomainParts = window.location.host.split('localhost');
		return subdomainParts[0] !== '' ? subdomainParts[0].split('.')[0] : null;
	} else if (process.env.REACT_APP_HOST_DOMAIN_NAME) {
		const subdomainParts = window.location.host.split(process.env.REACT_APP_HOST_DOMAIN_NAME);
		return subdomainParts[0] !== '' ? subdomainParts[0].split('.')[0] : null;
	}
	return null;
};

export const blobToBase64String = (blob?: Blob): Promise<string> => {
	return new Promise((resolve, reject) => {
		if (blob && blob.size) {
			const reader = new FileReader();
			reader.onerror = reject;
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.readAsDataURL(blob);
		} else {
			resolve('');
		}
	});
};

export const isEmptyArray = (array: any) => {
	if (!array) {
		return true;
	}
	if (Array.isArray(array) && array.length > 0) {
		return false;
	}
	return true;
};

export const roundTo = (value: number, scale: number) => {
	const roundPower = Math.pow(10, scale);
	return Math.round(value * roundPower) / roundPower;
};

export const convertAmpToWatt = (chargingStation: any, chargePoint: any, connectorID = 0, ampValue: number) => {
	const voltage = getChargingStationVoltage(chargingStation, chargePoint, connectorID);
	if (voltage) {
		return voltage * ampValue;
	}
	return 0;
};

export const getAvailableRoles = (role?: string): any[] => {
	if (role === UserRole.SUPER_ADMIN) {
		return [
			{ key: UserRole.SUPER_ADMIN, value: i18next.t('users.role_super_admin') },
		];
	}
	return [
		{ key: UserRole.ADMIN, value: i18next.t('users.role_admin') },
		{ key: UserRole.BASIC, value: i18next.t('users.role_basic') },
		{ key: UserRole.DEMO, value: i18next.t('users.role_demo') },
	];
};

export const getChargingStationVoltage = (chargingStation: any, chargePoint?: any, connectorId = 0) => {
	if (chargingStation) {
		// Check at charging station level
		if (chargingStation.voltage) {
			return chargingStation.voltage;
		}
		// Check at charge point level
		if (chargingStation.chargePoints) {
			for (const chargePointOfCS of chargingStation.chargePoints) {
				if (!chargePoint || chargePoint?.chargePointID === chargePointOfCS?.chargePointID) {
					// Charging Station
					if (connectorId === 0 && chargePointOfCS?.voltage) {
						return chargePointOfCS?.voltage;
					}
					// Connector
					if (chargePointOfCS.connectorIDs.includes(connectorId) && chargePointOfCS.voltage) {
						// Check Connector ID
						const connector = getConnectorFromID(chargingStation, connectorId);
						if (connector.voltage) {
							return connector.voltage;
						}
						return chargePointOfCS?.voltage;
					}
				}
			}
		}
		// Check at connector level
		if (chargingStation.connectors) {
			for (const connector of chargingStation.connectors) {
				// Take the first
				if (connectorId === 0 && connector.voltage) {
					return connector.voltage;
				}
				if (connector.connectorId === connectorId && connector.voltage) {
					return connector.voltage;
				}
			}
		}
	}
	return Voltage.VOLTAGE_230;
};

export const getConnectorFromID = (chargingStation: any, connectorID: number) => {
	if (!chargingStation.connectors) {
		return null;
	}
	return chargingStation.connectors.find((connector: any) =>
		connector && (connector.connectorId === connectorID));
};

export const getChargingStationPowers = (chargingStation: any, chargePoint?: any, connectorId = 0, forChargingProfile: boolean = false) => {
	const numberOfPhases = getNumberOfConnectedPhases(chargingStation, chargePoint, connectorId);
	const numberOfConnectors = chargePoint ? chargePoint?.connectorIDs?.length : chargingStation.connectors.length;
	const result = {
		notSupported: false,
		minAmp: StaticLimitAmps.MIN_LIMIT_PER_PHASE * numberOfPhases * numberOfConnectors,
		minWatt: convertAmpToWatt(chargingStation, chargePoint, connectorId,
			StaticLimitAmps.MIN_LIMIT_PER_PHASE * numberOfPhases * numberOfConnectors),
		maxAmp: StaticLimitAmps.MIN_LIMIT_PER_PHASE * numberOfPhases * numberOfConnectors,
		maxWatt: convertAmpToWatt(chargingStation, chargePoint, connectorId,
			StaticLimitAmps.MIN_LIMIT_PER_PHASE * numberOfPhases * numberOfConnectors),
		currentAmp: 0,
		currentWatt: 0,
	};
	if (!chargingStation ||
		!chargingStation.connectors ||
		isEmptyArray(chargingStation.connectors)) {
		result.notSupported = true;
		result.currentAmp = result.maxAmp;
		result.currentWatt = convertAmpToWatt(
			chargingStation, chargePoint, connectorId, result.currentAmp);
		return result;
	}
	const chargingStationAmperageLimit = getChargingStationAmperageLimit(chargingStation, chargePoint, connectorId);
	// Use Limit Amps
	if (forChargingProfile) {
		result.maxAmp = chargingStationAmperageLimit;
	} else {
		result.currentAmp = chargingStationAmperageLimit;
		result.maxAmp = getChargingStationAmperage(chargingStation, chargePoint, connectorId);
	}
	// Default
	if (result.currentAmp === 0) {
		result.currentAmp = result.maxAmp;
	}
	result.minWatt = convertAmpToWatt(chargingStation, chargePoint, connectorId, result.minAmp);
	result.maxWatt = convertAmpToWatt(chargingStation, chargePoint, connectorId, result.maxAmp);
	result.currentWatt = convertAmpToWatt(chargingStation, chargePoint, connectorId, result.currentAmp);
	return result;
};

export const getNumberOfConnectedPhases = (chargingStation: any, chargePoint?: any, connectorId = 0) => {
	if (chargingStation) {
		// Check at charge point level
		if (chargingStation.chargePoints) {
			for (const chargePointOfCS of chargingStation.chargePoints) {
				if (!chargePoint || chargePoint.chargePointID === chargePointOfCS.chargePointID) {
					// Charging Station
					if (connectorId === 0 && chargePointOfCS.numberOfConnectedPhase) {
						return chargePointOfCS.numberOfConnectedPhase;
					}
					// Connector
					if (chargePointOfCS?.connectorIDs?.includes(connectorId) && chargePointOfCS.numberOfConnectedPhase) {
						// Check Connector ID
						const connector = getConnectorFromID(chargingStation, connectorId);
						if (connector.numberOfConnectedPhase) {
							return connector.numberOfConnectedPhase;
						}
						return chargePointOfCS.numberOfConnectedPhase;
					}
				}
			}
		}
		// Check at connector level
		if (chargingStation.connectors) {
			for (const connector of chargingStation.connectors) {
				// Take the first
				if (connectorId === 0 && connector.numberOfConnectedPhase) {
					return connector.numberOfConnectedPhase;
				}
				if (connector.connectorId === connectorId && connector.numberOfConnectedPhase) {
					return connector.numberOfConnectedPhase;
				}
			}
		}
	}
	return 1;
};

export const getChargingStationAmperageLimit = (chargingStation: any, chargePoint: any, connectorId = 0) => {
	let amperageLimit = 0;
	if (chargingStation) {
		if (connectorId > 0) {
			return getConnectorFromID(chargingStation, connectorId).amperageLimit;
		}
		// Check at charge point level
		if (chargingStation.chargePoints) {
			for (const chargePointOfCS of chargingStation.chargePoints) {
				if (!chargePoint || chargePoint.chargePointID === chargePointOfCS.chargePointID) {
					if (chargePointOfCS.excludeFromPowerLimitation) {
						continue;
					}
					if (chargePointOfCS.cannotChargeInParallel ||
						chargePointOfCS.sharePowerToAllConnectors) {
						// Add limit amp of one connector
						amperageLimit += getConnectorFromID(chargingStation, chargePointOfCS.connectorIDs[0]).amperageLimit;
					} else {
						// Add limit amp of all connectors
						for (const connectorID of (chargePointOfCS?.connectorIDs || [])) {
							amperageLimit += getConnectorFromID(chargingStation, connectorID).amperageLimit;
						}
					}
				}
			}
		}
		// Check at connector level
		if (amperageLimit === 0 && chargingStation.connectors) {
			for (const connector of chargingStation.connectors) {
				amperageLimit += connector.amperageLimit;
			}
		}
	}
	const amperageMax = getChargingStationAmperage(chargingStation, chargePoint, connectorId);
	// Check and default
	if (amperageLimit === 0 || amperageLimit > amperageMax) {
		amperageLimit = amperageMax;
	}
	return amperageLimit;
};

export const getChargingStationAmperage = (chargingStation: any, chargePoint?: any, connectorId = 0) => {
	let totalAmps = 0;
	if (chargingStation) {
		// Check at charge point level
		if (chargingStation?.chargePoints) {
			for (const chargePointOfCS of chargingStation.chargePoints) {
				if (!chargePoint || chargePoint.chargePointID === chargePointOfCS.chargePointID) {
					// Charging Station
					if (connectorId === 0 && chargePointOfCS.amperage) {
						totalAmps += chargePointOfCS.amperage;
					} else if (chargePointOfCS?.connectorIDs?.includes(connectorId) && chargePointOfCS?.amperage) {
						if (chargePointOfCS.cannotChargeInParallel || chargePointOfCS.sharePowerToAllConnectors) {
							// Same power for all connectors
							// Check Connector ID first
							const connector = getConnectorFromID(chargingStation, connectorId);
							if (connector.amperage) {
								return connector.amperage;
							}
							return chargePointOfCS.amperage;
						}
						// Power is split evenly per connector
						return chargePointOfCS.amperage / chargePointOfCS.connectorIDs.length;
					}
				}
			}
		}
		// Check at connector level
		if (totalAmps === 0 && chargingStation.connectors) {
			for (const connector of chargingStation.connectors) {
				if (connectorId === 0 && connector.amperage) {
					totalAmps += connector.amperage;
				}
				if (connector.connectorId === connectorId && connector.amperage) {
					return connector.amperage;
				}
			}
		}
	}
	return totalAmps;
};

export const getChargePointFromID = (chargingStation: any, chargePointID: any) => {
	if (!chargingStation.chargePoints) {
		return null;
	}
	return chargingStation.chargePoints.find((chargePoint: any) =>
		chargePoint && (chargePoint.chargePointID === chargePointID));
};

export const computeStaticLimitAmpSteps = (chargingStation: any, chargePoint: any) => {
	if (chargingStation && chargePoint) {
		const numberOfPhases = getNumberOfConnectedPhases(chargingStation, chargePoint, 0);
		if (numberOfPhases > 0) {
			return numberOfPhases * chargePoint.connectorIDs.length;
		}
	}
	return 6;
};

export const convertAmpToWattString = (chargingStation: any, chargePoint: any, connectorId = 0,
	ampValue: number, unit: 'W' | 'kW' = 'kW', displayUnit: boolean = true,
	numberOfDecimals?: number) => {
	if (chargingStation) {
		return appUnitFormatter(
			convertAmpToWatt(chargingStation, chargePoint, connectorId, ampValue),
			'W', unit, displayUnit, 1, 0, numberOfDecimals ? numberOfDecimals : 0);
	}
	return 'N/A';
};

export const appUnitFormatter = (value: number, srcMeasure: string = '', destMeasure: string = '', withUnit: boolean = true, numberOfInteger: number = 1,
	numberOfDecimalMin: number = 2, numberOfDecimalMax: number = 2, useFormateNumber: boolean = false) => {
	const userInfo: any = localStorage.getItem('user_info');
	const userLanguage = JSON.parse(userInfo);
	if (value === 0) {
		numberOfDecimalMin = 0;
	}
	if (srcMeasure === destMeasure) {
		return value.toLocaleString(userLanguage.language) + `${withUnit ? ' ' + destMeasure : ''}`;
	}
	const src = parseMeasure(srcMeasure);
	const dest = parseMeasure(destMeasure);
	const localDestMeasure = destMeasure.replace('Wh', 'W.h');
	if (useFormateNumber) return FormateNumber((value / (src.size * dest.size)), numberOfDecimalMin, numberOfDecimalMax) + `${withUnit ? ' ' + localDestMeasure : ''}`;
	return formatDecimal(value / (src.size * dest.size),
		`${numberOfDecimalMax}`) + `${withUnit ? ' ' + localDestMeasure : ''}`;
};

const parseMeasure = (measureAsString: string) => {
	if (Unit[Unit[measureAsString]] === measureAsString) {
		return { unit: Unit[measureAsString], size: Size.BASIS };
	}
	return { unit: Unit[measureAsString.slice(1)], size: Size[measureAsString.slice(0, 1).toUpperCase()] as any };
};

const formatDecimal = (value, decimalPlaces) => {
	const formattedValue = value.toFixed(decimalPlaces).toLocaleString();
	return formattedValue;
};

export const toRgba = (rgb: string, alpha: number) => {
	if (!rgb) {
		return '';
	}
	let rgba = rgb.replace(/rgb/i, 'rgba');
	rgba = rgba.replace(/\)/i, `,${alpha})`);
	return rgba;
};

export const FormatLineColor = (color: string): any => {
	return {
		backgroundColor: `${color + '22'}`,
		borderColor: toRgba(color, 1),
		pointRadius: 0,
		pointHoverBackgroundColor: toRgba(color, 1),
		pointHoverBorderColor: '#fff',
		hoverBackgroundColor: toRgba(color, 0.8),
		hoverBorderColor: toRgba(color, 1),
	};
};

export const getStartTransactionErrorMessage = (errorCode: StartTransactionErrorCode): string => {
	switch (errorCode) {
		case StartTransactionErrorCode.BILLING_NO_PAYMENT_METHOD:
			return 'Service unavailable, please go to your profile to provide a payment method.';
		case StartTransactionErrorCode.BILLING_NO_TAX:
			return 'Start transaction is not possible - the tax ID is not set or inconsistent.';
		case StartTransactionErrorCode.BILLING_NO_SETTINGS:
			return 'Start transaction not possible - billing settings are not set (or partially set).';
		case StartTransactionErrorCode.BILLING_INCONSISTENT_SETTINGS:
			return 'Start transaction not possible - billing settings are inconsistent.';
		case StartTransactionErrorCode.ERROR_START_GENERAL:
			return 'Service temporarily unavailable, please contact the support.';
		default: return errorCode;
	}
};

export const FormateNumber = (value: number, minDigits: number = 2, maxDigits: number = 2): string => {
	const userInfo: any = localStorage.getItem('user_info');
	const userLanguage = JSON.parse(userInfo);
	return value?.toLocaleString(userLanguage.language, { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits });
};

export const getChargingStationPower = (chargingStation: any, chargePoint?: any, connectorId = 0) => {
	let totalPower = 0;
	if (chargingStation) {
		// Check at charge point level
		if (chargingStation.chargePoints) {
			for (const chargePointOfCS of chargingStation.chargePoints) {
				if (!chargePoint || chargePoint.chargePointID === chargePointOfCS.chargePointID) {
					// Charging Station
					if (connectorId === 0 && chargePointOfCS.power) {
						totalPower += chargePointOfCS.power;
						// Connector
					} else if (chargePointOfCS.connectorIDs.includes(connectorId) && chargePointOfCS.power) {
						if (chargePointOfCS.cannotChargeInParallel || chargePointOfCS.sharePowerToAllConnectors) {
							// Check Connector ID
							const connector = getConnectorFromID(chargingStation, connectorId);
							if (connector.power) {
								return connector.power;
							}
							return chargePointOfCS.power;
						}
						// Power is shared evenly on connectors
						return chargePointOfCS.power / chargePointOfCS.connectorIDs.length;
					}
				}
			}
		}
		// Check at connector level
		if (totalPower === 0 && chargingStation.connectors) {
			for (const connector of chargingStation.connectors) {
				if (connectorId === 0 && connector.power) {
					totalPower += connector.power;
				}
				if (connector.connectorId === connectorId && connector.power) {
					return connector.power;
				}
			}
		}
	}
	if (!totalPower) {
		const amperage = getChargingStationAmperage(chargingStation, chargePoint, connectorId);
		const voltage = getChargingStationVoltage(chargingStation, chargePoint, connectorId);
		if (voltage && amperage) {
			return voltage * amperage;
		}
	}
	return totalPower;
};

export const handleHttpError = (error: any, errorMessage: string) => {
	// Check error
	const notify: NotifyType = {
		message: '',
		type: 'success'
	};
	switch (error.status) {
		// Server connection error
		case 0:
			// ToDo;
			break;
		case StatusCodes.FORBIDDEN:
			notify.message = 'You are not authorized to perform this action';
			notify.type = 'error';
			break;
		case StatusCodes.BAD_REQUEST:
			notify.message = 'Invalid Content';
			notify.type = 'error';
			break;
		case StatusCodes.CONFLICT:
			notify.message = error.message;
			notify.type = 'error';
			break;
		case StatusCodes.REQUEST_TIMEOUT:
			notify.message = error.message;
			notify.type = 'error';
			break;
		// Backend issue
		default:
			notify.message = errorMessage;
			notify.type = 'error';
			break;
	}
	return notify;
};

export const handleError = (error: any, errorMessage: string = '') => {
	const notify: NotifyType = {
		message: '',
		type: 'error'
	};
	console.log(`Error: ${errorMessage}`, error);
	notify.message = errorMessage;
	notify.type = 'error';
	return notify;
};

export const isActive = (componentName: TenantComponents): boolean => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	const activeComponents = authContext.userInfo.activeComponents;
	if (authContext.isAuthenticated && activeComponents) {
		return activeComponents.includes(componentName);
	}
	return false;
};

export const buildCarCatalogName = (carCatalog: any, withID = false) => {
	let carCatalogName: string;
	if (!carCatalog) {
		return '-';
	}
	carCatalogName = carCatalog.vehicleMake;
	if (carCatalog.vehicleModel) {
		carCatalogName += ` ${carCatalog.vehicleModel}`;
	}
	if (carCatalog.vehicleModelVersion) {
		carCatalogName += ` ${carCatalog.vehicleModelVersion}`;
	}
	if (withID && carCatalog.id) {
		carCatalogName += ` (${carCatalog.id})`;
	}
	return carCatalogName;
};

export const convertToBoolean = (value: any) => {
	let result = false;
	// Check boolean
	if (value) {
		// Check the type
		if (typeof value === 'boolean') {
			// Already a boolean
			result = value;
		} else {
			// Convert
			result = (value === 'true');
		}
	}
	return result;
};

export const formatDate = (date: any) => {
	if (!date) {
		return ('');
	}
	date = new Date(date);
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	const strTime = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() % 100 + ', ' + hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

export const buildUserFullName = (user: any, withID = false) => {
	let fullName: string;
	if (!user || !user.name) {
		return '-';
	}
	// eslint-disable-next-line no-lonely-if
	if (user.firstName) {
		fullName = `${user.firstName} ${user.name}`;
	} else {
		fullName = user.name;
	}
	if (withID && user.id) {
		fullName += ` (${user.id})`;
	}
	return fullName;
};

export const formatDateTime = (date) => {
	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true
	} as any;
	return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getPreviousDate = () => {
	const today = new Date();
	const previousDate = new Date(today);
	previousDate.setDate(today.getDate() - 1);
	previousDate.setHours(0, 0, 0, 0);
	return previousDate;
};

export const getTodayWithEndTime = () => {
	const today = new Date();
	today.setHours(23, 59, 59, 0);
	return today;
};

export const isAdmin = (): boolean => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	return authContext.userInfo.role === UserRole.ADMIN;
};

export const isDemo = (): boolean => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	if (authContext.userInfo) {
		return authContext.userInfo.role === UserRole.DEMO;
	}
	return false;
};

export const isSuperAdmin = (): boolean => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	return authContext.userInfo.role === UserRole.SUPER_ADMIN;

};

export const isBasic = (): boolean => {
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	return authContext.userInfo.role === UserRole.BASIC;
};

export const canListUsers = (): boolean => {
	return canAccess(Entity.USER, Action.LIST);
};

export const canListUsersSites = (): boolean => {
	return canAccess(Entity.USERS_SITES, Action.LIST);
};

export const canListTokens = (): boolean => {
	return canAccess(Entity.REGISTRATION_TOKEN, Action.LIST);
};

export const canListPaymentMethods = (): boolean => {
	return canAccess(Entity.PAYMENT_METHOD, Action.LIST);
};

export const canListCars = (): boolean => {
	return canAccess(Entity.CAR, Action.LIST);
};

export const canUpdateCar = (): boolean => {
	return canAccess(Entity.CAR, Action.UPDATE);
};

export const canListTags = (): boolean => {
	return canAccess(Entity.TAG, Action.LIST);
};

export const canListTransactions = (): boolean => {
	return canAccess(Entity.TRANSACTION, Action.LIST);
};

export const canListTransfers = (): boolean => {
	return canAccess(Entity.BILLING_TRANSFER, Action.LIST);
};

export const canSynchronizeBillingUser = (): boolean => {
	return canAccess(Entity.USER, Action.SYNCHRONIZE_BILLING_USER);
};

export const canListUsersInError = (): boolean => {
	return canAccess(Entity.USER, Action.IN_ERROR);
};

export const canListTransactionsInError = (): boolean => {
	return canAccess(Entity.TRANSACTION, Action.IN_ERROR);
};

export const canListInvoicesBilling = (): boolean => {
	return canAccess(Entity.INVOICE, Action.LIST);
};

export const canRefundTransaction = (): boolean => {
	return canAccess(Entity.TRANSACTION, Action.REFUND_TRANSACTION);
};

export const canDeleteTransaction = (): boolean => {
	return canAccess(Entity.TRANSACTION, Action.DELETE);
};

export const canReadSetting = (): boolean => {
	return canAccess(Entity.SETTING, Action.READ);
};

export const canCreateSiteArea = (): boolean => {
	return canAccess(Entity.SITE_AREA, Action.CREATE);
};

export const canAssignUsersSites = (): boolean => {
	return canAccess(Entity.USERS_SITES, Action.ASSIGN);
};

export const canUnassignUsersSites = (): boolean => {
	return canAccess(Entity.USERS_SITES, Action.UNASSIGN);
};

export const canListChargingProfiles = (): boolean => {
	return canAccess(Entity.CHARGING_PROFILE, Action.LIST);
};

export const canListChargingStations = (): boolean => {
	return canAccess(Entity.CHARGING_STATION, Action.LIST);
};

export const canListChargingStationsInError = (): boolean => {
	return canAccess(Entity.CHARGING_STATION, Action.IN_ERROR);
};

export const isSiteAdmin = (siteID: string, loggedUser: IUserProfileModel): boolean => {
	const isAdmin = store.getState().userContext.userInfo.role === UserRole.ADMIN;
	return isAdmin || (!!loggedUser && !!loggedUser.sitesAdmin && loggedUser.sitesAdmin.includes(siteID));
};

export const isSiteUser = (siteID: string, loggedUser: IUserProfileModel): boolean => {
	const isAdmin = store.getState().userContext.userInfo.role === UserRole.ADMIN;
	if (isAdmin) {
		return true;
	}
	if (canAccess(Entity.SITE, Action.READ)) {
		return !!loggedUser && !!loggedUser.sites && loggedUser.sites.includes(siteID);
	}
	return false;
};

export const isEmptyString = (str: string): boolean => {
	return str ? str.length === 0 : true;
};

export const getDuration = (durationSecs: number): string => {
	let result = '';
	if (isNaN(durationSecs)) {
		return '-';
	}
	if (durationSecs < 1) {
		return '0 s';
	}
	const days = Math.floor(durationSecs / (3600 * 24));
	durationSecs -= days * 3600 * 24;
	const hours = Math.floor(durationSecs / 3600);
	durationSecs -= hours * 3600;
	const minutes = Math.floor(durationSecs / 60);
	const seconds = Math.floor(durationSecs - (minutes * 60));
	if (days !== 0) {
		result += `${days}d `;
	}
	if (((hours !== 0) || (days !== 0)) && (hours !== 0 || (minutes !== 0 && days === 0))) {
		result += `${hours}h `;
	}
	if (days === 0) {
		if ((minutes !== 0) || (hours !== 0) && (minutes !== 0 || (seconds !== 0 && hours === 0))) {
			result += `${minutes}m `;
		}
		if ((hours === 0) && (seconds !== 0)) {
			result += `${seconds}s `;
		}
	}
	return result;
};

export const getDurationInHours = (durationSecs: number): string => {
    let result = '';

    if (isNaN(durationSecs)) {
        return '-';
    }

    if (durationSecs < 1) {
        return '0s';
    }

    const days = Math.floor(durationSecs / (3600 * 24));
    durationSecs -= days * 3600 * 24;

    const hours = Math.floor(durationSecs / 3600);
    durationSecs -= hours * 3600;

    // const minutes = Math.floor(durationSecs / 60);
    // const seconds = Math.floor(durationSecs - minutes * 60);

    if (days !== 0) {
        result += `${days}d `;
    }

    if (hours !== 0) {
        result += `${hours}h `;
    }

    // if ((minutes !== 0) || (days !== 0 && hours !== 0 && seconds !== 0)) {
    //     result += `${minutes}m `;
    // }

    // if (days === 0 && hours === 0 && seconds !== 0) {
    //     result += `${seconds}s `;
    // }

    return result.trim();
};


export const convertToFloat = (value: any): number => {
	let changedValue: number = value;
	if (!value) {
		return 0;
	}
	if (typeof value === 'string') {
		// Create Object
		changedValue = parseFloat(value);
	}
	return changedValue;
};

export const getpercentage = (value: number, fixTo: number = 2): string => {
	return parseFloat((value * 100).toFixed(fixTo)) + '%';
};

export const getCurrencyCode = (): string => {
	// The [ISO 4217] currency code as defined in the Pricing Settings
	// N.B.: An empty string is returned when not yet set!
	const authContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	return (authContext.userInfo.currency) ? authContext.userInfo.currency : '';
};

export const getConnectorLetterFromConnectorID = (connectorId: number): string => {
	return String.fromCharCode(65 + connectorId - 1);
};

const objectHasProperty = (object: any, key: string): boolean => {
	return Object.prototype.hasOwnProperty.call(object, key) as boolean;
};

export const getVisibleTableColumnDefs = (tableColumnsDef: columnsType[], projectFields: string[] | undefined): columnsType[] => {
	if (projectFields?.length) {
		projectFields = projectFields.map(projectedField =>
			(projectedField.split('.')[0] === 'createdBy' || projectedField.split('.')[0] === 'lastChangedBy') ?
				projectedField.split('.')[0] : projectedField
		);
		for (const tableColumnDef of tableColumnsDef) {
			if (projectFields.includes(tableColumnDef.projectField + '') || tableColumnDef.projectField === 'action')
				tableColumnDef.visible = true;
			else tableColumnDef.visible = false;
		}
		// No projected fields, display all
	}
	else {
		for (const tableColumnDef of tableColumnsDef) {
			// if prop is not provided
			if (!objectHasProperty(tableColumnDef, 'visible'))
				tableColumnDef.visible = true;
		}
	}

	return tableColumnsDef.filter(column => column.visible);
};

export const formatTextToHTML = (value: any): string => {
	if (Array.isArray(value)) {
		for (let i = 0; i < value.length; i++) {
			// Format
			value[i] = internalFormatTextToHTML(value[i]);
		}
	} else {
		// Format
		value = internalFormatTextToHTML(value);
	}
	// Hack: Replace <anonymous> tag which is part of some Stack Trace to avoid hiding of the end of the detailed message!!!
	return value.replace('<anonymous>', 'anonymous');
};

const internalFormatTextToHTML = (value: any): string => {
	// JSON?
	if (typeof value === 'object') {
		// Check that every values is parsed
		return internalFormatTextToHTML(JSON.stringify(value));
		// String?
	} else if (typeof value === 'string') {
		let parsedValue: string;
		try {
			// Try to parse and format it
			parsedValue = JSON.stringify(JSON.parse(value), null, 6);
			// Ok: Format
			parsedValue = parsedValue
				.replace(/</g, '&lt')
				.replace(/>/g, '&gt')
				.replace(/\n/g, '<br/>')
				.replace(/\\n\s/g, '<br/>')
				.replace(/\s/g, '&nbsp;');
		} catch (err) {
			// Err: Apply default formatting
			parsedValue = value.replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>');
		}
		// Replace
		return parsedValue;
	} else {
		// Unknown
		return value + '';
	}
};
const monthLabel = 'month';
const unitLabel = 'unit';

export const buildStackedChartDataForMonths = (statisticsData: any[], roundingDecimals: number = 0, totalLabel?: any, addUnitToLabel = false, sortedBy: 'label-asc' | 'label-desc' | 'size-asc' | 'size-desc' = 'size-desc') => {
	const stackedChartData: any = { labels: [], datasets: [] };
	let roundingFactor = 1;
	let monthString = '';
	let dataSetIndex = 0;
	let monthIndex = 0;
	let countMonths = 0;
	let newKey = '';
	let numberArray: any = [];
	let sum = 0;
	const totalDataArray: number[] = [];
	const transactionValues = statisticsData;
	const barGraphAnimationConfig = {
		animation: {
			animateRotate: true,
			duration: 2000,
			easing: 'easeOutBounce',
		}
	};
	const datasets: any[] = [];
	if (roundingDecimals !== 0) {
		if (roundingDecimals > 0) {
			for (let i = 0; i < roundingDecimals; i++) {
				roundingFactor *= 10;
			}
		} else {
			for (let i = roundingDecimals; i < 0; i++) {
				roundingFactor /= 10;
			}
		}
	}
	if (!isEmptyArray(transactionValues)) {
		const labels: string[] = [];
		// eslint-disable-next-line complexity
		transactionValues.forEach((transactionValue) => {
			// for each month (sorted from 0 to 11, but attention, multiple month values are possible if multiple units!):
			let totalValuePerMonth = 0;
			let newMonth = false;
			monthIndex = transactionValue.month;
			const userInfo: any = localStorage.getItem('user_info');
			const lang = JSON.parse(userInfo);
			dayjs.locale(lang.language); // Set the desired locale
			monthString = dayjs().month(monthIndex).format('MMMM');
			const currentIndex = labels ? labels.indexOf(monthString) : -1;
			if (currentIndex < 0 && labels) {
				countMonths++;
				newMonth = true;
				labels.push(monthString);
			}
			// now for all items:
			for (const key in transactionValue) {
				if ((key !== monthLabel) &&
					(key !== unitLabel)) {
					// Round
					transactionValue[key] *= roundingFactor;
					transactionValue[key] = Math.round(transactionValue[key]);
					transactionValue[key] /= roundingFactor;
					if (transactionValue[key] && transactionValue[key] !== 0) {
						if (addUnitToLabel && (unitLabel in transactionValue)) {
							newKey = key + ` [${transactionValue[unitLabel]}]`;
						} else {
							newKey = key;
						}
						dataSetIndex = datasets.findIndex((dataset) => dataset['label'] === newKey);
						if (dataSetIndex < 0 && datasets) {
							numberArray = [];
							for (let i = 1; i < countMonths; i++) {
								// add leading zeros for previous months without activity
								numberArray.push(0);
							}
							numberArray.push(transactionValue[key]);
							datasets.push({ label: newKey, data: numberArray, stack: 'item', ...barGraphAnimationConfig });
						} else {
							numberArray = datasets[dataSetIndex].data;
							if (newMonth) {
								numberArray.push(transactionValue[key]);
							} else {
								let monthlyNumber = numberArray[countMonths - 1];
								if (typeof (monthlyNumber) === 'number') {
									monthlyNumber += transactionValue[key];
									numberArray[countMonths - 1] = monthlyNumber;
								}
							}
							datasets[dataSetIndex].data = numberArray;
						}
						totalValuePerMonth += transactionValue[key];
					}
				}
			}
			// add trailing zero if no activity in the current month:
			datasets.forEach((dataset) => {
				if (dataset.stack !== 'total') {
					numberArray = dataset.data;
					if (numberArray.length < countMonths) {
						for (let i = numberArray.length; i < countMonths; i++) {
							numberArray.push(0);
						}
						dataset.data = numberArray;
					}
				}
			});
			// Deferred update for totals:
			if (newMonth) {
				totalDataArray.push(totalValuePerMonth);
			} else {
				let totalNumber = totalDataArray[currentIndex];
				if (typeof (totalNumber) === 'number') {
					totalNumber += totalValuePerMonth;
					totalDataArray[currentIndex] = totalNumber;
				}
			}
		});
		stackedChartData.labels = labels;
		// sort datasets:
		if (sortedBy.startsWith('size-')) {
			// add totals at index position 0, to be used for sorting:
			datasets.forEach((dataset) => {
				numberArray = dataset.data;
				sum = 0;
				numberArray.forEach((numberItem) => {
					if (typeof (numberItem) === 'number') {
						sum += numberItem;
					}
				});
				numberArray.unshift(sum);
				dataset.data = numberArray;
			});
		}
		datasets.sort((dataset1, dataset2) => {
			switch (sortedBy) {
				case 'label-asc':
					if (dataset1.label < dataset2.label) {
						return -1;
					} else {
						if (dataset1.label > dataset2.label) {
							return 1;
						} else {
							return 0;
						}
					}
				case 'label-desc':
					if (dataset1.label > dataset2.label) {
						return -1;
					} else {
						if (dataset1.label < dataset2.label) {
							return 1;
						} else {
							return 0;
						}
					}
				case 'size-asc':
					if (dataset1.data[0] < dataset2.data[0]) {
						return -1;
					} else {
						if (dataset1.data[0] > dataset2.data[0]) {
							return 1;
						} else {
							return 0;
						}
					}
				case 'size-desc':
					if (dataset1.data[0] > dataset2.data[0]) {
						return -1;
					} else {
						if (dataset1.data[0] < dataset2.data[0]) {
							return 1;
						} else {
							return 0;
						}
					}
			}
		});
		if (sortedBy.startsWith('size-')) {
			// remove calculated totals again:
			datasets.forEach((dataset) => {
				dataset.data.splice(0, 1);
			});
		}
		// Last chart dataset for totals:
		datasets.push({ label: totalLabel, data: totalDataArray, stack: 'total', ...barGraphAnimationConfig });
	}
	stackedChartData.datasets = datasets;
	return stackedChartData;
};

export const calculateTotalValueFromChartData = (chartData: any) => {
	let totalValue = 0;
	let dataSetIndex = 0;
	let numberArray = [];
	if (chartData.datasets) {
		// is the chart a stacked chart (with totals)?
		dataSetIndex = chartData.datasets.findIndex((dataset) => dataset.stack === 'total');
		if (dataSetIndex < 0) {
			// no, it is a simple chart
			if (!isEmptyArray(chartData.datasets)) {
				numberArray = chartData.datasets[0].data;
				if (Array.isArray(numberArray)) {
					numberArray.forEach((numberValue) => {
						if (typeof (numberValue) === 'number') {
							totalValue += numberValue;
						}
					});
				}
			}
		} else {
			// yes, it is a stacked chart with totals
			numberArray = chartData.datasets[dataSetIndex].data;
			if (Array.isArray(numberArray)) {
				numberArray.forEach((numberValue) => {
					if (typeof (numberValue) === 'number') {
						totalValue += numberValue;
					}
				});
			}
		}
	}
	return totalValue;
};

export const calculateTotalChartDataFromStackedChartData = (stackedChartData: any) => {
	const totalChartData: any = { labels: [], datasets: [] };
	let totalValue = 0;
	let numberArray: any = [];
	if (stackedChartData.datasets && totalChartData.labels) {
		stackedChartData.datasets.forEach((dataset) => {
			if (dataset.stack !== 'total') {
				const labels: string[] = totalChartData.labels as string[];
				const datasets: any[] = totalChartData.datasets as any[];
				labels.push(dataset.label);
				totalValue = 0;
				numberArray = dataset.data;
				if (Array.isArray(numberArray)) {
					numberArray.forEach((numberValue) => {
						if (typeof (numberValue) === 'number') {
							totalValue += numberValue;
						}
					});
				}
				if (isEmptyArray(datasets)) {
					numberArray = [];
					numberArray.push(totalValue);
					datasets.push({ data: numberArray }); // no 'label, no 'stack'
				} else {
					numberArray = datasets[0].data;
					numberArray.push(totalValue);
					datasets[0].data = numberArray;
				}
				totalChartData.labels = labels;
				totalChartData.datasets = datasets;
			}
		});
	}
	return totalChartData;
};

export const getColorCode = (counter: number) => {
	const colors = [
		[144, 238, 144, 0.8],
		[255, 165, 0, 0.5],
		[135, 206, 235, 0.8],
		[222, 184, 135, 0.5],
		[255, 182, 193, 0.8],
		[102, 205, 170, 0.5],
		[255, 160, 122, 0.8],
		[70, 130, 180, 0.5],
		[255, 222, 173, 0.8],
		[218, 112, 214, 0.5],
		[144, 238, 144, 0.5],
		[255, 165, 0, 0.8],
		[135, 206, 235, 0.5],
		[222, 184, 135, 0.8],
		[255, 182, 193, 0.5],
		[102, 205, 170, 0.8],
		[255, 160, 122, 0.5],
		[70, 130, 180, 0.8],
		[255, 222, 173, 0.5],
		[218, 112, 214, 0.8],
	];
	const div20 = counter % 20;
	return `rgba(${colors[div20][0]}, ${colors[div20][1]}, ${colors[div20][2]}, ${colors[div20][3]})`;
};

export const updateChartData = (data: any, graphType: ChartTypeValues) => {
	let countData = 0;
	const backgroundColor: any = [];
	const chartData = data;
	if (!chartData.labels) {
		chartData.labels = [];
	}
	if (!chartData.datasets) {
		chartData.datasets = [];
	}
	if (graphType === ChartTypeValues.BAR) {
		chartData.datasets.forEach((dataset) => {
			if (dataset.stack === 'total') {
				dataset.hidden = false;
				dataset.backgroundColor = 'lightgrey';
			} else {
				dataset.stack = 'item'; // to be sure
				dataset.hidden = false;
				dataset.backgroundColor = getColorCode(countData);
				countData++;
			}
		});
	} else {
		chartData.datasets.forEach((dataset) => {
			for (let i = 0; i < dataset.data.length; i++) {
				backgroundColor.push(getColorCode(i));
			}
		});
	}
	return backgroundColor;
};

export const canReadTransaction = (siteArea: any, badgeID: string, loggedUser: IUserProfileModel, isUserAdmin: any, isDemoUser: any, isActiveOrg: any) => {
	if (canAccess(Entity.TRANSACTION, Action.READ)) {
		if (!!loggedUser && !!loggedUser.tagIDs && loggedUser.tagIDs.includes(badgeID)) {
			return true;
		}
		if (isActiveOrg && siteArea) {
			return isUserAdmin || isSiteAdmin(siteArea.siteID, loggedUser) || (isDemoUser && isSiteUser(siteArea.siteID, loggedUser));
		}
		return isUserAdmin || isDemoUser;
	}
	return false;
};

export const getSitesAdmin = (loggedUser: IUserProfileModel): readonly string[] => {
	return !!loggedUser && loggedUser.sitesAdmin ? loggedUser.sitesAdmin : [];
};

export const getGraphLabelVisibility = (graphData: any, stackedChart: boolean | undefined, graphType: ChartTypeValues): number => {
	let minValue = 0;
	const minDivisor = graphType === 'bar' ? constMinDivisorBar : constMinDivisorPie;
	if (stackedChart) {
		minValue = 0;
		graphData.datasets.forEach((dataset) => {
			if (Array.isArray(dataset.data) === true &&
				dataset.stack === ChartConstants.STACKED_TOTAL) {
				for (const data of dataset.data) {
					if (typeof (data) === 'number' && data > minValue) {
						minValue = data;
					}
				}
			}
		});
	} else {
		minValue = 0;
		graphData.datasets.forEach((dataset) => {
			if (Array.isArray(dataset.data) === true) {
				for (const data of dataset.data) {
					if (typeof (data) === 'number') {
						minValue = minValue + data;
					}
				}
			}
		});
	}

	return minValue / minDivisor;
};

export const isInMobileApp = (subDomain: string): boolean => {
	return getMobileVendor() !== null && subDomain !== 'ezcharge';
};

export const getMobileVendor = (): MobileType | null => {
	const userAgent: string = navigator.userAgent as string || navigator.vendor as string || window['opera'] as string;
	if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
		return MobileType.IOS;
	} else if (userAgent.match(/Android/i)) {
		return MobileType.ANDROID;
	}
	return null;
};

export const buildMobileAppDeepLink = (path: string) => {
	const mobileVendor = getMobileVendor();

	switch (mobileVendor) {
		case MobileType.IOS:
			return `eMobility://${path}`;
		case MobileType.ANDROID:
			return `intent://${path}#Intent;scheme=eMobility;package=com.emobility;end`;
	}
};

export const handleCanvasNoData = (chart, transaction: (string) => string) => {
	if (chart?.data) {

		if (chart.data.datasets.every(item => item.data.length === 0)) {
			const ctx = chart.ctx;
			const width = chart.width;
			const height = chart.height;

			chart.clear();
			ctx.save();
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(transaction('organization.graph.no_consumption'), width / 2, height / 2);
			ctx.restore();
		}
	}
};

export const appCurrencyPipe = (price?: number, currencyCode?: string) => {
	const currency = currencyCode || store.getState().userContext.userInfo.currency || DEFAULT_CURRENCY_CODE;
	if (!price) price = Number(0);

	return price.toLocaleString((languageLocale[i18next.language] || 'en-EN'), {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const containsGPSCoordinates = (coordinates: any) => {
	// Check if GPs are available
	if (coordinates && coordinates.length === 2 && coordinates[0] && coordinates[1]) {
		// Check Longitude & Latitude
		const latitude = new RegExp(/^-?([1-8]?[1-9]|[1-9]0)\.{0,1}[0-9]*$/);
		const longitude = new RegExp(/^-?([1]?[0-7][0-9]|[1]?[0-8][0]|[1-9]?[0-9])\.{0,1}[0-9]*$/);
		if (longitude.test(coordinates[0].toString()) && latitude.test(coordinates[1].toString())) {
			return true;
		}
	}
	return false;
};
export const getCalendarLocale = (): Locale => {
	const userLang = store.getState().userContext.userInfo.language?.toString() || 'en';
	return (
		{ cs: cs, fr: fr, es: es, de: de, pt: pt, it: it, en: en }[userLang] || en
	);
};

export const timeAgo = (date: any) => {
	const now: any = new Date();
	const timestamp: any = new Date(date);
	const seconds = Math.floor((now - timestamp) / 1000);
	const years = Math.floor(seconds / 31536000);
	const months = Math.floor((seconds % 31536000) / 2592000);
	const weeks = Math.floor((seconds % 2592000) / 604800);
	const days = Math.floor((seconds % 604800) / 86400);
	const hours = Math.floor((seconds % 86400) / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;
		if (years === 1) {
			return 'a year ago';
		} else if (years > 1) {
			return `${years} years ago`;
		} else if (months === 1) {
			return 'a month ago';
		} else if (months > 1) {
			return `${months} months ago`;
		} else if (weeks === 1) {
			return 'a week ago';
		} else if (weeks > 1) {
			return `${weeks} weeks ago`;
		} else if (days === 1) {
			return 'a day ago';
		} else if (days > 1) {
			return `${days} days ago`;
		} else if (hours === 1) {
			return 'a hour ago';
		} else if (hours > 1) {
			return `${hours} hours ago`;
		} else if (minutes === 1) {
			return 'a minute ago';
		} else if (minutes > 1) {
			return `${minutes} minutes ago`;
		} else if (remainingSeconds === 1) {
			return 'a second ago';
		} else if (remainingSeconds > 1) {
			return 'a few seconds ago';
		}
  
	
  };

  export const amenitiesMapping = {
	Dining: 'isDiningAvailable',
	Restroom: 'isRestroomAvailable',
	Shopping: 'isShoppingAvailable',
	Lodging: 'isLodgingAvailable',
	Park: 'isParkAvailable',
	Grocery: 'isGroceryAvailable',
	WiFi: 'isWifiAvailable',
	Valet: 'isValetAvailable',
	'Free Charging': 'isFreeChargingAvailable',
	Restaurant: 'isRestaurantAvailable',
	Store: 'isStoreAvailable',
	'24 Hours': 'is2hHoursOpen',
	Reservation: 'isReservationAvailable',
	Hospital: 'isHospitalAvailable',
	Parking: 'isParkingAvailable'
  };
  
  
