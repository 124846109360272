import {
   IContextPropsModel,
   IFormPropsModel,
} from '@/common/utils/withContext';

type Subset<K> = {
   [attr in keyof K]?: K[attr] extends object
      ? Subset<K[attr]>
      : K[attr] extends object | null
      ? Subset<K[attr]> | null
      : K[attr] extends object | null | undefined
      ? Subset<K[attr]> | null | undefined
      : K[attr];
};

export interface IPriceListPropsModel extends IContextPropsModel {
   siteId: string;
   siteName: string;
   showModal: boolean;
   onClose: () => void;
   defaultSortColumn?: string;
   entityType: string;
   isModal?: boolean;
   listHeight?: number | string;
   chargingSiteId?: string;
}
export interface IPriceListStateModel {
   limit: number;
   sortDescending: boolean;
   sortFields: string;
   rows: Record<string, any>[];
   totalRowsCount: number;
   defaultSortColumn?: string;
   isPriceAddModalOpen: boolean;
   selectedPriceId: string;
   canCreate: boolean;
   projectFields: string[];
   siteRows: Record<string, any>[];
   tenantRows: Record<string, any>[];
   totalSiteRows: number;
   totalTenantRows: number;
   page:number;
}

export interface IPriceFormPropsModel
   extends IContextPropsModel,
      IFormPropsModel<PriceFormModel> {
   handleClose: (flag: boolean) => void;
   resetPriceform: (value: string) => void;
   show: boolean;
   entityID: string;
   selectedPriceId: string;
   siteName: string;
   entityType: string;
}

export class PriceFormModel {
   name: string = '';
   description: string = '';
   entityID: string = '';
   entityType: string = '';
   dimensions?: DimensionFormModel;
   restrictions?: RestrictionFormModel;
   staticRestrictions?: StaticRestrictionFormModel;

   constructor() {
      this.dimensions = { ...new DimensionFormModel() };
      this.restrictions = { ...new RestrictionFormModel() };
      this.staticRestrictions = { ...new StaticRestrictionFormModel() };
   }

   static buildModel(data: PriceFormModel) {
      const model: Subset<PriceFormModel> = {
         ...data,
         dimensions: DimensionFormModel.buildModel(data.dimensions),
         restrictions: RestrictionFormModel.buildModel(data.restrictions),
         staticRestrictions: StaticRestrictionFormModel.buildModel(
            data.staticRestrictions
         ),
      };
      return model;
   }
}

export class DimensionFormModel {
   flatFee?: {
      active: boolean;
      price?: string;
   } = {
      active: false,
      price: '',
   };

   energy?: {
      active: boolean;
      price?: string;
      stepSizeEnabled: boolean;
      stepSize?: string;
   } = {
      active: false,
      price: '',
      stepSize: '',
      stepSizeEnabled: false,
   };

   chargingTime?: {
      active: boolean;
      price?: string;
      stepSizeEnabled: boolean;
      stepSize?: string;
   } = {
      active: false,
      price: '',
      stepSizeEnabled: false,
      stepSize: '',
   };

   parkingTime?: {
      active: boolean;
      price?: string;
      stepSize?: string;
      stepSizeEnabled: boolean;
   } = {
      active: false,
      price: '',
      stepSize: '',
      stepSizeEnabled: false,
   };

   static toSeconds(minutes: any) {
      if (minutes == null) {
         return minutes;
      }
      const seconds = (parseInt(minutes) * 60).toString();
      return seconds;
   }
   static toMinutes(value?: string) {
      if (!value) {
         return value;
      }
      return (parseInt(value) / 60).toString();
   }
   static buildModel(data?: DimensionFormModel) {
      if (!data) return null;
      const model: Subset<DimensionFormModel> = {};
      (
         ['flatFee', 'energy', 'chargingTime', 'parkingTime'] as Array<
            keyof DimensionFormModel
         >
      ).forEach((prop) => {
         if (data[prop]?.active) {
            model[prop] = {
               active: true,
               price: data[prop]?.price,
            };
            if (prop === 'energy' && data[prop]?.stepSizeEnabled) {
               model[prop] = { ...model[prop], stepSize: data[prop]?.stepSize };
            } else if (
               prop !== 'flatFee' &&
               prop !== 'energy' &&
               data[prop]?.stepSizeEnabled
            ) {
               model[prop] = {
                  ...model[prop],
                  stepSize: data[prop]
                     ? this.toSeconds(data[prop]?.stepSize)
                     : data[prop]?.stepSize,
               };
            }
         }
      });
      return model;
   }

   static restoreModel(data: DimensionFormModel) {
      const model: DimensionFormModel = {
         ...new DimensionFormModel(),
         ...data,
      };
      (
         ['flatFee', 'energy', 'chargingTime', 'parkingTime'] as Array<
            keyof DimensionFormModel
         >
      ).forEach((prop) => {
         let params: any = {};
         if (model[prop]?.price) {
            params = { active: true };
         }
         if (prop === 'energy') {
            const stepSize = model[prop]?.stepSize ?? '';
            params = { ...params, stepSizeEnabled: !!stepSize, stepSize };
         } else if (prop === 'chargingTime' || prop === 'parkingTime') {
            const stepSize =
               DimensionFormModel.toMinutes(model[prop]?.stepSize) ?? '';
            params = { ...params, stepSizeEnabled: !!stepSize, stepSize };
         }
         model[prop] = { ...model[prop], ...params };
      });
      return model;
   }
}

export class RestrictionFormModel {
   daysOfWeekEnabled: boolean = false;
   daysOfWeek?: number[] = [];
   timeRangeEnabled: boolean = false;
   timeFrom?: string = '';
   timeTo?: string = '';
   minEnergyKWhEnabled: boolean = false;
   minEnergyKWh?: string = '';
   maxEnergyKWhEnabled: boolean = false;
   maxEnergyKWh?: string = '';
   minDurationSecsEnabled: boolean = false;
   minDurationSecs?: string = '';
   maxDurationSecsEnabled: boolean = false;
   maxDurationSecs?: string = '';

   static buildModel(data?: RestrictionFormModel) {
      if (!data) return null;
      let model: Subset<RestrictionFormModel> = {};
      (
         [
            'daysOfWeek',
            'minEnergyKWh',
            'maxEnergyKWh',
            'minDurationSecs',
            'maxDurationSecs',
         ] as Array<keyof RestrictionFormModel>
      ).forEach((prop) => {
         const isPropEnabled = `${prop}Enabled`;
         if (data[isPropEnabled as keyof RestrictionFormModel] === true) {
            model = { ...model, [prop]: data[prop] };
         }
      });

      if (data.timeRangeEnabled) {
         model.timeFrom = data.timeFrom;
         model.timeTo = data.timeTo;
      }
      if (data.maxDurationSecsEnabled && data.maxDurationSecs != null) {
         model.maxDurationSecs = (
            parseInt(data.maxDurationSecs) * 60
         ).toString();
      }
      if (data.minDurationSecsEnabled && data.minDurationSecs != null) {
         model.minDurationSecs = (
            parseInt(data.minDurationSecs) * 60
         ).toString();
      }
      return model;
   }

   static restoreModel(data?: RestrictionFormModel) {
      let model = { ...new RestrictionFormModel(), ...data };
      (
         [
            'daysOfWeek',
            'minEnergyKWh',
            'maxEnergyKWh',
            'minDurationSecs',
            'maxDurationSecs',
         ] as Array<keyof RestrictionFormModel>
      ).forEach((prop) => {
         const isPropEnabled = `${prop}Enabled`;
         if (prop === 'daysOfWeek') {
            model = {
               ...model,
               [isPropEnabled]: (model.daysOfWeek ?? []).length > 0,
            };
         } else if (model[prop]) {
            model = { ...model, [isPropEnabled]: true };
         }
      });
      if (model.timeFrom || model.timeTo) {
         model.timeRangeEnabled = true;
      }
      if (model.maxDurationSecs != null && model.maxDurationSecs != '') {
         model.maxDurationSecs = (
            parseInt(model.maxDurationSecs) / 60
         ).toString();
      }
      if (model.minDurationSecs != null && model.minDurationSecs != '') {
         model.minDurationSecs = (
            parseInt(model.minDurationSecs) / 60
         ).toString();
      }
      return model;
   }
}

export class StaticRestrictionFormModel {
   validFrom?: string = '';
   validTo?: string = '';
   connectorType?: string = '';
   connectorPowerkW?: string = '';
   connectorPowerEnabled: boolean = false;

   static buildModel(data?: StaticRestrictionFormModel) {
      if (!data) return null;
      const model: Subset<StaticRestrictionFormModel> = {};
      if (data.validFrom) {
         model.validFrom = data.validFrom;
      }
      if (data.validTo) {
         model.validTo = data.validTo;
      }
      if (data.connectorType) {
         model.connectorType = data.connectorType;
      }
      if (data.connectorPowerEnabled) {
         model.connectorPowerkW = data.connectorPowerkW;
      }
      return model;
   }

   static restoreModel(data: StaticRestrictionFormModel) {
      const model = { ...new StaticRestrictionFormModel(), ...data };
      if (model.connectorPowerkW) {
         model.connectorPowerEnabled = true;
      }
      return model;
   }
}
