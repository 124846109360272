import { useEffect, useState } from 'react';
import { BluetoothDrive, Portrait } from '@mui/icons-material';
import {
   withContext,
   withUseFormHook,
} from '@/common/utils/withContext';
import {
   ISiteAreaFormStateModel,
} from '@/modules/organization/shared/models/siteArea';
import { formSchema } from '../../../../shared/schemas/electricVehiclesForm';
import { UseFormReturn } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { DefaultPageSize } from '@/common/constants/constants';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import { NotifyType } from '@/common/utils/notificationService';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EvConnector from './evConnector';
import { addElectricVehicle, getElectricVehicleById, updateElectricVehicle } from '@/services/cars';
import { IElectricVehicleModel, ElectricVehicleModel } from '@/modules/car/shared/models/electricVehicle';
import EvForm from './evForm';

function CreateElectricForm(props: IElectricVehicleModel) {
   const electricVehicleForm = props.formService as UseFormReturn<
      ElectricVehicleModel,
      any
   >;
   const isFormDirty = electricVehicleForm.formState.isDirty;
   const isSaveEnabled = electricVehicleForm.formState.isValid && isFormDirty;
   const evCatalog = electricVehicleForm.watch('evCatalog');
   const { t: translate } = useTranslation();

   const [state, setStateData] = useState({
      selectedTab: '1',
      pageLimit: DefaultPageSize,
      isDisabled: false,
   } as ISiteAreaFormStateModel);

   const notify: NotifyType = {
      message: '',
      type: 'success',
   };

   useEffect(() => {
      loadSiteArea();
   }, []);

   const loadSiteArea = async () => {
      if (props.electricVehicleId) {
         getElectricVehicle();
      }
   };

   const { refetch: getElectricVehicle } = useQuery(
      ['electricVehicle', props.electricVehicleId],
      () => {
         return getElectricVehicleById(props.electricVehicleId);
      },
      {
         enabled: false,
         onSuccess: (response: any) => {
            electricVehicleForm.reset({
               evCatalog:`${response?.carCatalog?.vehicleMake} ${response?.carCatalog?.vehicleModel} ${response?.carCatalog?.vehicleModelVersion}`,
               vehicleMake:`${response?.carCatalog?.vehicleMake}`,
               id:response.id,
               converter:`${response?.converter?.amperagePerPhase} kW - ${response?.converter?.numberOfPhases} Phase(s) -  ${response?.converter?.powerWatts} A`,
               vin:response?.vin,
               licensePlate:response?.licensePlate,
               user:response?.user ? `${response?.user?.firstName} ${response?.user?.name }` : '',
               defaultEv:response.default,
               evCatalogId:response.carCatalogID,
               image:response?.carCatalog?.image,
               type:response.type,
               userId:response?.userID || '',
               amperagePerPhase:response?.converter?.amperagePerPhase,
               numberOfPhases:response?.converter?.numberOfPhases,
               powerWatts:response?.converter?.powerWatts,
               converterType:response?.converter?.type,
               connectorMeterId:response.carConnectorData?.carConnectorMeterID,
               connectionName:response.carConnectorData?.carConnectorID,
            });
         },
      }
   );

   const { mutate: saveElectricVehicle } = useMutation(
      (electricVehicleFormData) => addElectricVehicle(electricVehicleFormData),
      {
         onSuccess: (data: any) => {
            if (data.status === 'Success') {
               props.onFormClose();
               props.resetElectricVehicleForm('');
               notify.message = `${translate(
                  'site_areas.create_success'
               ).replace(/{{siteAreaName}}/, `'${evCatalog}'`)}`;
               notify.type = 'success';
               props.notificationService?.notify(notify);
               props.fetchAllElectricVehicles();
            } else if (data.status === 404) {
               notify.message = `${translate(
                  'site_areas.site_area_does_not_exist'
               )}`;
               notify.type = 'error';
               props.notificationService?.notify(notify);
               props.onFormClose();
               props.resetElectricVehicleForm('');
               props.notificationService?.notify(notify);
            } else {
               // handleHttpTreeError(
               //    data,
               //    `${translate('site_areas.create_error')}`
               // );
            }
         },
      }
   );

   const { mutate: editElectricVehicle } = useMutation(
      (electricVehicleFormData) => updateElectricVehicle(electricVehicleFormData, props.electricVehicleId),
      {
         onSuccess: (data: any) => {
            if (data.status === 'Success') {
               props.onFormClose();
               props.resetElectricVehicleForm('');
               notify.message = `${translate(
                  'cars.update_success'
               ).replace(/{{carName}}/, `'${evCatalog}'`)}`;
               notify.type = 'success';
               props.notificationService?.notify(notify);
               props.fetchAllElectricVehicles();
            } else if (data.status === 404) {
               notify.message = `${translate(
                  'cars.update_error'
               )}`;
               notify.type = 'error';
               props.notificationService?.notify(notify);
               props.onFormClose();
               props.resetElectricVehicleForm('');
               props.notificationService?.notify(notify);
            } else {
               // handleHttpTreeError(
               //    data,
               //    `${translate('site_areas.update_error')}`
               // );
            }
         },
      }
   );

   const onFormSubmit = (formData: ElectricVehicleModel) => {
      const dataToSend: any = {
         carCatalog: formData.evCatalog,
         carCatalogID: formData.evCatalogId,
         id: formData.id,
         licensePlate: formData.licensePlate,
         type: formData.type,
         user: formData.user,
         userID: formData.userId,
         vin: formData.vin,
         forced: false,
         default: formData.defaultEv,
         converterType: formData.converterType,
         carConnectorData: {
            carConnectorID: formData.connectionName,
            carConnectorMeterID: formData.connectorMeterId
         },
         converter: {
            amperagePerPhase: formData.amperagePerPhase,
            numberOfPhases: formData.numberOfPhases,
            powerWatts: formData.powerWatts,
            type: formData.converterType
         }
      };

      if (props.electricVehicleId) {
         editElectricVehicle({ ...dataToSend as any });
      } else {
         saveElectricVehicle(dataToSend);
      }
   };


   const onHandelClose = () => {
      if (!!Object.keys(electricVehicleForm.formState?.dirtyFields).length === true) {
         //based on the formValidity confirmation Dialogtype changes
         if (electricVehicleForm.formState.isValid) {
            props.dialogService?.showConfirm(
               {
                  confirmType: 'DIRTY_CHANGE_CLOSE',
               },
               (result) => {
                  if (result === 'SAVE') {
                     electricVehicleForm.handleSubmit(onFormSubmit)();
                  } else if (result === 'CLOSE') {
                     props.onFormClose();
                     props.resetElectricVehicleForm('');
                  }
               }
            );
         } else {
            props.dialogService?.showConfirm(
               {
                  confirmType: 'INVALID_CHANGE_CLOSE',
               },
               (result: string) => {
                  if (result === 'CLOSE') {
                     props.onFormClose();
                     props.resetElectricVehicleForm('');
                  }
               }
            );
         }
      } else {
         props.onFormClose();
         props.resetElectricVehicleForm('');
      }
   };

   const tabsConfig = [
      {
         name: `${translate('cars.ev')} - ${evCatalog}`,
         icon: <Portrait />,
         component: (
            <EvForm
               electricVehicleForm={electricVehicleForm as any}
               pageLimit={state.pageLimit}
               isDisabled={state.isDisabled}
            />
         ),
         visible: true,
      },
      {
         name: `${translate('cars.ev_connector')}`,
         icon: <BluetoothDrive />,
         component: (
            <EvConnector
               siteAreaForm={electricVehicleForm as any}
               isDisabled={state.isDisabled}
            />
         ),
         visible: true,
      }
   ];

   return (
      <>
         <EvesModal
            maxHeight={'673px'}
            maxWidth={'950px'}
            isOpen={props.openElectricVehicle}
            onHandleSave={electricVehicleForm.handleSubmit(onFormSubmit)}
            onHandleClose={onHandelClose}
            modalType='tabs'
            isSaveDisabled={!isSaveEnabled}
            modalTabSelected={state.selectedTab}
            tabsConfig={tabsConfig}
            isSaveHidden={state.isDisabled || !props.canCreate}
            scrollButtons={'auto'}
         ></EvesModal>
      </>
   );
}

const mapStateToProps = (state: any) => ({
   userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
   withContext(
      withUseFormHook(CreateElectricForm, {
         schema: formSchema,
         defaultValues: { ...new ElectricVehicleModel() },
      })
   )
);
