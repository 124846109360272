import {
  CloudUpload,
  CloudDownload,
  Cancel,
  Add,
  Sync,
  LocalAtm,
  OpenInNew,
} from '@mui/icons-material';
import { ReactComponent as DemandGraph } from '../../../../assets/img/gridify/demandGraph.svg';
import { ReactComponent as Config } from '../../../../assets/img/gridify/config.svg';
import { Grid, Tooltip, Menu, MenuItem } from '@mui/material';
import { EvesButton } from '../../atoms/button/button';
import TableRefreshComponent from '../../organisms/table/refreshComponent';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import sessionfilter from '../../../../assets/img/dashboard/sessionfilter.svg';
import Trash from '../../../../assets/img/sessions/delete.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styles from './buttonGroup.module.scss';
import {ReactComponent as Connector} from '../../../../assets/img/Connectors.svg';
import {ReactComponent as Phase} from '../../../../assets/img/gridify/Phase.svg';
import StopIcon from '@mui/icons-material/Stop';
export default function ButtonGroup({
  showfilter,
  setShowfilter,
  resetFilters,
  state,
  autoRefreshStatus=false,
  onClickCreate,
  isStatistics,
  onClickImport,
  onClickExport,
  onRefresh,
  onClickDelete,
  isUserAdmin,
  onClickRegister,
  isDelete,
  isExport,
  isImport,
  isCreate,
  isRegister,
  isRefund,
  synchronizeRefund,
  authContext,
  UserRole,
  refund,
  handleGraph,
  isOpenURLRefundAction,
  onClickOpenURLRefundAction,
  isRefundTransactionEnabled,
  isAnalyticsComponentActive,
  onConnectChargingClick,
  openAnalyticsList,
  onConnectChargingClose,
  multiple,
  intervalsAndFunctions,
  isDemandGraph,
  isConfig,
  hideLabel = true,
  isPowerManage,
  onClickConnectors,
  showconnector,
  showphase,
  onClickPhase,
  hidePhase,
  handleConfig,
  isSession=false
}: any) {
  const { t: translate } = useTranslation();

  return (
    <>
      <Grid
        item
        display={'flex'}
        justifyContent={'end'}
        className={styles.gaps}
      >
        <Tooltip
          title={`${translate(!showfilter ? 'Filter' : 'Close')}`}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'black',
                borderStyle: 'solid',
                borderWidth: '1px',
              },
            },
          }}
          followCursor={false}
          placement={'bottom-start'}
          enterNextDelay={500}
        >
          <Grid item>
            {!showfilter ? (
              <EvesButton
                isAction={true}
                onClick={() => {
                  setShowfilter(!showfilter);
                }}
                data-cy='show-filter'
              >
                <img src={sessionfilter} />
              </EvesButton>
            ) : (
              <EvesButton
                isAction={true}
                sx={{
                  border: '1px solid #3D3AD3 !important',
                  background: 'rgba(61, 58, 211, 0.10) !important',
                  '&:hover': {
                    background: 'rgba(61, 58, 211, 0.10) !important',
                  },
                }}
                onClick={() => {
                  setShowfilter(!showfilter);
                  resetFilters();
                }}
                data-cy='close-filter'
              >
                <CloseIcon style={{ color: '3D3AD3' }} />
              </EvesButton>
            )}
          </Grid>
        </Tooltip>
        {state.canCreate && isCreate && (
          <Tooltip
            title={`${translate('Create')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <Grid item>
              <EvesButton
                isAction={true}
                onClick={onClickCreate}
                data-cy='rfid-create-button'
              >
                <Add />
              </EvesButton>
            </Grid>
          </Tooltip>
        )}
        {state.canImport && isImport && (
          <Tooltip
            title={`${translate('Upload')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <Grid item>
              <EvesButton
                isAction={true}
                data-cy='rfid-import-button'
                onClick={onClickImport}
              >
                <CloudUpload data-testid='CloudDownloadIcon' />
              </EvesButton>
            </Grid>
          </Tooltip>
        )}

        {state.canExport && isExport && (
          <Tooltip
            title={`${translate('Export')}`}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <Grid item>
              <EvesButton
                isAction={true}
                data-cy='rfid-export-button'
                onClick={onClickExport}
              >
                <CloudDownload data-testid='CloudDownloadIcon' />
              </EvesButton>
            </Grid>
          </Tooltip>
        )}

        {isStatistics && (
          <>
            {state.sacLinksActive && (
              <>
                {isAnalyticsComponentActive && (
                  <Tooltip
                    title={`${translate('Analytics')}`}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          color: 'black',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                        },
                      },
                    }}
                    followCursor={false}
                    placement={'bottom-start'}
                    enterNextDelay={500}
                  >
                    <EvesButton
                      isAction={true}
                      sx={{
                        backgroundColor: '#3D3AD3',
                        ':hover': { backgroundColor: '#3D3AD3' },
                      }}
                      variant='contained'
                      aria-controls='simple-menu'
                      aria-haspopup='true'
                      onClick={onConnectChargingClick}
                    >
                      {' '}
                      <VisibilityIcon />
                    </EvesButton>
                  </Tooltip>
                )}
                <Menu
                  keepMounted
                  anchorEl={openAnalyticsList}
                  onClose={onConnectChargingClose}
                  open={Boolean(openAnalyticsList)}
                >
                  {state.analyticsOptions?.map((option) => {
                    return (
                      <MenuItem
                        className='statics_Menu_item'
                        key={option}
                        onClick={() => {
                          window.open(option.url);
                        }}
                      >
                        {option?.description}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
          </>
        )}
        {isConfig && (
          <Tooltip
            title={'Apply Config to All'}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <Grid item>
              <EvesButton
                isAction={true}
                data-cy='transactionsrefund-refund-button'
                onClick={handleConfig}
                style={{
                  background: '#3D3AD3' ,
                  //  height:'40px',
                  '& :hover': {
                    background:  '#3D3AD3' ,
                  },
                }}
              >
                <Config />
              </EvesButton>
            </Grid>
          </Tooltip>
        )}

        <TableRefreshComponent
          componentName='chargingStation'
          autoRefreshStatus={autoRefreshStatus ? autoRefreshStatus : false}
          hideLabel={hideLabel}
          intervalsAndFunctions={intervalsAndFunctions}
          multiple={multiple}
          onRefresh={onRefresh}
        />
        {isDemandGraph && (
          <Tooltip
            title={'Hide Graph'}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'black',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                },
              },
            }}
            followCursor={false}
            placement={'bottom-start'}
            enterNextDelay={500}
          >
            <Grid item>
              <EvesButton
                isAction={true}
                data-cy='transactionsrefund-refund-button'
                onClick={handleGraph}
                style={{
                  background: state.showGraph ? '#3D3AD3' : '#999',
                  //  height:'40px',
                  '& :hover': {
                    background: state.showGraph ? '#3D3AD3' : '#999',
                  },
                }}
              >
                <DemandGraph />
              </EvesButton>
            </Grid>
          </Tooltip>
        )}
            {isPowerManage && 
        <Tooltip
          title={'Connectors'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'black',
                borderStyle: 'solid',
                borderWidth: '1px',
              },
            },
          }}
          followCursor={false}
          placement={'bottom-start'}
          enterNextDelay={500}
        >
          <Grid item>
        
              <EvesButton
                isAction={true}
                data-cy='connector-power-management-button'
                onClick={onClickConnectors}
                style={{
                  background: showconnector ? '#3D3AD3' : '#999',
                  //  height:'40px',
                  '& :hover': {
                    background: showconnector ? '#3D3AD3' : '#999',
                  },
                }}
              >
                <Connector />
              </EvesButton>
          
          </Grid>
        </Tooltip>
          }
              {isPowerManage &&!hidePhase&& 
        <Tooltip
          title={'Phase'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'black',
                borderStyle: 'solid',
                borderWidth: '1px',
              },
            },
          }}
          followCursor={false}
          placement={'bottom-start'}
          enterNextDelay={500}
        >
          <Grid item>
        
              <EvesButton
                isAction={true}
                data-cy='phase-powermanagement-button'
                onClick={onClickPhase}
                style={{
                  background: showphase ? '#3D3AD3' : '#999',
                  //  height:'40px',
                  '& :hover': {
                    background: showphase ? '#3D3AD3' : '#999',
                  },
                }}
              >
               <Phase width={80} height={80}/>
              </EvesButton>
           
          </Grid>
        </Tooltip>
         }
      </Grid>
      <Grid item display={'flex'} justifyContent={'end'} gap={'5px'}>
        <Grid item display={'flex'} mt={2} className={styles.gaps}>
          {!state.isResetBtnDisabled && (
            <Grid item>
              <Tooltip
                title={`${translate('general.reset_filter')}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <Grid item>
                  <EvesButton
                    isAction={true}
                    onClick={resetFilters}
                    data-cy='transactionsInProgress-reset-filter-button'
                  >
                    <Cancel />
                  </EvesButton>
                </Grid>
              </Tooltip>
            </Grid>
          )}

          {(state?.selectedRows?.length || state.selectedRecords?.length) > 0 &&
            isDelete && (
              <Tooltip
                title={`${translate('Delete')}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <Grid item>
                  <EvesButton
                    sx={{
                      background: '#D3302F !important',

                      '&:hover': {
                        background: '#D3302F !important',
                      },
                    }}
                    onClick={onClickDelete}
                    data-cy='create-station'
                    isAction={true}
                  >
                   {isSession ? <StopIcon /> :<img src={Trash} alt='img' />} 
                  </EvesButton>
                </Grid>
              </Tooltip>
            )}
          {state.canAssign && isRegister && (
            <Grid item>
              <Tooltip
                title={`${translate('Register')}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <Grid item>
                  <EvesButton
                    isAction={true}
                    onClick={onClickRegister}
                    data-cy='rfid-register-button'
                  >
                    <Add />
                  </EvesButton>
                </Grid>
              </Tooltip>
            </Grid>
          )}
          {isRefund && (
            <>
              <Tooltip
                title={`${translate('Synchronize')}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <Grid item>
                  {isRefundTransactionEnabled && isUserAdmin && (
                    <EvesButton
                      isAction={true}
                      onClick={synchronizeRefund}
                      data-cy='transactionsrefund-sync-button'
                      disabled={!(authContext.userInfo.role === UserRole.ADMIN)}
                    >
                      <Sync />{' '}
                    </EvesButton>
                  )}
                </Grid>
              </Tooltip>
              <Tooltip
                title={`${translate('Refund')}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <Grid item>
                  {isRefundTransactionEnabled && (
                    <EvesButton
                      isAction={true}
                      data-cy='transactionsrefund-refund-button'
                      onClick={refund}
                      disabled={
                        !(
                          isRefundTransactionEnabled &&
                          state.selectedRows.length > 0
                        )
                      }
                    >
                      <LocalAtm />
                    </EvesButton>
                  )}
                </Grid>
              </Tooltip>
              <Tooltip
                title={`${translate('Jump To Your Expenses')}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                    },
                  },
                }}
                followCursor={false}
                placement={'bottom-start'}
                enterNextDelay={500}
              >
                <Grid item>
                  {isRefundTransactionEnabled && (
                    <EvesButton
                      isAction={true}
                      disabled={!isOpenURLRefundAction}
                      onClick={onClickOpenURLRefundAction}
                      data-cy='transactionsrefund-jump-to-your-expenses-button'
                    >
                      <OpenInNew />{' '}
                    </EvesButton>
                  )}
                </Grid>
              </Tooltip>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
