import { Tile } from '@/common/components/molecules/tile/tile';
import { BatteryChargingFull, EvStation, FlashOn, Money, Timelapse, Timer, TimerOff } from '@mui/icons-material';
import { Stack } from '@mui/material';
import DefaultAvatar from '@/assets/img/user-default.png';
import DefaultCarCatalogImage from '@/assets/img/theme/no-image.png';
import { buildCarCatalogName } from '@/common/utils/utils';
import { languageLocale } from '@/common/constants/constants';
import i18next from 'i18next';

const TransactionHeader = (props: any) => {
	const formatDate = (date : any) => {
		if(date == 'Invalid Date'){
			return('');
		}
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12;
		minutes = minutes < 10 ? '0'+minutes : minutes;
		const strTime = (date.getMonth()+1)+'/'+ date.getDate() + '/'+ date.getFullYear()%100+', ' +hours + ':' + minutes + ' ' + ampm;
		return strTime;
	};

		const formatStateOfCharge = (state1: number, state2: number) => {
			const difference = state2 - state1;
			let result = '';
		
			if (difference > 0) {
			result = `${state1.toFixed(1)}% > ${state2.toFixed(1)}% (+${difference.toFixed(1)}%)`;
			} else {
			result = `${state1.toFixed(1)}% > ${state2.toFixed(1)}% (${difference.toFixed(1)}%)`;
			}
		
			if (state1 === 0 && state2 === 0) {
			result = '-%';
			}
		
			return result;
		};


	const inactiveDuration = (transaction: any) => {
		let totalDurationSecs = 0;
		let totalInactivitySecs = 0;
		if (transaction?.stop) {
			totalDurationSecs = transaction?.stop?.totalDurationSecs;
			totalInactivitySecs = transaction?.stop?.totalInactivitySecs + transaction?.stop?.extraInactivitySecs;
		} else {
			totalDurationSecs = transaction?.currentTotalDurationSecs;
			totalInactivitySecs = transaction?.currentTotalInactivitySecs;
		}
		if (totalDurationSecs) {
			const percentage = totalDurationSecs > 0 ? (totalInactivitySecs / totalDurationSecs) : 0;
			return appDurationPipe(totalInactivitySecs) +
				` (${(percentage * 100).toFixed(0)}%)`;
		}
		return appDurationPipe(totalInactivitySecs);
	};

	const appDurationPipe = (durationSecs: number) => {
		let result = '';
		if (isNaN(durationSecs)) {
			return '-';
		}
		if (durationSecs < 1) {
			return `0 ${'s'}`;
		}
		const days = Math.floor(durationSecs / (3600 * 24));
		durationSecs -= days * 3600 * 24;
		const hours = Math.floor(durationSecs / 3600);
		durationSecs -= hours * 3600;
		const minutes = Math.floor(durationSecs / 60);
		const seconds = Math.floor(durationSecs - (minutes * 60));
		if (days !== 0) {
			result += `${days}${'d'} `;
		}
		if (((hours !== 0) || (days !== 0)) && (hours !== 0 || (minutes !== 0 && days === 0))) {
			result += `${hours}${'h'} `;
		}
		if (days === 0) {
			if ((minutes !== 0) || (hours !== 0) && (minutes !== 0 || (seconds !== 0 && hours === 0))) {
				result += `${minutes}${'m'} `;
			}
			if ((hours === 0) && (seconds !== 0)) {
				result += `${seconds}${'s'} `;
			}
		}
		return result;
	};

	const roundedTransactionPrice = props.transaction?.stop?.roundedPrice.toLocaleString(
		languageLocale[i18next.language] || 'en-EN',
		{
			style: 'currency',
			currency: 'USD',
		}
	);

	const transactionPrice = props.transaction?.price.toLocaleString(
		languageLocale[i18next.language] || 'en-EN',
		{
			style: 'currency',
			currency: 'USD',
		}
	);
			

	return (
		<Stack direction="row"  justifyContent="space-around" alignItems="center" data-cy='chargeStationDetails'>
			<Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
				<div data-cy='userFullName' >
					<Tile
						imgSrc={!props.userImage ? DefaultAvatar : props.userImage}
						title={`${props.transaction?.user?.firstName} ${props.transaction?.user?.name}`}
						subTitle={props.transaction?.tag?.visualID}
						description={(props.withStopedBy && props.transaction?.stop?.user) ? 
							`Stopped By ${props.transaction?.stop?.user?.firstName} ${props.transaction?.stop?.user?.name}` : ''}
					/>
				</div>
			</Stack>
				
			<Stack direction="column"  justifyContent="space-between" alignItems="center">
				{props.transaction?.stop && 
				<div data-cy='price'>
					<Tile
						tooltipTitle="Amount"
						icon={Money}
						title={`${((props.transaction?.stop && roundedTransactionPrice || transactionPrice) || 0)}`}
					/>
				</div> || 
				<div data-cy='current-consumption'>
					<Tile
						tooltipTitle="Current Consumption"
						icon={FlashOn}
						title={`${((props.transaction?.currentInstantWatts || 0)/1000).toFixed(2)} kW`}
					/>
				</div>
				}
				<div data-cy='startDate'>
					<Tile
						tooltipTitle="Start date"
						icon={Timelapse}
						title={formatDate(new Date(props.transaction?.timestamp))}
						subTitle={(formatDate(new Date(props.transaction?.stop && props.transaction?.stop?.timestamp || ''))) ? ' ' + formatDate(new Date(props.transaction?.stop && props.transaction?.stop?.timestamp || '')) + ' ' : ''}
					/>
				</div>
			</Stack>
			<Stack direction="column"  justifyContent="space-between" alignItems="center">
				<div data-cy='totalConsumption'>
					<Tile
						tooltipTitle="Total consumption"
						icon={EvStation}
						title={`${(((props.transaction?.stop && props.transaction?.stop?.totalConsumptionWh || props.transaction?.currentTotalConsumptionWh) || 0) / 1000).toFixed(2)} kW.h`}
					/>
				</div>
				<div data-cy='totalDuration'>
					<Tile
						tooltipTitle="Total duration"
						icon={Timer}
						title={appDurationPipe(props.transaction?.stop && props.transaction?.stop?.totalDurationSecs || props.transaction?.currentTotalDurationSecs)}
					/>
				</div>
			</Stack>
			<Stack direction="column" justifyContent="space-between" alignItems="center">
				<div data-cy='batteryLevel'>
					<Tile
						tooltipTitle="Battery level"
						icon={BatteryChargingFull}
						title={formatStateOfCharge(props.transaction?.stateOfCharge || 0, (props.transaction?.stop && props.transaction?.stop?.stateOfCharge || props.transaction?.currentStateOfCharge) || 0)}
					/>
				</div>
				<div data-cy='inactiveDuration'>
					<Tile
						tooltipTitle="Inactive duration"
						icon={TimerOff}
						title={inactiveDuration(props.transaction)}
						type='success'
						data-cy='inactiveDuration'
					/>
				</div>
			</Stack>

			<Stack justifyContent={'center'} alignItems={'center'}>
				<div data-cy='applicationName'>
					<Tile
						imgSrc={!props.carCatalogImage ? DefaultCarCatalogImage : props.carCatalogImage}
						title={buildCarCatalogName(props.transaction?.carCatalog)}
						subTitle={props.transaction?.car?.licensePlate}
					/>
				</div>
			</Stack>
		</Stack>
	);
};

export default TransactionHeader;